// deno-lint-ignore-file no-explicit-any

import { EMPTY_STRING } from "./defs";
import { compareScope, normalizeLocationProperties, createIdentifyingProperty } from "./utils";

import { parse as generateEsTree } from "acorn-loose";
import { generate as writeCode } from "astring";
import { parseScript } from "esprima";
import { isObjectMethod, wrapAsObjectLiteral,isObjectMethodUpdate,getMethodName } from "./helper";

/*
import { flatten as flattenObject, unflatten as restoreFlattenedObject } from 'npm:flat';
import hash from 'npm:object-hash';
import { v4 as uuid } from 'npm:uuid';
*/

export default function LogicEngine(existingCode: string, incomingCode: string) {

  if (isCompleteRewrite(existingCode, incomingCode)) {
    return incomingCode;
  }

  if (isObjectMethodUpdate(existingCode, incomingCode)) {
    const methodName = getMethodName(incomingCode);
    
    // Parse the existing code to get the AST
    const existingAst = parseScript(existingCode);
    
    // Find the object declaration
    const objectDeclaration = findObjectDeclaration(existingAst);
    if (objectDeclaration) {
        // Update the method in the object
        const updatedCode = updateObjectMethod(existingCode, methodName, incomingCode);
        return updatedCode;
    }
}


  let source: string = EMPTY_STRING;
  
  // Wrap the code if it's an object method
  const wrappedIncomingCode = isObjectMethod(incomingCode) 
    ? wrapAsObjectLiteral(incomingCode) 
    : incomingCode;
  
  try {
      const existingAst = parseScript(existingCode);
      const incomingAst = parseScript(wrappedIncomingCode);

      const normalizedLocationExistingAst = normalizeLocationProperties(existingAst);
      const normalizedLocationIncomingAst = normalizeLocationProperties(incomingAst);

      const comparableExistingAst = createIdentifyingProperty(normalizedLocationExistingAst);
      const comparableIncomingAst = createIdentifyingProperty(normalizedLocationIncomingAst);

      // If we wrapped the code, we need to unwrap the AST
      const incomingBody = isObjectMethod(incomingCode) 
        ? comparableIncomingAst.body[0].expression.properties 
        : comparableIncomingAst.body;

      const mergedAstBodies = compareScope(comparableExistingAst.body, incomingBody);
      const noSubmitMergedAstBodies = removeSubmitEventListeners(mergedAstBodies);

      noSubmitMergedAstBodies.forEach((node: any, index: number) => {
          source += writeCode(node);
          source += noSubmitMergedAstBodies.length - 1 === index ? '' : '\n\n';
      });

      return source;
  } catch (error) {
      console.error('Error parsing code:', error);
      return incomingCode; // Return original code if parsing fails
  }
}
function findObjectDeclaration(ast: any): any {
  // Find the variable declaration that contains our object
  return ast.body.find((node: any) => 
      node.type === 'VariableDeclaration' &&
      node.declarations[0].init &&
      node.declarations[0].init.type === 'ObjectExpression'
  );
}

function updateObjectMethod(code: string, methodName: string, newMethod: string): string {
  const lines = code.split('\n');
  const objectStart = lines.findIndex(line => line.includes('const') || line.includes('let') || line.includes('var'));
  
  const methodRegex = new RegExp(`\\s*${methodName}\\s*:`);
  let methodStart = -1;
  let methodEnd = -1;
  let bracketCount = 0;
  let inMethod = false;
  let hasCommaAfterMethod = false;

  for (let i = objectStart; i < lines.length; i++) {
      if (methodRegex.test(lines[i])) {
          methodStart = i;
          inMethod = true;
      }
      
      if (inMethod) {
          bracketCount += (lines[i].match(/{/g) || []).length;
          bracketCount -= (lines[i].match(/}/g) || []).length;
          
          if (bracketCount === 0) {
              methodEnd = i;
              // Check if the current method has a comma after it
              hasCommaAfterMethod = lines[i].trim().endsWith(',');
              break;
          }
      }
  }

  if (methodStart !== -1 && methodEnd !== -1) {
      const beforeMethod = lines.slice(0, methodStart);
      const afterMethod = lines.slice(methodEnd + 1);
      
      // Add comma if it existed in the original code
      let updatedMethod = newMethod;
      if (hasCommaAfterMethod && !updatedMethod.trim().endsWith(',')) {
          updatedMethod = updatedMethod.trim() + ',';
      }
      
      return [...beforeMethod, updatedMethod, ...afterMethod].join('\n');
  }

  return code;
}
function isCompleteRewrite(existingCode: string, incomingCode: string): boolean {
  try {
    const existingAst = parseScript(existingCode);
    const incomingAst = parseScript(incomingCode);

    // If both have the same top-level variable declarations
    const existingVars = getTopLevelVariables(existingAst);
    const incomingVars = getTopLevelVariables(incomingAst);

    // If they share the same main variable name and structure
    return existingVars.some(existingVar => 
      incomingVars.includes(existingVar) && 
      hasMatchingStructure(existingCode, incomingCode, existingVar)
    );
  } catch (error) {
    return false;
  }
}

function getTopLevelVariables(ast: any): string[] {
  return ast.body
    .filter((node: any) => 
      node.type === 'VariableDeclaration' || 
      node.type === 'FunctionDeclaration'
    )
    .map((node: any) => {
      if (node.type === 'VariableDeclaration') {
        return node.declarations[0].id.name;
      }
      return node.id.name;
    });
}

function hasMatchingStructure(existingCode: string, incomingCode: string, varName: string): boolean {
  // Check if both codes define the same variable/object with similar structure
  const varRegex = new RegExp(`(?:const|let|var)\\s+${varName}\\s*=\\s*{`);
  return varRegex.test(existingCode) && varRegex.test(incomingCode);
}
function removeSubmitEventListeners(estree: any): any {
  if (Array.isArray(estree)) {
    return estree.filter(node => !isSubmitEventListener(node))
                 .map(removeSubmitEventListeners);
  }

  if (estree && typeof estree === 'object') {
    for (let key in estree) {
      if (estree.hasOwnProperty(key)) {
        estree[key] = removeSubmitEventListeners(estree[key]);
      }
    }
  }

  return estree;
}

function isSubmitEventListener(node: any) {
  if (node.type === 'ExpressionStatement' &&
      node.expression.type === 'CallExpression' &&
      node.expression.callee.type === 'MemberExpression' &&
      node.expression.callee.property.name === 'addEventListener' &&
      node.expression.arguments[0] &&
      node.expression.arguments[0].value === 'submit') {
    return true;
  }

  if (node.type === 'ExpressionStatement' &&
      node.expression.type === 'AssignmentExpression' &&
      node.expression.left.type === 'MemberExpression' &&
      node.expression.left.property.name === 'onsubmit') {
    return true;
  }

  return false;
}

// function removeSubmitEventListeners(estree: any) {
//     return estree.filter((node: any) => {
//       if (node.type === 'ExpressionStatement' &&
//           node.expression.type === 'CallExpression' &&
//           node.expression.callee.type === 'MemberExpression' &&
//           node.expression.callee.property.name === 'addEventListener' &&
//           node.expression.arguments[0].value === 'submit') {
//         return false;
//       }
  
//       if (node.type === 'ExpressionStatement' &&
//           node.expression.type === 'AssignmentExpression' &&
//           node.expression.left.type === 'MemberExpression' &&
//           node.expression.left.property.name === 'onsubmit') {
//         return false;
//       }
  
//       return true;
//     }).map((node: any) => {
//       if (node.type === 'BlockStatement' && node.body) {
//         node.body = removeSubmitEventListeners(node.body);
//       }
//       return node;
//     });
//   }
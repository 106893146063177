import { useEffect, useRef, useState, useMemo } from 'react';
import {
  Badge,
  Tooltip,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  FormLabel,
  Radio, RadioGroup, Stack,
  Tabs, TabList, Tab, TabPanels, TabPanel, keyframes, useTheme,
  Icon, Select, Heading, Flex, Button, Checkbox, IconButton, Input, useColorModeValue, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Box
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import AWS from 'aws-sdk';

import { useRecoilTransaction_UNSTABLE, useRecoilValue, useSetRecoilState, useRecoilState, useResetRecoilState } from 'recoil';
import { useToast } from '@chakra-ui/react';
import { IsProjectFromTemplate, IsUploadingSpinner, IsTranscribingSpinner, IsGeneratingImageSpinner, IsGeneratingLoadingScreen, IsGeneratingSpinner, IsGlowingEnhance, Autofixer, AutofixerContext, ViewportTree, CreatePageFromLink, GoToPageFromLink, WipeIframeContent, IsUpdated, LocalContextId, LocalContextCSS, TranscriptionOutput, SelectedProjectMode, IsPreviewMode, DefaultProjectSourceFile, currentUserState, combinedUserState, iconDisabledState, ChatBubbles, selectedProjectSelector, selectedPageSelector, pagesState, ViewportSourceDocument, ConversationHistory, ContentUndoHistory, ConversationHistoryUndoHistory, UndoHistoryStepIndex, HTMLCode, CSSCode, JavaScriptCode, LocalContext, UserPreferences, LocalContextOuterHTML, IsDeployingSpinner, PageLinks, OutlinerTree, PageForms, PageImages, PageFormsHaveBeenUpdated, UserAllottedCredits, GlobalContextOuterHTML, ProjectForms, CurrentMessageGroupType, MessageGroupTypes, extraFormsEnabled, IsGlowingTranslate } from '../state';
import { useCloudFrontActions, useOpenaiActions, useFormActions, usePageActions, useUserActions, useProjectActions } from '../hooks';
import { Page } from '../types';
import { useNavigate } from 'react-router-dom';

import { SoftCard } from '../components/generic/SoftCard';
import { AuthenticatedLayout } from '../layouts';
import { Processor } from '../components/engine/processor';
import { Transcriber } from '../components/engine/transcriber';
import { Chat } from '../components/engine/chat';
import { Links } from '../components/engine/links';
import { Images } from '../components/engine/images';
import { Undo } from '../components/engine/undo';
import { DevMode } from '../components/engine/devmode';
import { Outliner } from '../components/engine/outliner';

import { Colors } from '../components/engine/colors';
import { CommonElements } from '../components/engine/common-elements';

import { Forms } from '../components/engine/forms';
import { motion } from 'framer-motion';

import { unsupportedColors } from '../lib/project-modes/unsupported-colors';

import { MdFormatListBulletedAdd, MdSystemUpdateAlt, MdCloudUpload } from "react-icons/md";

// import { CompletionLoadingScreen } from '../components/engine/completion-loading-screen';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { html as beautify_html } from 'js-beautify';
import { css as beautify_css } from 'js-beautify';

import beautify_js from 'js-beautify';

import { FaFileExport } from "react-icons/fa";
import { MdAccountTree, MdGTranslate } from "react-icons/md";

import { languages } from '../lib/languages';

import { cssjs } from 'jotform-css.js';
import { getIDsRecursively, extractCSSRulesBySelectors, deleteCSSRulesBySelectors, writeCSSFromRules } from '../components/engine/css-tools';

import { MdOutlineLocalFireDepartment, MdChevronRight, MdAddCircleOutline, MdModeEdit, MdUndo, MdOutlineRestore, MdOutlineLineWeight, MdRocketLaunch, MdSaveAlt, MdOutlineFolderZip, MdOutlineCode, MdOutlinePublishedWithChanges, MdOutlineRocket, MdOutlineCopyAll, MdOutlineLink } from "react-icons/md";
import { TbMessageCode } from "react-icons/tb";
import { IoLinkSharp } from "react-icons/io5";
import { FaRegImage } from "react-icons/fa";
import { BsRocketTakeoff } from "react-icons/bs";
import { IoSparkles } from "react-icons/io5";

import { Link } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

import { useBreakpointValue } from "@chakra-ui/react";
import { MdContentCopy } from "react-icons/md";

import { useSIFOActions } from '../hooks';
import { constructPageLinksArray, constructPageFormsArray, hasBackgroundImage } from '../components/engine/links-tools';

import '../lib/anim.css';
import { TriggeredFunction } from '../state';

import { isAvailableInPlanDeveloperMode, exportLocally } from '../lib/feature-availability';
import { parse as parseTolerant } from 'tolerant-json-parser';
import { jsonrepair as repairJson } from 'jsonrepair'

import { MdColorLens } from "react-icons/md";
import { BsReverseLayoutSidebarInsetReverse } from "react-icons/bs";

export const DashboardPage: React.FC = () => {

  const [isGlowingEnhance, setIsGlowingEnhance] = useRecoilState<any>(IsGlowingEnhance);
  const [isGlowingTranslate, setIsGlowingTranslate] = useRecoilState<any>(IsGlowingTranslate);

  const theme = useTheme();
  const purple = theme.colors.purple;

  const glowKeyframes = (color: any) => keyframes`
  0% { box-shadow: 0 0 10px ${color[200]}; }
  50% { box-shadow: 0 0 20px ${color[400]}; }
  100% { box-shadow: 0 0 10px ${color[200]}; }
`;

  // const { buildOutlinerTree } = useSIFOActions();

  const setViewportTree = useSetRecoilState(ViewportTree);
  const setGlobalContextOuterHTML = useSetRecoilState(GlobalContextOuterHTML);
  const MotionBox = motion(Box);

  const [createPageFromLink, setCreatePageFromLink] = useRecoilState<any>(CreatePageFromLink);
  const [goToPageFromLink, setGoToPageFromLink] = useRecoilState<any>(GoToPageFromLink);

  // const [isCreatedPageFromLink, setIsCreatedPageFromLink] = useRecoilState<any>(IsCreatedPageFromLink);

  const setWipeIframeContent = useSetRecoilState<any>(WipeIframeContent);

  const [isEnhanceCoolingDown, setIsEnhanceCoolingDown] = useState(false);

  const { csvToObject, cleanTranslation, buildLocalizationMap, buildContextTree, buildOutlinerTree } = useSIFOActions();
  const setOutlinerTree = useSetRecoilState(OutlinerTree);

  const { isOpen: isExportOpen, onOpen: onExportOpen, onClose: onExportClose } = useDisclosure();

  const { isOpen: isResetConfirmationOpen, onOpen: onResetConfirmationOpen, onClose: onResetConfirmationClose } = useDisclosure();
  const [isGeneratingSpinner, setIsGeneratingSpinner] = useRecoilState(IsGeneratingSpinner);

  const currentUser: any = useRecoilValue(currentUserState);
  const selectedProject: any = useRecoilValue(selectedProjectSelector);

  const toast = useToast();
  const isWideScreen = useBreakpointValue({ base: false, md: true });

  const navigate = useNavigate();
  const { isOpen: isUpgradeOpen, onOpen: onUpgradeOpen, onClose: onUpgradeClose } = useDisclosure();
  const cancelRef = useRef<any | null>();
  const startFromScratchCancelRef = useRef<any | null>();

  const [autofixer, setAutofixer] = useRecoilState(Autofixer);
  const [autofixerContext, setAutofixerContext] = useRecoilState(AutofixerContext);
  const { updateProjectPages, refreshPageWithForms } = useProjectActions();

  const userPreferences = useRecoilValue(UserPreferences);
  const defaultProjectSourceFile = useRecoilValue(DefaultProjectSourceFile);

  const [triggeredFunction, setTriggeredFunction] = useRecoilState<any>(TriggeredFunction);

  const isPreviewMode = useRecoilValue(IsPreviewMode);
  const scrollBarColor = useColorModeValue('#38B2AC', '#81E6D9');
  const setIsGeneratingLoadingScreen = useSetRecoilState(IsGeneratingLoadingScreen);
  const setCurrentMessageGroupType = useSetRecoilState(CurrentMessageGroupType);
  const resetIsGeneratingSpinner = useResetRecoilState(IsGeneratingSpinner);
  const resetIsGeneratingLoadingScreen = useResetRecoilState(IsGeneratingLoadingScreen);

  const [isChatVisible, setIsChatVisible] = useState(false);
  const [isUndoVisible, setIsUndoVisible] = useState(false);
  const [isPagesVisible, setIsPagesVisible] = useState(false);
  const [isDevModeVisible, setIsDevModeVisible] = useState(false);
  const [isLinksVisible, setIsLinksVisible] = useState(false);
  const [isImagesVisible, setIsImagesVisible] = useState(false);

  const [isOutlinerVisible, setIsOutlinerVisible] = useState(false);
  const [isColorsVisible, setIsColorsVisible] = useState(false);
  const [isCommonElementsVisible, setIsCommonElementsVisible] = useState(false);

  const [isFormsVisible, setIsFormsVisible] = useState(false);
  const [areIconsDisabled, setAreIconsDisabled] = useState(false);
  const combinedUser = useRecoilValue(combinedUserState);
  const isExtraFormsEnabled = useRecoilValue(extraFormsEnabled);
  const setProjectForms = useSetRecoilState(ProjectForms);
  const [projectForms] = useRecoilState(ProjectForms);

  AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-east-1:98689dd4-f972-4f76-8aec-0b54f0cb2c08'
    }),
    httpOptions: {
      timeout: 600000 
    }
  });
  const lambda = new AWS.Lambda();

  let lambdaFunctionName : any

  if (window.location.hostname === 'app.sifo.ai') {
    lambdaFunctionName = 'my-app-api-production-poller'
  } else if (window.location.hostname === 'app.staging.sifo.ai') {
    lambdaFunctionName = 'my-app-api-staging-poller'
  }

  useEffect(() => {
    if (user?.plan_id === 'FREE_TRIAL') {

      if (combinedUser && combinedUser.iconDisabled) {
        setAreIconsDisabled(true);
      }
    }
  }, [combinedUser]);

  useEffect(() => {
    if (isWideScreen) {
      setIsChatVisible(true);
      setIsPagesVisible(true);
      // setIsOutlinerVisible(true);
    }
  }, [isWideScreen])

  const toggleChat = () => {
    if (!isWideScreen) {
      setIsUndoVisible(false);
      setIsPagesVisible(false);
      setIsDevModeVisible(false);
      setIsLinksVisible(false);
      setIsImagesVisible(false);
      setIsOutlinerVisible(false);
      setIsColorsVisible(false);
      setIsCommonElementsVisible(false);
      setIsFormsVisible(false);
    }
    setIsChatVisible(!isChatVisible);
  }

  const toggleUndo = () => {
    if (!isWideScreen) {
      setIsChatVisible(false);
      setIsPagesVisible(false);
      setIsDevModeVisible(false);
      setIsLinksVisible(false);
      setIsImagesVisible(false);
      setIsOutlinerVisible(false);
      setIsColorsVisible(false);
      setIsCommonElementsVisible(false);
      setIsFormsVisible(false);
    }
    setIsUndoVisible(!isUndoVisible);
  }

  const togglePages = () => {
    if (!isWideScreen) {
      setIsChatVisible(false);
      setIsUndoVisible(false);
      setIsDevModeVisible(false);
      setIsLinksVisible(false);
      setIsImagesVisible(false);
      setIsOutlinerVisible(false);
      setIsColorsVisible(false);
      setIsCommonElementsVisible(false);
      setIsFormsVisible(false);
    }
    setIsPagesVisible(!isPagesVisible);
  }

  const toggleDevMode = () => {
    if (!isWideScreen) {
      setIsChatVisible(false);
      setIsUndoVisible(false);
      setIsPagesVisible(false);
      setIsLinksVisible(false);
      setIsImagesVisible(false);
      setIsOutlinerVisible(false);
      setIsColorsVisible(false);
      setIsCommonElementsVisible(false);
      setIsFormsVisible(false);
    }
    setIsDevModeVisible(!isDevModeVisible);
  }

  const toggleLinks = () => {
    if (!isWideScreen) {
      setIsChatVisible(false);
      setIsUndoVisible(false);
      setIsPagesVisible(false);
      setIsDevModeVisible(false);
      setIsImagesVisible(false);
      setIsOutlinerVisible(false);
      setIsColorsVisible(false);
      setIsCommonElementsVisible(false);
      setIsFormsVisible(false);
    }
    setIsLinksVisible(!isLinksVisible);
  }

  const toggleImages = () => {
    if (!isWideScreen) {
      setIsChatVisible(false);
      setIsUndoVisible(false);
      setIsPagesVisible(false);
      setIsDevModeVisible(false);
      setIsLinksVisible(false);
      setIsOutlinerVisible(false);
      setIsColorsVisible(false);
      setIsCommonElementsVisible(false);
      setIsFormsVisible(false);
    }
    setIsImagesVisible(!isImagesVisible)
  }

  const toggleOutliner = () => {
    if (!isWideScreen) {
      setIsChatVisible(false);
      setIsUndoVisible(false);
      setIsPagesVisible(false);
      setIsDevModeVisible(false);
      setIsLinksVisible(false);
      setIsImagesVisible(false);
      setIsFormsVisible(false);
      setIsColorsVisible(false);
      setIsCommonElementsVisible(false);
    }
    setIsOutlinerVisible(!isOutlinerVisible)
  }

  const toggleColors = () => {
    if (!isWideScreen) {
      setIsChatVisible(false);
      setIsUndoVisible(false);
      setIsPagesVisible(false);
      setIsDevModeVisible(false);
      setIsLinksVisible(false);
      setIsImagesVisible(false);
      setIsFormsVisible(false);
      setIsOutlinerVisible(false);
      setIsCommonElementsVisible(false);
    }
    setIsColorsVisible(!isColorsVisible)
  }

  const toggleCommonElements = () => {
    if (!isWideScreen) {
      setIsChatVisible(false);
      setIsUndoVisible(false);
      setIsPagesVisible(false);
      setIsDevModeVisible(false);
      setIsLinksVisible(false);
      setIsImagesVisible(false);
      setIsFormsVisible(false);
      setIsOutlinerVisible(false);
      setIsColorsVisible(false);
    }
    setIsCommonElementsVisible(!isCommonElementsVisible)
  }

  const toggleForms = () => {
    if (!isWideScreen) {
      setIsChatVisible(false);
      setIsUndoVisible(false);
      setIsPagesVisible(false);
      setIsDevModeVisible(false);
      setIsLinksVisible(false);
      setIsImagesVisible(false);
      setIsOutlinerVisible(false);
      setIsColorsVisible(false);
      setIsCommonElementsVisible(false);
    }
    setIsFormsVisible(!isFormsVisible)
  }

  const transcriptionOutput = useRecoilValue(TranscriptionOutput);
  const setViewportSourceDocument = useSetRecoilState(ViewportSourceDocument);
  const user = useRecoilValue(currentUserState);
  const project = useRecoilValue(selectedProjectSelector);
  const page = useRecoilValue(selectedPageSelector);
  const userAllottedCredits = useRecoilValue<any>(UserAllottedCredits);
  
  const [pages, setPages] = useRecoilState<any>(pagesState);
  // const pages = useRecoilValue(pagesState);
  
  // const [sortedPages, setSortedPages] = useState<any>(pages)

  // useEffect(() => {
  //   if (pages.length === 0) return;

  //   const pagesCopy = [...pages];
  //   pagesCopy.sort((a: any, b: any) => b.is_index - a.is_index);
  //   setSortedPages(pagesCopy);
  // }, [pages])

  const { isOpen: isCreatePageOpen, onOpen: onCreatePageOpen, onClose: onCreatePageClose } = useDisclosure()
  const { isOpen: isEditPageOpen, onOpen: onEditPageOpen, onClose: onEditPageClose } = useDisclosure()

  const { isOpen: isProjectDeployedOpen, onOpen: onProjectDeployedOpen, onClose: onProjectDeployedClose } = useDisclosure()
  const [deployedProject, setDeployedProject] = useState(null);
  const { isOpen: isUpdateModalOpen, onOpen: onUpdateModalOpen, onClose: onUpdateModalClose } = useDisclosure();
  const [updatedSiteUrl, setUpdatedSiteUrl] = useState('');
  const pageNameInputRef = useRef<HTMLInputElement>(null);
  const renamePageInputRef = useRef<HTMLInputElement>(null);

  const { createPage, renamePage, deletePage, fetchPageContent, fetchForms, fetchAllPagesContents, fetchPageContentWithoutViewport, updateAdditionalFormPageContent, updatePageContent } = usePageActions();

  const [selectedPage, setSelectedPage] = useRecoilState(selectedPageSelector);

  const selectedProjectMode = useRecoilValue(SelectedProjectMode);
  const fromTemplate = useRecoilValue(IsProjectFromTemplate);

  const setContentUndoHistory = useSetRecoilState(ContentUndoHistory);
  const setConversationHistoryUndoHistory = useSetRecoilState(ConversationHistoryUndoHistory);
  const [pageLinks, setPageLinks] = useRecoilState(PageLinks);
  const [pageForms, setPageForms] = useRecoilState(PageForms);
  const [pageImages, setPageImages] = useRecoilState(PageImages);

  const undoHistoryStepIndex = useRecoilValue(UndoHistoryStepIndex);
  const setUndoHistoryStepIndex = useSetRecoilState(UndoHistoryStepIndex);

  const resetUndoHistoryStepIndex = useResetRecoilState(UndoHistoryStepIndex);
  const resetLocalContext = useResetRecoilState(LocalContext);
  const resetLocalContextCSS = useResetRecoilState(LocalContextCSS);

  // const setHTMLCode = useSetRecoilState(HTMLCode);
  const [htmlCode, setHTMLCode] = useRecoilState(HTMLCode);
  const [cssCode, setCSSCode] = useRecoilState(CSSCode);

  const setJavaScriptCode = useSetRecoilState(JavaScriptCode);

  const setIsUpdated = useSetRecoilState(IsUpdated);

  const localContext: any = useRecoilValue(LocalContext);
  const localContextId: any = useRecoilValue(LocalContextId);
  const localContextHTML: any = useRecoilValue(LocalContextOuterHTML);
  const [localContextCSS, setLocalContextCSS] = useRecoilState(LocalContextCSS);

  const { getPageTranslation, getProjectTranslation, getEnhancedCSS } = useOpenaiActions();
  const { createSite, deploySite, updateSite, saveSite, fetchSites } = useCloudFrontActions();

  const setConversationHistory = useSetRecoilState(ConversationHistory);
  const [chatBubbles, setChatBubbles] = useRecoilState(ChatBubbles);

  // const setChatBubbles = useSetRecoilState(ChatBubbles);
  useEffect(() => {
    if (user?.plan_id === 'FREE_TRIAL') {

      console.log('HELLO', user?.trial_used);

      if (user && user.trial_used) {
        setAreIconsDisabled(true);
      }
    }
  }, [user]);

  async function handleCreatePage(page_title?: string, go_to_page: boolean = true) {

    // check page limits
    if (pages.length < userAllottedCredits?.pages_allowed) {
      const title: any = page_title || pageNameInputRef?.current?.value;

      if (project && title) {
        onCreatePageClose();

        // I could add more context here, for GPT to know where
        // the user left off, and where it could pick up,
        // context, you know.

        const createdPage: any = await createPage(
          {
            projectId: project?.id,
            title,
            path: `${title.toLowerCase().split(' ').join('-')}.html`,
            content: defaultProjectSourceFile,
            // content: (projectModes as any)[project.mode].source,
            conversationHistory: [
              {
                role: 'system',
                content: ''
                // content: (projectModes as any)[project.mode].systemMessage
              },
              // pages[0].conversation_history[1]
            ],
            chat: [
              {
                date: Date.now(),
                type: 'message',
                source: 'assistant',
                content: "Alright, let's keep going! What's next?"
              }
            ],
            links: [],
            images: [],
            forms: [],
            locale: 'Default'
          }
        );

        if (go_to_page) {
          if (createdPage) handleSwitchPage(createdPage.id, createdPage);
        }

      }

    } else {
      onUpgradeOpen();
    }

  }

  const handleEnterKeyPressCreatePage = (event: any) => {
    if (event.key === 'Enter') {
      handleCreatePage();
    }
  }

  const [pageBeingRenamedTitle, setPageBeingRenamedTitle] = useState<any>('');
  const [pageBeingRenamedId, setPageBeingRenamedId] = useState<any>('');

  const handleOpenRenamePageModal = (pageTitle: string, pageId: string) => {
    setPageBeingRenamedTitle(pageTitle);
    setPageBeingRenamedId(pageId);
    onEditPageOpen();
  }

  async function handleRenamePage(page_id: string) {
    const newPageTitle: any = renamePageInputRef?.current?.value;
    await renamePage(page_id, newPageTitle);
    onEditPageClose();
  }

  // async function handleTranslatePage(page_id: string) {
  //   // const currentPage = pages.find((page: Page) => page.id === page_id);

  //   // buildLocalizationMap();

  //   // New getTranslatedPage endpoint,
  //   // Uses system message in backend,
  //   // buildContextTree out of content,

  // }

  async function handleClonePage(page_id: string) {
    const currentPage = pages.find((page: Page) => page.id === page_id);
    if (!currentPage) return;

    await createPage(
      {
        projectId: currentPage.project_id,
        title: currentPage.title + ' Copy',
        path: `${currentPage.title.toLowerCase().split(' ').join('-')}-copy.html`,
        content: currentPage.content,
        conversationHistory: currentPage.conversation_history,
        chat: currentPage.chat,
        links: currentPage.links,
        images: currentPage.images,
        forms: currentPage.forms,
        locale: 'Default'
      }
    );

    onEditPageClose();
  }

  const handleEnterKeyPressRenamePage = (event: any, page_id: string) => {
    if (event.key === 'Enter') {
      handleRenamePage(page_id);
    }
  }

  function handleSwitchPage(page_id: string, newlyCreatedPage?: Page) {

    let nextPage: any;

    if (newlyCreatedPage) {
      nextPage = newlyCreatedPage
    } else {
      console.log('########### The pages I am about to earch through: ', pages);
      nextPage = pages.find((page: Page) => page.id === page_id);
    }

    if (nextPage) {
      const parser = new DOMParser();

      if (nextPage.locale === "Default") {
        const indexPage = pages.find((page: Page) => page.is_index === true);

        if (indexPage) {
          const parsedIndexPageContent = parser.parseFromString(indexPage.content, "text/html");

          /*
          Legacy way of doing things, using the viewportTree for context,
          and a specific format of completions.
          */

          // const indexPageViewportTree = buildContextTree(parsedIndexPageContent.documentElement);
          // setViewportTree(indexPageViewportTree);

          /*
          New method of doing things, context is plain html,
          and we remove contenteditable from context because it is not needed,
          as it is injected afterwards.
          */

          const bodyElement = parsedIndexPageContent.querySelector("div#body");

          if (bodyElement) {
            bodyElement.querySelectorAll('[contenteditable]').forEach(element => element.removeAttribute('contenteditable'));
            setGlobalContextOuterHTML(bodyElement.innerHTML);
          }

          // setGlobalContextOuterHTML(parsedIndexPageContent.querySelector("div#body")!.innerHTML)
          // setGlobalContextOuterHTML(parsedIndexPageContent.querySelector("div#body")!.outerHTML)
        } else {
          const parsedNextPageContent = parser.parseFromString(nextPage.content, "text/html");

          const bodyElement = parsedNextPageContent.querySelector("div#body");

          if (bodyElement) {
            bodyElement.querySelectorAll('[contenteditable]').forEach(element => element.removeAttribute('contenteditable'));
            setGlobalContextOuterHTML(bodyElement.innerHTML);
          }
        }
      }

      let pageDocument = parser.parseFromString(nextPage.content, 'text/html');

      const bodyElement = pageDocument.querySelector('div#body');
      const styleElement = pageDocument.querySelector('style[data-dev-tag="inject-into"]');
      const scriptElement = pageDocument.querySelector('script[data-dev-tag="inject-into"]');

      if (bodyElement) setHTMLCode(bodyElement.innerHTML);
      if (styleElement) setCSSCode(String(styleElement.textContent));
      if (scriptElement) setJavaScriptCode(scriptElement.innerHTML);

      if (nextPage.locale === "Default") {
        setContentUndoHistory([
          {
            timestamp: new Date().toLocaleString(),
            label: 'Initial',
            //content: ''
            content: nextPage.content
            // content: defaultProjectSourceFile
            // content: (projectModes as any)[selectedProjectMode].source
          },
        ]);
      } else {
        setContentUndoHistory([
          {
            timestamp: new Date().toLocaleString(),
            label: 'Initial',
            content: nextPage.content
          },
        ]);
      }

      // setContentUndoHistory([
      //   {
      //     timestamp: new Date().toLocaleString(),
      //     label: 'Initial',
      //     content: ''
      //     // content: defaultProjectSourceFile
      //     // content: (projectModes as any)[selectedProjectMode].source
      //   },
      // ]);


      const outlinerTree = buildOutlinerTree(pageDocument);
      setOutlinerTree(outlinerTree);

      resetUndoHistoryStepIndex();
      resetLocalContext();
      resetLocalContextCSS();

      setSelectedPage(nextPage);
      setConversationHistory(nextPage.conversation_history);
      setChatBubbles(nextPage.chat);
      setPageLinks(nextPage.links);
      // Handle forms
      if (isExtraFormsEnabled) {
        // Refresh the page content and forms before switching
        refreshPageWithForms(page_id);

        // Get the forms from the project forms map for this specific page
        const dbForms = projectForms.get(nextPage.id) || [];

        // Merge viewport forms with DB forms
        const mergedForms = (nextPage.forms || []).map((viewportForm: any) => {
          const matchingDbForm = dbForms.find((dbForm: any) =>
            dbForm.dom_id === viewportForm.dom_id &&
            dbForm.page_id === nextPage.id
          );

          if (matchingDbForm) {
            return {
              ...viewportForm,
              is_active: matchingDbForm.is_active,
              destination_email: matchingDbForm.destination_email,
              action: matchingDbForm.action
            };
          }
          return viewportForm;
        });

        setPageForms(mergedForms);
      } else {
        setPageForms(nextPage.forms || []);
      }

      setPageImages(nextPage.images);
      setViewportSourceDocument(nextPage.content);
    }
  }

  const handleDeletePage = async (page_id: string) => {
    await deletePage(page_id);
    if (page && page_id === page.id) {
      handleSwitchPage(pages[0].id);
    }
    onEditPageClose();
  }

  const [enhancedResponse, setEnhancedResponse] = useState<any>(null);

  const handleEnhanceCSS = async (options?: any) => {
    setIsEnhanceCoolingDown(true);

    let fixtype = options?.fixtype;
    let autofixerContext = options?.context;

    const cleanDevelopmentAttributes = (html: string) => {
      const parser = new DOMParser();
      const parsed = parser.parseFromString(html, 'text/html');

      const elemsWithDevAttribs = parsed.querySelectorAll('[data-parent]');
      elemsWithDevAttribs.forEach(elem => {
        elem.removeAttribute('data-parent');
        elem.removeAttribute('data-is-before');
        elem.removeAttribute('data-is-after');
      });

      const contentEditableElements = parsed.querySelectorAll('[contenteditable]');
      contentEditableElements.forEach(elem => {
        elem.removeAttribute('contenteditable');
      });

      const manualEditorElements = parsed.querySelectorAll('.sifo-manual-editor');
      manualEditorElements.forEach((meElement: any) => meElement.remove());

      return parsed.body.innerHTML;
    }

    if (!project) return;

    let htmlContext: any;
    let cssContext: any;

    if (localContextId) {
      htmlContext = localContextHTML;
      cssContext = localContextCSS;
    } else if (autofixerContext) {
      htmlContext = autofixerContext;
      cssContext = cssCode;
    } else {
      htmlContext = cleanDevelopmentAttributes(htmlCode);
      cssContext = cssCode;
    }

    console.log('[[ CONTEXT ]]', htmlContext, cssContext);

    // console.log(htmlContext);
    // console.log(cssContext);

    const enhanced = await getEnhancedCSS(
      htmlContext,
      cssContext,
      {
        mode: project.mode,
        model: userPreferences.ai_model,
        context: localContextId ? 'local' : 'global',
        fixtype
      }
    );



    // if (userPreferences.ai_model === 'model-1')
    //   enhanced = await getEnhancedCSS(localContextHTML, localContextCSS, { mode: project.mode });
    // else if (userPreferences.ai_model === 'sifo-0')
    //   enhanced = await getEnhancedCSS(localContextHTML, localContextCSS, { mode: project.mode, model: userPreferences.ai_model });

    setEnhancedResponse(enhanced);

    if (enhanced) {
      toast({
        title: "Enhanced successfully.",
        description: 'Your site styles have been enhanced.',
        variant: 'solid',
        status: 'success',
        position: 'bottom-left',
        duration: 2000,
      });
    }

    setTimeout(() => {
      setIsEnhanceCoolingDown(false);
    }, 10000)
  }

  const prepareDocumentForExport = (pageDocument: any, pageTitle: string, pageLocale: any) => {

    // const baseElement = pageDocument.querySelector('base');
    // baseElement.remove();

    const titleElement = document.createElement('title');
    // titleElement.textContent = `${pageTitle} | ${project?.name}`;
    titleElement.textContent = `${project?.title_display ? project.title_placement === 'left' ? project?.name + ' ' + project.title_separator : '' : ''} ${pageTitle} ${project?.title_display ? project.title_placement === 'right' ? project.title_separator + ' ' + project?.name : '' : ''}`;
    pageDocument.head.appendChild(titleElement);

    const faviconElement = document.createElement('link');
    faviconElement.rel = 'icon';
    faviconElement.type = 'image/x-icon';
    if (project) faviconElement.href = project?.favicon;
    pageDocument.head.appendChild(faviconElement);


    // The fact that everything works in the viewport means that I shouldn't touch anything.
    // Now, on export,






    // In a localized page,
    if (pageLocale !== 'Default') {

      const anchorTags = pageDocument.querySelectorAll('a');
      anchorTags.forEach((anchor: any) => {
        const anchorHref = anchor.getAttribute('href');
        if (!anchorHref.endsWith('.html')) return;

        const dividedAnchorHref = anchorHref.split('/');
        const localeShortForm = dividedAnchorHref[0]
        const locale = languages.find(lang => lang.shortForm === localeShortForm)?.name;

        // Trying to go to a localized page
        if (dividedAnchorHref.length === 2) {


          // If the page's locale is the same as thr links locale, e.g. fr and fr, remove the links locale, to allow navigation,
          if (locale === pageLocale) {
            anchor.setAttribute('href', dividedAnchorHref[1]);
          } else {
            anchor.setAttribute('href', `../${dividedAnchorHref[0]}/${dividedAnchorHref[1]}`);

          }

          // if it's existent (and is not the same as page's locale, go back, then go into locales directory, ../de/

          // Trying to go to a non-localized page
        } else if (dividedAnchorHref.length === 1) {
          // If the link's locale is not existent in languages, meaning it's Default, add ../ instead

          anchor.setAttribute('href', `../${dividedAnchorHref[0]}`);
        }

      })

    }

    // const formTags = pageDocument.querySelectorAll('form');
    // formTags.forEach((formTag: any) => formTag.setAttribute('target', '_blank'));

    const contentEditableElements = pageDocument.querySelectorAll('[contenteditable]');
    contentEditableElements.forEach((ceElement: any) => ceElement.removeAttribute('contenteditable'));

    const manualEditorElements = pageDocument.querySelectorAll('.sifo-manual-editor');
    manualEditorElements.forEach((meElement: any) => meElement.remove());

    const sectionLabels = pageDocument.querySelectorAll('.sifo-section-label');
    sectionLabels.forEach((sectionLabel: any) => sectionLabel.remove());

    const previewElements = pageDocument.querySelectorAll('[data-dev-tag="sifo-preview"]');
    previewElements.forEach((previewElement: any) => previewElement.remove());

    const developmentElements = pageDocument.querySelectorAll('[data-dev-tag="sifo-dev"]');
    developmentElements.forEach((devElement: any) => devElement.remove());

    const injectedElements = pageDocument.querySelectorAll('[data-dev-tag="inject-into"]');
    injectedElements.forEach((injectedElement: any) => {
      injectedElement?.removeAttribute('data-dev-tag');

      if (!injectedElement.textContent)
        injectedElement.remove()
    });

    pageDocument.head.removeAttribute('id');

    const elementsWithPositioningAttributes = pageDocument.querySelectorAll('[data-parent]');
    elementsWithPositioningAttributes.forEach((elementWithPositioningAttributes: any) => {
      elementWithPositioningAttributes.removeAttribute('data-parent');
      elementWithPositioningAttributes.removeAttribute('data-is-before');
      elementWithPositioningAttributes.removeAttribute('data-is-after');
    });

    return pageDocument;

  }

  const setIsDeployingSpinner = useSetRecoilState(IsDeployingSpinner);
  const isDeployingSpinner = useRecoilValue(IsDeployingSpinner);


  const handleDeploy = async () => {
    try {
      if (user?.plan_id === 'FREE_TRIAL') {
        onUpgradeOpen();
        return;
      }
      const sites: any = await fetchSites(currentUser.id);
      const deployedSitesCount = sites.length;
      console.log('SITES', sites)
      console.log('SITES', deployedSitesCount)

      if (deployedSitesCount < userAllottedCredits?.sites_allowed) {
        setIsDeployingSpinner(true);
        const projectFile = await handleCreateProjectZip();
        if (!projectFile) return;

        if (!currentUser?.id || !selectedProject?.id || !selectedProject?.name) {
          console.error('Missing required parameters: userId, projectId, projectName');
          return;
        }

        const site: any = await createSite(currentUser?.id, selectedProject?.id, selectedProject?.name);
        if (!site) return;

        const formData = new FormData();
        formData.append("project", projectFile);
        formData.append("sid", site.site_id);
        deploySite(formData);

        if (currentUser && selectedProject && site) {
          saveSite(
            site.id,
            currentUser?.id,
            selectedProject?.id,
            selectedProject?.name,
            site.url,
          );
        }

        setDeployedProject(site);
        setIsDeployingSpinner(false);
        onProjectDeployedOpen();
      } else {
        onUpgradeOpen();
      }
    } catch (error) {
      console.error('Error in handleDeploy:', error);
    }
  };
  const handleUpdate = async () => {
    try {
      setIsDeployingSpinner(true);
      console.log('Starting update process');

      const sites: any = await fetchSites(currentUser.id);
      console.log('Fetched sites:', sites);

      const siteToUpdate = sites.find((site: any) => site.project_id === selectedProject?.id);
      console.log('Site to update:', siteToUpdate);

      if (!siteToUpdate) {
        console.error('No matching site found for the selected project');
        setIsDeployingSpinner(false);
        return;
      }

      const projectFile = await handleCreateProjectZip();
      console.log('Project file created:', projectFile);
      if (!projectFile) {
        setIsDeployingSpinner(false);
        return;
      }

      if (!currentUser?.id || !selectedProject?.id || !selectedProject?.name) {
        console.error('Missing required parameters: userId, projectId, projectName');
        setIsDeployingSpinner(false);
        return;
      }

      const formData = new FormData();
      formData.append("project", projectFile);
      formData.append("distributionId", siteToUpdate.netlify_id);
      console.log('Form data prepared:', Object.fromEntries(formData));

      const result = await updateSite(formData);
      console.log('Update site result:', result);

      setIsDeployingSpinner(false);
      setUpdatedSiteUrl(siteToUpdate.url);
      onUpdateModalOpen();
    } catch (error) {
      console.error('Error in handleUpdate:', error);
      setIsDeployingSpinner(false);
    }
  };

  const [projectExportType, setProjectExportType] = useState('single');

  const handleRadioChange = (event: any, property: string) => {
    if (property === 'project-export-type') setProjectExportType(event);
  };

  const handleExportPage = async () => {
    // if (userAllottedCredits?.exports_allowed !== 0) {

    if (exportLocally.includes(user?.plan_id as string)) {
      if (!page) return;

      // const pageContent = await fetchPageContent(page.id) as string;
      const pageContent = page.content;
      if (!pageContent) return;

      const parser = new DOMParser();

      let pageDocument = parser.parseFromString(pageContent, 'text/html');

      if (projectExportType === 'separate') {
        const stylesheetFileContent = pageDocument.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
        const scriptFileContent = pageDocument.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;

        pageDocument.querySelector('style[data-dev-tag="inject-into"]')?.remove();
        pageDocument.querySelector('script[data-dev-tag="inject-into"]')?.remove();

        const pageDocumentClean = prepareDocumentForExport(pageDocument, page.title, page.locale);


        if (stylesheetFileContent) {
          let stylesheetLink = document.createElement('link');
          stylesheetLink.rel = 'stylesheet';
          stylesheetLink.type = 'text/css';
          stylesheetLink.href = 'styles.css';

          pageDocumentClean.querySelector('head').appendChild(stylesheetLink);
        }


        if (scriptFileContent) {
          let scriptTag = document.createElement('script');
          scriptTag.src = 'script.js';

          pageDocumentClean.querySelector('body').appendChild(scriptTag);
        }

        const pageDocumentStringified = pageDocumentClean.documentElement.outerHTML;

        const zip = new JSZip();
        const pageFolder = zip.folder(page.title as any);

        pageFolder?.file(`${page.title.toLowerCase().split(' ').join('-')}.html`, beautify_html(pageDocumentStringified).replace(/(\r\n|\r|\n){2,}/g, '\n'));

        if (stylesheetFileContent) {
          pageFolder?.file(`styles.css`, beautify_css(stylesheetFileContent));
        }

        if (scriptFileContent) {
          pageFolder?.file(`script.js`, beautify_js(scriptFileContent));
        }

        const blob = await zip.generateAsync({ type: "blob" });
        saveAs(blob, `${page.title}.zip`);
      } else if (projectExportType === 'single') {

        const pageDocumentStringified = prepareDocumentForExport(pageDocument, page.title, page.locale).documentElement.outerHTML;
        const blob = new Blob([beautify_html(pageDocumentStringified).replace(/(\r\n|\r|\n){2,}/g, '\n')], { type: "text/html; charset=utf-8" });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `${page.title.toLowerCase().split(' ').join('-')}.html`;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    } else {
      onUpgradeOpen();
    }

    // } else {
    // onUpgradeOpen();
    // }
  };

  const handleCreateProjectZip = async (deployable?: boolean) => {

    // if (exportLocally.includes(user?.plan_id as string)) {
    if (!project) return;

    // const pages: any = await fetchAllPagesContents(project.id);
    if (!pages) return;

    const zip = new JSZip();
    const projectFolder = zip.folder(project?.name as any);

    console.log('project?.name as any: ', project?.name as any);
    console.log('projectFolder: ', projectFolder);

    const parser = new DOMParser();

    pages.forEach((page: any) => {
      let pageDocument = parser.parseFromString(page.content, 'text/html');
      const languageShortForm = languages.find(lang => lang.name === page.locale)?.shortForm;

      console.log('languageShortForm: ', languageShortForm);

      if (deployable || projectExportType === 'single') {

        const pageDocumentStringified = prepareDocumentForExport(pageDocument, page.title, page.locale).documentElement.outerHTML
        projectFolder?.file(
          // `${page.locale === 'Default' ? '' : languageShortForm + '/'}${page.is_index ? 'index' : page.title.toLowerCase().split(' ').join('-')}.html`,
          page.path,
          beautify_html(pageDocumentStringified).replace(/(\r\n|\r|\n){2,}/g, '\n')
        );

      } else if (projectExportType === 'separate') {

        const stylesheetFileContent = pageDocument.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
        const scriptFileContent = pageDocument.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;

        pageDocument.querySelector('style[data-dev-tag="inject-into"]')?.remove();
        pageDocument.querySelector('script[data-dev-tag="inject-into"]')?.remove();

        const pageDocumentClean = prepareDocumentForExport(pageDocument, page.title, page.locale);

        if (stylesheetFileContent) {
          let stylesheetLink = document.createElement('link');
          stylesheetLink.rel = 'stylesheet';
          stylesheetLink.type = 'text/css';
          stylesheetLink.href = `assets/stylesheets/${page.is_index ? 'index' : page.title.toLowerCase().split(' ').join('-')}.css`;

          pageDocumentClean.querySelector('head').appendChild(stylesheetLink);
        }

        if (scriptFileContent) {
          let scriptTag = document.createElement('script');
          scriptTag.src = `assets/scripts/${page.is_index ? 'index' : page.title.toLowerCase().split(' ').join('-')}.js`;

          pageDocumentClean.querySelector('body').appendChild(scriptTag);
        }

        const pageDocumentStringified = pageDocumentClean.documentElement.outerHTML;
        // projectFolder?.file(`${page.locale === 'Default' ? '' : languageShortForm + '/'}${page.is_index ? 'index' : page.title.toLowerCase().split(' ').join('-')}.html`, beautify_html(pageDocumentStringified).replace(/(\r\n|\r|\n){2,}/g, '\n'));
        projectFolder?.file(page.path, beautify_html(pageDocumentStringified).replace(/(\r\n|\r|\n){2,}/g, '\n'));

        if (stylesheetFileContent) {
          projectFolder?.file(
            `${page.locale === 'Default' ? '' : languageShortForm + '/'}assets/stylesheets/${page.is_index ? 'index' : page.title.toLowerCase().split(' ').join('-')}.css`,
            beautify_css(stylesheetFileContent)
          );
        }

        if (scriptFileContent) {
          projectFolder?.file(
            `${page.locale === 'Default' ? '' : languageShortForm + '/'}assets/scripts/${page.is_index ? 'index' : page.title.toLowerCase().split(' ').join('-')}.js`,
            beautify_js(scriptFileContent)
          );
        }

      }
    });

    const blob = await zip.generateAsync({ type: "blob" });
    return blob;
    // } else {
    //   onUpgradeOpen();
    // }

  };

  const handleExportProject = async () => {
    if (exportLocally.includes(user?.plan_id as string)) {
      if (!project) return;

      if (userAllottedCredits?.exports_allowed !== 0) {
        const blob: any = await handleCreateProjectZip();
        saveAs(blob, `${project?.name}.zip`);
      } else {
        onUpgradeOpen();
      }
    } else {
      onUpgradeOpen();
    }
  }

  useEffect(() => {
    if (pages.length === 0) return;

    const handleClick = (e: any) => {
      if (!page) return;

      if (typeof e.data === 'string') {
        if (e.data.endsWith('.html')) {
          const href = e.data;

          const pageToSwitchTo = pages.find((_page: Page) => {

            console.log('href:', href);

            // I need to automatically translate links to in applyTranslation
            // so what i need is on export, on all pages that are localized, i remove prefixes from their links?

            if (href.includes('/')) {

              const dividedHref = href.split('/');

              const localeShortForm = dividedHref[0];
              const path = dividedHref[1];
              const locale = languages.find(lang => lang.shortForm === localeShortForm)?.name;

              if (_page.locale === locale && _page.path === path) {
                return true;
              }
            }

            if (_page.path === href) {
              return true;
            }

          });

          if (pageToSwitchTo) {
            handleSwitchPage(pageToSwitchTo?.id);
          } else {
            toast({
              title: "Page does not exist.",
              description: 'The requested page does not exist.',
              variant: 'solid',
              status: 'error',
              position: 'bottom-left',
              duration: 6000,
            });
          }

        }
      }
    };

    window.addEventListener('message', handleClick);

    return () => {
      window.removeEventListener('message', handleClick);
    };
  }, [pages]);


  // V1
  // const findPageNameSimilarityLevel = (firstPage: string, secondPage: string) => {
  //   firstPage = firstPage.replace(/\s/g, "").toLowerCase();
  //   secondPage = secondPage.replace(/\s/g, "").toLowerCase();

  //   const minimumLength: number = Math.min(firstPage.length, secondPage.length);

  //   let matches: number = 0;
  //   for (let i = 0; i < minimumLength; i++) {
  //     if (firstPage[i] === secondPage[i]) {
  //       matches++;
  //     }
  //   }

  //   const score: number = (matches / minimumLength) * 100;

  //   return Math.round(score);
  // }

  // V2
  function findPageNameSimilarityLevel(firstPage: any, secondPage: any) {
    const cleanFirstPage: any = firstPage.replace(/\s/g, "").toLowerCase();
    const cleanSecondPage: any = secondPage.replace(/\s/g, "").toLowerCase();

    const intersection = new Set([...cleanFirstPage].filter(x => cleanSecondPage.includes(x)));
    const union = new Set([...cleanFirstPage, ...cleanSecondPage]);

    const score = (intersection.size / union.size) * 100;

    return Math.round(score);
  }

  // Create pages automatically when detecting links meant to be for internal use
  useEffect(() => {
    if (!userPreferences.create_pages_automatically_on_linking) return;
    if (pages.length === 0) return;

    if (pageLinks) {
      pageLinks.forEach((link: any) => {
        if (link.type === 'external') return;

        // const correspondingPage = pages.find((page: Page) => page.title.toLowerCase() === link.inner.toLowerCase());
        const correspondingPage = pages.find((page: Page) => {
          if (page.title.toLowerCase() === link.inner.toLowerCase() && findPageNameSimilarityLevel(link.inner, page.title) > 50) {
            return true;
          }
        });


        if (!correspondingPage) {
          handleCreatePage(link.inner, false);
        }
      })
    }
  }, [pageLinks])


  // Handle page creation when triggered from the links panel
  useEffect(() => {
    if (!createPageFromLink) return;

    async function runCreatePageFromLink() {
      await handleCreatePage(createPageFromLink, false);

      toast({
        title: `${createPageFromLink} created`,
        description: `Go to ${createPageFromLink} and start creating.`,
        variant: 'solid',
        status: 'success',
        position: 'bottom-left',
        duration: 3000,
      });

      setCreatePageFromLink(null);
    }

    runCreatePageFromLink();


  }, [createPageFromLink])

  // Handle go to page when triggered from the links panel
  useEffect(() => {
    if (goToPageFromLink) {

      const pageToSwitchTo = pages.find((_page: Page) => {
        if (_page.path === goToPageFromLink) return true;
      })

      if (pageToSwitchTo) {
        handleSwitchPage(pageToSwitchTo?.id);
      }

      setGoToPageFromLink(null);
    }
  }, [goToPageFromLink])

  function normalizeEmail(email: string) {
    const popularProviders = [
      'gmail.com',
      'yahoo.com',
      'hotmail.com',
      'outlook.com',
      'icloud.com',
      'aol.com',
      'protonmail.com',
      'mail.com'
    ];

    if (email.includes('@')) {
      return email;
    }

    const removeExtraSpaces = (str: any) => str.replace(/\s+/g, ' ').trim();

    for (const provider of popularProviders) {
      const providerIndex = email.toLowerCase().indexOf(provider);
      if (providerIndex !== -1) {
        const beforeProvider = email.slice(0, providerIndex).trim();
        const username = removeExtraSpaces(beforeProvider.replace(/at$/i, ''));
        return `${username}@${provider}`;
      }
    }

    const atRegex = /(\S+)\s*at\s*(\S+)/i;
    const match = email.match(atRegex);

    if (match) {
      const [, username, domain] = match;
      return `${removeExtraSpaces(username)}@${removeExtraSpaces(domain)}`;
    }

    return email;
  }

  function generateWrongEmails(email: string) {
    if (typeof email !== 'string' || !email.includes('@')) {
      throw new Error('Invalid email format');
    }

    const wrongEmails = [
      email.replace('@', ' at '),
      email.replace('@', 'at')
    ];

    return wrongEmails;
  }

  function replaceWrongEmails(text: string, correctEmail: string) {
    const wrongEmails = generateWrongEmails(correctEmail);
    let modifiedText = text;

    wrongEmails.forEach(wrongEmail => {
      const regex = new RegExp(wrongEmail, 'g');
      modifiedText = modifiedText.replace(regex, correctEmail);
    });

    return modifiedText;
  }

  useEffect(() => {
    if (triggeredFunction) {
      // if (triggeredFunction.name === 'add_new_page') {
      if (triggeredFunction.name === 'new_screen') {
        const args = JSON.parse(triggeredFunction.arguments);
        if (!args.title) return;

        handleCreatePage(args.title);
      }

      if (triggeredFunction.name === 'delete_current_page') {
        if (page && page.id) handleDeletePage(page.id);
      }

      if (triggeredFunction.name === 'export_current_page') {
        const args = JSON.parse(triggeredFunction.arguments);
        if (!args.mode) return;

        if (args.mode === 'single_file') {
          setProjectExportType('single');
        } else if (args.mode === 'multiple_files') {
          setProjectExportType('separate');
        }

        handleExportPage();
      }

      if (triggeredFunction.name === 'export_website') {
        const args = JSON.parse(triggeredFunction.arguments);
        if (!args.mode) return;

        if (args.mode === 'single_file') {
          setProjectExportType('single');
        } else if (args.mode === 'multiple_files') {
          setProjectExportType('separate');
        }

        handleExportProject();
      }

      if (triggeredFunction.name === 'translate') {
        const args = JSON.parse(triggeredFunction.arguments);

        console.log('args: ', args);
        if (!args.mode) return;
        if (!args.language) return;

        // setTranslationTargetLanguage(args.language);

        console.log('args.mode: ', args.mode);
        console.log('args.language: ', args.language);

        if (args.mode === 'current_page') {
          handleTranslatePage(args.language);
        } else if (args.mode === 'full_project') {
          handleTranslateProject(args.language);
        }
      }

      if (triggeredFunction.name === 'publish_website') {
        handleDeploy();
      }

      if (triggeredFunction.name === 'toggle_pages_panel') {
        togglePages();
      }

      if (triggeredFunction.name === 'toggle_outliner_panel') {
        toggleOutliner();
      }

      if (triggeredFunction.name === 'toggle_undo_history_panel') {
        toggleUndo();
      }

      if (triggeredFunction.name === 'toggle_linking_and_navigation_panel') {
        toggleLinks();
      }

      if (triggeredFunction.name === 'toggle_images_panel') {
        toggleImages();
      }

      if (triggeredFunction.name === 'toggle_developer_mode_panel') {
        toggleDevMode();
      }

      if (triggeredFunction.name === 'enhance_selected_element') {
        handleEnhanceCSS();
      }

      if (triggeredFunction.name === 'enable_form') {
        const args = JSON.parse(triggeredFunction.arguments);
        if (!args.destination_email) return;

        let formId = null;

        if (localContext && localContext.tagName === "FORM") {
          formId = localContextId;
        } else if (pageForms.length > 0) {
          const firstForm = pageForms[0];
          formId = (firstForm as any).dom_id;
        } else {
          formId = null;
        }

        const destinationEmail = args.destination_email;
        const normalizedDestinationEmail = normalizeEmail(destinationEmail);

        if (formId) {
          handleEnableForm(formId, normalizedDestinationEmail);

          setChatBubbles((prev) => {
            const newChatBubbles = [...prev];
            const lastItem = newChatBubbles[newChatBubbles.length - 1];
            const updatedContent = replaceWrongEmails(lastItem.content, normalizedDestinationEmail);

            newChatBubbles[newChatBubbles.length - 1] = {
              ...lastItem,
              date: Date.now(),
              content: updatedContent
            };

            return newChatBubbles;
          });

        } else {
          toast({
            description: 'Please select a form and try again.',
            variant: 'solid',
            status: 'error',
            position: 'bottom-left',
            duration: 6000,
          });
        }

        // if (localContext) {
        //   handleEnableForm(localContextId, args.destination_email);
        // } else {
        //   toast({
        //     description: 'Please select a form and try again.',
        //     variant: 'solid',
        //     status: 'error',
        //     position: 'bottom-left',
        //     duration: 6000,
        //   });
        // }
      }

      setTriggeredFunction(null);
    }
  }, [triggeredFunction])

  const { isOpen: isTranslateOpen, onOpen: onTranslateOpen, onClose: onTranslateClose } = useDisclosure();
  const { isOpen: isUpdateOpen, onOpen: onUpdateOpen, onClose: onUpdateClose } = useDisclosure();

  const [translationTargetLanguage, setTranslationTargetLanguage] = useState<any>('');

  const handleTranslationTargetLanguageOnChange = (event: any) => {
    if (event.target.value) {
      setTranslationTargetLanguage(event.target.value);
    }
  }

  // const languages = useMemo(() => [
  //   { name: "English", shortForm: "en" },
  //   { name: "Spanish", shortForm: "es" },
  //   { name: "French", shortForm: "fr" },
  //   { name: "German", shortForm: "de" },
  //   { name: "Portuguese", shortForm: "pt" },
  //   { name: "Russian", shortForm: "ru" },
  //   { name: "Chinese", shortForm: "zh" },
  //   { name: "Japanese", shortForm: "ja" },
  //   { name: "Arabic", shortForm: "ar" },
  //   { name: "Hindi", shortForm: "hi" },
  //   { name: "Bengali", shortForm: "bn" },
  //   { name: "Urdu", shortForm: "ur" },
  //   { name: "Korean", shortForm: "ko" },
  //   { name: "Italian", shortForm: "it" },
  // ], []);


  function prependLocalization(path: any, localization: any) {
    path = path.replace(/^\.\//, '');
    path = path.replace(/^\//, '');

    if (path.startsWith(`${localization}/`)) {
      return path;
    } else {

      return `${localization}/${path}`;
    }
  }

  const handleApplyTranslation = async (page: any, translation: any, language: any) => {
    if (!page) return;

    console.log('Page:', page);

    const parser = new DOMParser();
    const pageDocument = parser.parseFromString(page.content, 'text/html');
    if (!pageDocument) return;

    console.log('pageDocument:', pageDocument);

    // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@", translation, "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");

    // try {

    const parsedTranslation: any = csvToObject(cleanTranslation(translation) as string);
    // const parsedTranslation: any = JSON.parse(cleanTranslation(translation) as string);
    // const parsedTranslation: any = JSON.parse(translation as string);
    // const parsedTranslation: any = csvToObject(translation as string);
    // const parsedTranslation: any = parseTolerant(repairJson(cleanTranslation(translation) as string));

    console.log("#### translation, parsedTranslation: ", translation, parsedTranslation);

    // Insert in page,
    for (const key in parsedTranslation) {
      console.log("key: ", key);
      if (parsedTranslation.hasOwnProperty(key)) {
        const value = parsedTranslation[key];
        // try {
        const element = pageDocument.querySelector(`#${key}`);

        if (element) {
          // element.textContent = value;
          element.innerHTML = value;
          element.innerHTML = element.innerHTML.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
        } else {
          console.error(`Element with ID '${key}' not found.`);
        }
        // } catch {
        //   throw new Error('An error occured.')
        // }
      }
    }

    const languageShortForm = languages.find(lang => lang.name === language)?.shortForm;

    console.log("#### languageShortForm: ", languageShortForm);


    const pageAnchorsTags: any = pageDocument.querySelectorAll('a');

    console.log("#### pageAnchorsTags: ", pageAnchorsTags);


    pageAnchorsTags.forEach((anchor: any) => {
      console.log("### anchor not-if: ", anchor);

      if (anchor.getAttribute('href') && anchor.getAttribute('href').endsWith('.html')) {
        console.log("### anchor yes-if: ", anchor);
        anchor.setAttribute('href', prependLocalization(anchor.getAttribute('href'), languageShortForm));
      }

      console.log("...Done?...");
    })


    // Create new page,

    // console.log(
    //   `projectId: ${page.project_id}`,
    //   `title: ${page.title}`,
    //   `path: `, `${languageShortForm}/${page.is_index ? 'index' : page.title.toLowerCase().split(' ').join('-')}.html`,
    //   `content: ${pageDocument.documentElement.outerHTML}`,
    //   // `conversationHistory: ${page.conversation_history}`,
    //   `conversationHistory: ${[]}`,
    //   `chat: ${page.chat}`,
    //   `links: ${constructPageLinksArray(pageAnchorsTags)}`,
    //   `images: ${page.images}`,
    //   `forms: ${page.forms}`,
    //   `locale: ${language}`,
    // );

    // console.log('### props: ',
    //   {
    //     projectId: page.project_id,
    //     title: page.title,
    //     path: `${languageShortForm}/${page.is_index ? 'index' : page.title.toLowerCase().split(' ').join('-')}.html`,
    //     content: pageDocument.documentElement.outerHTML,
    //     // conversationHistory: page.conversation_history,
    //     conversationHistory: [],
    //     chat: page.chat,
    //     links: constructPageLinksArray(pageAnchorsTags),
    //     images: page.images,
    //     forms: page.forms, // this is supposed to already be snake case, that's why me trying to convert it gives undefined exept for action
    //     locale: language,
    //     isIndex: false

    //     // isIndex: page.is_index ? true : false // Hmmm?
    //     // title: `${page.title} - ${languageShortForm || translationTargetLanguage}`,
    //     // links: page.links,
    //   }
    // );

    await createPage(
      {
        projectId: page.project_id,
        title: page.title,
        path: `${languageShortForm}/${page.is_index ? 'index' : page.title.toLowerCase().split(' ').join('-')}.html`,
        content: pageDocument.documentElement.outerHTML,
        // conversationHistory: page.conversation_history,
        conversationHistory: [],
        chat: page.chat,
        links: constructPageLinksArray(pageAnchorsTags),
        images: page.images,
        forms: page.forms, // this is supposed to already be snake case, that's why me trying to convert it gives undefined exept for action
        locale: language,
        isIndex: false

        // isIndex: page.is_index ? true : false // Hmmm?
        // title: `${page.title} - ${languageShortForm || translationTargetLanguage}`,
        // links: page.links,
      }
    );

    // } catch {
    //   throw new Error('Failed to complete parsing and translation.');
    // }
  }

  const handleTranslating = () => {
    setCurrentMessageGroupType(MessageGroupTypes.TRANSLATING);
    setIsGeneratingLoadingScreen(true);
  };

  let timer;


    const invokeLambdaTranslation = async (payload:any) => {
      const lambdaPayload = {
          FunctionName: lambdaFunctionName,
          InvocationType: 'RequestResponse',
          Payload: JSON.stringify(payload)
      };

      try {
          const lambdaResponse = await lambda.invoke(lambdaPayload).promise();
          const responsePayload = JSON.parse(lambdaResponse.Payload as any);

          if (responsePayload.statusCode === 200) {
              return JSON.parse(responsePayload.body);
          } else {
              throw new Error(responsePayload.body);
          }
      } catch (error) {
          console.error('Lambda invocation error:', error);
          throw error;
      }
  };

  const handleTranslatePage = async (language: any) => {
    if (!language || !page) return;

    const uniqueLanguagesUsed = new Set();
    pages.forEach((page: Page) => {
        if (page.locale) uniqueLanguagesUsed.add(page.locale);
    });

    const usedLanguagesCount = uniqueLanguagesUsed.size;
    if (usedLanguagesCount < userAllottedCredits?.translations_allowed) {
        try {
            const parser = new DOMParser();
            const pageDocument = parser.parseFromString(page.content, 'text/html');
            if (!pageDocument) return;

            const localizationMap = buildLocalizationMap(pageDocument);
            if (isTranslateOpen) onTranslateClose();
            setIsGeneratingSpinner(true);
            timer = setTimeout(() => {
              // setIsGeneratingLoadingScreen(true);
              handleTranslating();
            }, 2000);
            const response = await invokeLambdaTranslation({
                context: 'translate',
                type: 'page',
                localizationData: localizationMap,
                language,
                userId: user?.id
            });

            if (response && response.translation) {
                handleApplyTranslation(page, response.translation, language);

                toast({
                    title: 'Translated successfully.',
                    description: 'Open pages panel to navigate to your translated pages.',
                    variant: 'solid',
                    status: 'info',
                    position: 'bottom-left',
                    duration: 3000,
                });
                setIsGeneratingSpinner(false);
                setIsGeneratingLoadingScreen(false);
                clearTimeout(timer);
                resetIsGeneratingSpinner();
                resetIsGeneratingLoadingScreen();


            } else {
                throw new Error("No translation in response");
            }
        } catch (error: any) {
            if (error.message === 'Translation limit reached') {
                onUpgradeOpen();
            } else {
                console.error('Translation error:', error);
                toast({
                    title: 'Translation failed.',
                    description: 'An error occurred while translating the page.',
                    status: 'error',
                    position: 'bottom-left',
                    duration: 3000,
                });
            }
        }
    } else {
        onUpgradeOpen();
    }
  };


  // Update project translation handler
  const handleTranslateProject = async (language: any) => {
    if (!language || !pages) return;

    const uniqueLanguagesUsed = new Set();
    pages.forEach((page: Page) => {
        if (page.locale) uniqueLanguagesUsed.add(page.locale);
    });

    const usedLanguagesCount = uniqueLanguagesUsed.size;

    if (usedLanguagesCount < userAllottedCredits?.translations_allowed) {
        try {
            const parser = new DOMParser();
            const localizationMaps = pages
                .map((page: Page) => {
                    if (page.locale === 'Default') {
                        const pageDocument = parser.parseFromString(page.content, 'text/html');
                        return {
                            pid: page.id,
                            map: buildLocalizationMap(pageDocument)
                        };
                    }
                    return null;
                })
                .filter(Boolean);
                if (isTranslateOpen) onTranslateClose();
                setIsGeneratingSpinner(true);
                timer = setTimeout(() => {
                  // setIsGeneratingLoadingScreen(true);
                  handleTranslating();
                }, 2000);
            const response = await invokeLambdaTranslation({
                context: 'translate',
                type: 'project',
                localizationData: localizationMaps,
                language,
                userId: user?.id
            });

            if (response && response.translations) {
                response.translations.forEach((translation: any) => {
                    const correspondingPage = pages.find((page: Page) => page.id === translation.pid);
                    if (correspondingPage) {
                        handleApplyTranslation(correspondingPage, translation.map, language);
                    }
                });


                toast({
                    title: 'Translated successfully.',
                    description: 'Open pages panel to navigate to your translated pages.',
                    variant: 'solid',
                    status: 'info',
                    position: 'bottom-left',
                    duration: 3000,
                });
                setIsGeneratingSpinner(false);
                setIsGeneratingLoadingScreen(false);
                clearTimeout(timer);
                resetIsGeneratingSpinner();
                resetIsGeneratingLoadingScreen();

            } else {
                throw new Error("No translations found in response");
            }
        } catch (error: any) {
            if (error.message === 'Translation limit reached') {
                onUpgradeOpen();
            } else {
                console.error('Translation error:', error);
                toast({
                    title: 'Translation failed.',
                    description: 'An error occurred while translating the project.',
                    status: 'error',
                    position: 'bottom-left',
                    duration: 3000,
                });
            }
        }
    } else {
        onUpgradeOpen();
    }
  };









  // const handleTranslatePage = async (language: any) => {

  //   if (!language) return;
  //   if (!page) return;

  //   const uniqueLanguagesUsed = new Set();

  //   pages.forEach((page: Page) => {
  //     const pageLocale = page.locale;

  //     if (pageLocale) {
  //       uniqueLanguagesUsed.add(pageLocale);
  //     }
  //   })

  //   const usedLanguagesCount = uniqueLanguagesUsed.size;

  //   if (usedLanguagesCount < userAllottedCredits?.translations_allowed) {
  //     const parser = new DOMParser();
  //     const pageDocument = parser.parseFromString(page.content, 'text/html');
  //     if (!pageDocument) return;

  //     const localizationMap = buildLocalizationMap(pageDocument);
  //     console.log("##### localizationMap: ", localizationMap);

  //     // const translation: any = await getPageTranslation(JSON.stringify(localizationMap), language);
  //     const translation: any = await getPageTranslation(localizationMap, language);
  //     console.log("##### translation: ", translation);

  //     handleApplyTranslation(page, translation, language);
  //     if (isTranslateOpen) onTranslateClose();
  //     setIsGeneratingSpinner(true);
  //     timer = setTimeout(() => {
  //       // setIsGeneratingLoadingScreen(true);
  //       handleTranslating();
  //     }, 2000);
  //     toast({
  //       title: 'Translated successfully.',
  //       description: 'Open pages panel to navigate to your translated pages.',
  //       variant: 'solid',
  //       status: 'info',
  //       position: 'bottom-left',
  //       duration: 3000,
  //     });

  //     setIsGeneratingSpinner(false);
  //     setIsGeneratingLoadingScreen(false);
  //     clearTimeout(timer);
  //     resetIsGeneratingSpinner();
  //     resetIsGeneratingLoadingScreen();

  //   } else {
  //     onUpgradeOpen();
  //   }

  // }

  // const handleTranslateProject = async (language: any) => {

  //   if (!language) return;
  //   if (!pages) return;

  //   const uniqueLanguagesUsed = new Set();

  //   pages.forEach((page: Page) => {
  //     const pageLocale = page.locale;

  //     if (pageLocale) {
  //       uniqueLanguagesUsed.add(pageLocale);
  //     }
  //   })

  //   const usedLanguagesCount = uniqueLanguagesUsed.size;

  //   if (usedLanguagesCount < userAllottedCredits?.translations_allowed) {
  //     const parser = new DOMParser();

  //     const localizationMaps = pages.map((page: Page) => {
  //       console.log('page.locale: ', page.locale);

  //       const pageDocument = parser.parseFromString(page.content, 'text/html');
  //       console.log("@@@ buildLocalizationMap(pageDocument) @@@", buildLocalizationMap(pageDocument));

  //       if (page.locale === 'Default') {
  //         const pageDocument = parser.parseFromString(page.content, 'text/html');


  //         return {
  //           pid: page.id,
  //           map: buildLocalizationMap(pageDocument)
  //           // map: JSON.stringify(buildLocalizationMap(pageDocument))
  //         }
  //       } else return null;
  //     }).filter(Boolean);


  //     // console.log('localizationMaps: ', localizationMaps);
  //     // console.log('### PARSED localizationMaps: ', JSON.parse(localizationMaps[0]!.map as string));

  //     const translations: any = await getProjectTranslation(localizationMaps, language);

  //     // console.log("//////////// translations: ////////////", translations);

  //     translations.forEach((translation: any) => {
  //       const correspondingPage = pages.find((page: Page) => page.id === translation.pid);
  //       handleApplyTranslation(correspondingPage, translation.map, language);
  //     })

  //     if (isTranslateOpen) onTranslateClose();
  //     setIsGeneratingSpinner(true);
  //     timer = setTimeout(() => {
  //       // setIsGeneratingLoadingScreen(true);
  //       handleTranslating();
  //     }, 2000);
  //     toast({
  //       title: 'Translated successfully.',
  //       description: 'Open pages panel to navigate to your translated pages.',
  //       variant: 'solid',
  //       status: 'info',
  //       position: 'bottom-left',
  //       duration: 3000,
  //     });


  //     setIsGeneratingSpinner(false);
  //     setIsGeneratingLoadingScreen(false);
  //     clearTimeout(timer);
  //     resetIsGeneratingSpinner();
  //     resetIsGeneratingLoadingScreen();
  //   } else {
  //     onUpgradeOpen();
  //   }

  // }






  const pagesByLocale = useMemo(() => {
    return pages.reduce((locales: any, page: any) => {
      const locale: any = page.locale;
      if (!locale) return;

      if (!locales[locale]) {
        locales[locale] = [];
      }

      locales[locale].push(page);
      return locales;
    }, {});
  }, [pages]);

  // window.onmessage = (e) => {
  //   console.log('Message evemt: ',  e.data);
  //   // if (e.data === 'about.html') {
  //   //   let href = e.data;
  //   //   console.log('Got the Internal link, I can now trigger handlePageSwitch: ', href);
  //   // }
  // }

  const { enableForm, getProjectForms } = useFormActions();
  const setPageFormsHaveBeenUpdated = useSetRecoilState(PageFormsHaveBeenUpdated);
  const setExtraFormsEnabled = useSetRecoilState(extraFormsEnabled);

  // const resetUndoHistoryAfterFormEnabled = useRecoilCallback(({ set }) => () => {
  //   set(ContentUndoHistory, (prev) => prev.slice(undoHistoryStepIndex + 1));
  //   set(UndoHistoryStepIndex, 0);
  // }, []);

  // const resetUndoHistoryAfterFormEnabled = useRecoilTransaction_UNSTABLE(({ get, set }) => () => {
  //   const undoHistoryStepIndex = get(UndoHistoryStepIndex);
  //   const contentUndoHistory = get(ContentUndoHistory);

  //   set(ContentUndoHistory, contentUndoHistory.slice(undoHistoryStepIndex + 1));
  //   set(UndoHistoryStepIndex, 0);
  // });

  const handleEnableForm = async (domId: string, destinationEmail: string) => {
    if (!user || !project || !page) return;

    const { form: enabledForm, additionalForms } = await enableForm({
      userId: user.id,
      projectId: project.id,
      pageId: page.id,
      domId,
      destinationEmail
    });

    if (!enabledForm) throw new Error('Form could not be enabled.')
    const enabledFormId = enabledForm.id;
    const formAction = `${process.env.REACT_APP_API_URL}forms/handle-submission/${enabledFormId}`;

    // Update main form
    setPageForms((previousPageForms: any) => {
      return previousPageForms.map((form: any) => {
        if (form.dom_id === domId) {
          return {
            ...form,
            action: formAction,
            destination_email: destinationEmail,
            is_active: true
          };
        }
        return form;
      });
    });

    // Handle additional forms
    if (additionalForms && additionalForms.length > 0) {
      setExtraFormsEnabled(true)
      for (const additionalForm of additionalForms) {
        const content = await fetchPageContentWithoutViewport(additionalForm.page_id);
        if (content) {
          const parser: any = new DOMParser();
          const doc = parser.parseFromString(content, 'text/html');
          const formElement = doc.querySelector(`#${domId}`);

          if (formElement) {
            formElement.setAttribute('action', formAction);
            formElement.setAttribute('method', 'post');
            formElement.removeAttribute('onsubmit');

            // Update the content in the background
            await updatePageContent(
              additionalForm.page_id,
              doc.documentElement.outerHTML
            );
          }
        }
      }

      // Refetch all project forms after enabling additional forms
      const allForms: any = await getProjectForms(project.id);

      // Organize forms by page_id
      const formsByPage: any = new Map();
      allForms.forEach((form: any) => {
        if (!formsByPage.has(form.page_id)) {
          formsByPage.set(form.page_id, []);
        }
        const formWithAction = {
          ...form,
          is_active: Boolean(form.is_active),
          action: formAction // Add the action to all related forms
        };
        formsByPage.get(form.page_id).push(formWithAction);
      });

      setProjectForms(formsByPage);
    }

    setPageFormsHaveBeenUpdated(true);

    // Primary success toast
    toast({
      title: "Form enabled.",
      description: `The form is now ready to receive submissions to ${destinationEmail}.`,
      variant: 'solid',
      status: 'success',
      position: 'bottom-left',
      duration: 3000,
    });

    // Additional forms toast if any were found and enabled
    if (additionalForms && additionalForms.length > 0) {
      setTimeout(() => {
        toast({
          title: "Additional forms enabled",
          description: `Detected and Enabled ${additionalForms.length} Other Form(s) matching this one`,
          variant: 'solid',
          status: 'info',
          position: 'bottom-left',
          duration: 3000,
        });
      }, 3500);
    }

    setContentUndoHistory((prevHistory) => prevHistory.slice(undoHistoryStepIndex + 1));
  };

  // useEffect(() => {
  //     const htmlParser = new DOMParser();
  //     let parsedDocument = htmlParser.parseFromString(localContextHTML, 'text/html');
  //     const highlightedElement = parsedDocument.querySelector(`#${localContextId}`);

  //     if (!highlightedElement) return;

  //     if (highlightedElement.tagName === 'A') {
  //       if (!isLinksVisible) {
  //         setIsLinksVisible(true);
  //       }
  //     }

  //     if (highlightedElement.tagName === 'IMG') {
  //       if (!isImagesVisible) {
  //         setIsImagesVisible(true);
  //       }
  //     }

  //     if (highlightedElement.tagName === 'FORM') {
  //       if (!isFormsVisible) {
  //         setIsFormsVisible(true);
  //       }
  //     }

  // }, [localContextId, localContextHTML])

  useEffect(() => {
    if (!localContext) return;

    console.log(localContext);

    if (localContext.tagName === 'A') {
      if (!isLinksVisible) {
        setIsLinksVisible(true);
      }
    }

    if (localContext.tagName === 'IMG' || hasBackgroundImage(localContext)) {
      if (!isImagesVisible) {
        setIsImagesVisible(true);
      }
    }

    if (localContext.tagName === 'FORM') {
      if (!isFormsVisible) {
        setIsFormsVisible(true);
      }
    }

  }, [localContext])


  const onStartFromScratch = () => {
    onResetConfirmationOpen();
  }

  const batchUpdatePages = async (pages: any[], batchSize: number = 2) => {
    const batches = [];
    for (let i = 0; i < pages.length; i += batchSize) {
      batches.push(pages.slice(i, i + batchSize));
    }
  
    for (const batch of batches) {
      const updateBatch = batch.map((page: any) => ({
        id: page.id,
        content: page.content
      }));
      await updateProjectPages(updateBatch);
    }
  };

  const handleConfirmStartFromScratch = async () => {
    if (fromTemplate) {
      // Set page content to page initial_content.
      const resetPages = pages.map((page: any) => {
        const newPage = { ...page };    
        newPage.content = newPage.initial_content;
        return newPage;
      });

      setPages(resetPages);
      setSelectedPage(resetPages[0]);
      setViewportSourceDocument(resetPages[0].content);

      onResetConfirmationClose();

      const updateDatabasePages = resetPages.map((page: any) => {
        return {
          id: page.id,
          content: page.content
        }
      })
  
      await batchUpdatePages(updateDatabasePages);

    } else {
      setWipeIframeContent(true);
      onResetConfirmationClose();
    }

    // onResetConfirmationClose();
  }

  // Close chat panel after user sends prompt on mobile
  useEffect(() => {
    if (isWideScreen) return;
    if (!chatBubbles) return;

    if (isChatVisible && chatBubbles[chatBubbles.length - 1].source === "user") {
      setIsChatVisible(false);
    }
  }, [chatBubbles])

  const handleClickInProcessorWhenMobile = () => {
    // console.log('Hello world, this is before the check.');
    if (isWideScreen) return;

    // console.log('Hello world, this is after the check.');

    if (isChatVisible) setIsChatVisible(false);
    if (isUndoVisible) setIsUndoVisible(false);
    if (isPagesVisible) setIsPagesVisible(false);
    if (isDevModeVisible) setIsDevModeVisible(false);
    if (isLinksVisible) setIsLinksVisible(false);
    if (isImagesVisible) setIsImagesVisible(false);
    if (isOutlinerVisible) setIsOutlinerVisible(false);
    if (isColorsVisible) setIsColorsVisible(false);
    if (isCommonElementsVisible) setIsCommonElementsVisible(false);
    if (isFormsVisible) setIsFormsVisible(false);
  }

  const handleExportClick = () => {
    if (exportLocally.includes(user?.plan_id as string)) {
      onExportOpen();
    } else {
      onUpgradeOpen();
    }
  };
  // const handleDetectedBarbonesCompletionNoCss = () => {
  //   // Toggle effects on enhance button, make it glow, make it obvious.
  // }

  // useEffect(() => {
  //   if (!autofixer || !autofixerContext) return;

  //   // console.log('');

  //   // Trigger and all,
  //   if (!isEnhanceCoolingDown) {
  //     handleEnhanceCSS({
  //       fixtype: autofixer,
  //       context: autofixerContext
  //     });

  //     setAutofixer(null);
  //     setAutofixerContext(null);

  //     console.log(`
  //       @@@@@@@@@@@@@@@@@@@
  //       WILL USE AUTOFIX SYSTEM MESSAGE
  //       @@@@@@@@@@@@@@@@@@@
  //       `);
  //   }


  // }, [autofixer, autofixerContext])

  useEffect(() => {
    if (isGlowingEnhance === true) {
      setTimeout(() => {
        setIsGlowingEnhance(false);
      }, 8000)
    };
  }, [isGlowingEnhance])
  useEffect(() => {
    if (isGlowingTranslate === true) {
      setTimeout(() => {
        setIsGlowingTranslate(false);
      }, 8000)
    };
  }, [isGlowingTranslate])
  // const resetIsGeneratingSpinner = useResetRecoilState(IsGeneratingSpinner);
  // const resetIsGeneratingLoadingScreen = useResetRecoilState(IsGeneratingLoadingScreen);
  // const resetIsGeneratingImageSpinner = useResetRecoilState(IsGeneratingImageSpinner);

  // const resetIsTranscribingSpinner = useResetRecoilState(IsTranscribingSpinner);
  // const resetIsDeployingSpinner = useResetRecoilState(IsDeployingSpinner);
  // const resetIsUploadingSpinner = useResetRecoilState(IsUploadingSpinner);


  // useEffect(() => {
  //   resetIsGeneratingSpinner();
  //   resetIsGeneratingLoadingScreen();
  //   resetIsGeneratingImageSpinner();
  //   resetIsTranscribingSpinner();
  //   resetIsDeployingSpinner();
  //   resetIsUploadingSpinner();
  // }, [])

  const handleApplyColorTheme = (color: any) => {
    // Get CssCode
    // Parse CssCode
    // Run through algorithm to extract color related properties only.

    return;
  }

  return (
    <AuthenticatedLayout>
      <Flex flex={1} flexDirection={{ base: "row", md: "row" }} gap={2} overflowY={'auto'}>
        <Flex flex={1} flexDirection={'column'} gap={2} w='full'>
          <Flex flex={1} flexDirection={{ base: "column", md: "row" }} gap={2} overflowY={'auto'}>
            {(isPagesVisible || isOutlinerVisible || isColorsVisible || isCommonElementsVisible) && (
              <Flex flexDir='column' gap={2} h='100%' minH='0' flexBasis={{ base: '80%', md: '200px' }} order={{ base: 3, md: 0 }}>
                {(isPagesVisible && !isPreviewMode) && (
                  <SoftCard h='100%' minH='0' flex={1}>
                    <Flex className='zoom' flexDirection='column' gap={4} h='full'>

                      <Flex gap={2} alignItems='end' >
                        <MdOutlineLineWeight />
                        <Heading as='h5' size='sm'>
                          Pages
                        </Heading>
                      </Flex>

                      <Modal size='xs' isOpen={isCreatePageOpen} onClose={onCreatePageClose} initialFocusRef={pageNameInputRef} isCentered>
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>Create page</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <Text fontSize="md" mb={3}>
                              Let's pick a name for your page
                            </Text>
                            <Input
                              ref={pageNameInputRef}
                              placeholder="Enter your page name"
                              size='sm'
                              defaultValue={'Untitled page'}
                              onKeyPress={handleEnterKeyPressCreatePage}
                            />
                          </ModalBody>

                          <ModalFooter>
                            <Button size="sm" colorScheme="purple" rightIcon={<MdChevronRight />} onClick={() => handleCreatePage()}>
                              Confirm
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>

                      {!fromTemplate && (
                        <Tooltip gutter={11} placement='auto' label='New page: Add a page to the project.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                          <IconButton
                            onClick={onCreatePageOpen}
                            size="sm"
                            variant="outline"
                            color="current"
                            aria-label="Add page"
                            icon={<MdAddCircleOutline />}
                            flexShrink={0}
                          />
                        </Tooltip>
                      )}

                      <Flex flexDirection="column" gap={6} overflowX="auto" css={{ "::-webkit-scrollbar": { width: "5px", display: "none" }, "::-webkit-scrollbar-thumb": { backgroundColor: scrollBarColor } }}>
                        {

                          user &&
                          project &&
                          pagesByLocale &&
                          Object.entries(pagesByLocale).map(([locale, pages]) => (
                            <Flex flexDir='column' key={locale} gap={3}>
                              {locale !== 'Default' && (
                                <Badge colorScheme='purple' alignSelf='flex-start' fontSize='0.55em'>{locale}</Badge>
                              )}

                              <Flex flexDirection="column" gap={2} overflowX="auto" css={{ "::-webkit-scrollbar": { width: "5px", display: "none" }, "::-webkit-scrollbar-thumb": { backgroundColor: scrollBarColor } }}>
                                {pages && (pages as any).map((page: any) => (
                                  <Flex key={page.id}
                                    justifyContent={'space-between'} gap={2}>
                                    <Button
                                      colorScheme="purple"
                                      size="xs"
                                      justifyContent="flex-start"
                                      variant={page.id === selectedPage?.id ? "solid" : "ghost"}
                                      flex="1"
                                      leftIcon={<MdOutlineCopyAll />}
                                      onClick={() => handleSwitchPage(page.id)}
                                    >
                                      <Text isTruncated>{page.title}</Text>
                                    </Button>

                                    <Tooltip gutter={11} label='Rename: Change the name of the page.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                                      <IconButton
                                        onClick={() => handleOpenRenamePageModal(page.title, page.id)}
                                        size="xs"
                                        variant={"ghost"}
                                        color="current"
                                        aria-label="Edit Page"
                                        icon={<MdModeEdit />}
                                      />
                                    </Tooltip>
                                  </Flex>
                                ))}
                              </Flex>
                            </Flex>
                          ))


                        }

                        <Modal size='sm' isOpen={isEditPageOpen} onClose={onEditPageClose} isCentered>
                          <ModalOverlay />
                          <ModalContent>
                            <ModalHeader>Rename page</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                              <Text fontSize="md" mb={3}>
                                Let's pick a better name for your page
                              </Text>
                              <Input
                                ref={renamePageInputRef}
                                placeholder="Rename your page"
                                size='sm'
                                defaultValue={pageBeingRenamedTitle}
                                onKeyPress={(e) => handleEnterKeyPressRenamePage(e, pageBeingRenamedId)}
                              />
                            </ModalBody>

                            <ModalFooter>
                              <Flex gap={3}>
                                {/* <Button size="sm" colorScheme="purple" onClick={() => handleTranslatePage(pageBeingRenamedId)}>
                                  Translate
                                </Button> */}
                                <Button size="sm" colorScheme="red" onClick={() => handleDeletePage(pageBeingRenamedId)}>
                                  Delete
                                </Button>
                                <Button size="sm" colorScheme="purple" onClick={() => handleClonePage(pageBeingRenamedId)}>
                                  Clone
                                </Button>
                                <Button size="sm" colorScheme="purple" onClick={() => handleRenamePage(pageBeingRenamedId)}>
                                  Rename
                                </Button>
                              </Flex>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>

                      </Flex>
                    </Flex>
                  </SoftCard>
                )}

                {(isColorsVisible) && (
                  <Colors />
                )}

                {(isCommonElementsVisible) && (
                  <CommonElements />
                )}

                {(isOutlinerVisible) && (
                  <Outliner />
                )}
              </Flex>
            )}

            <Processor setAreIconsDisabled={setAreIconsDisabled} transcription={transcriptionOutput} enhanced={enhancedResponse} handleClickInProcessorWhenMobile={handleClickInProcessorWhenMobile} isEnhanceCoolingDown={isEnhanceCoolingDown} />


            {(isChatVisible && !isPreviewMode) && (
              <Chat />
            )}

            {(isUndoVisible || isLinksVisible || isFormsVisible || isImagesVisible) && (
              <Flex flexDir='column' gap={2} h='100%' minH='0' flexBasis={{ base: '80%', md: '200px' }}>
                {(isUndoVisible && !isPreviewMode) && (
                  <Undo />
                )}

                {(isLinksVisible && !isPreviewMode) && (
                  <Links />
                )}

                {(isFormsVisible && !isPreviewMode) && (
                  <Forms handleEnableForm={handleEnableForm} />
                )}

                {(isImagesVisible && !isPreviewMode) && (
                  <Images />
                )}
              </Flex>
            )}

            {/* { isWideScreen && (isChatVisible && !isPreviewMode) && (
              <Chat />
            )} */}

          </Flex>

          {(isDevModeVisible && !isPreviewMode) && (
            <DevMode />
          )}
        </Flex>

        <Flex flexDirection={{ base: 'column', md: 'column' }} gap={2} justifyContent={{ base: "flex-start", md: "flex-start" }}>

          {/* {!fromTemplate && ( */}
            <Tooltip gutter={11} placement='auto' label='Reset: Start from scratch' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
              <IconButton
                onClick={onStartFromScratch}
                size="sm"
                variant="ghost"
                color="current"
                aria-label="Start Form Scratch"
                icon={<MdOutlineRestore />}
                isDisabled={areIconsDisabled || user?.plan_id === 'FREE_TRIAL'}
              />
            </Tooltip>
          {/* )} */}

          <Transcriber />

          <AlertDialog isOpen={isResetConfirmationOpen} leastDestructiveRef={startFromScratchCancelRef} onClose={onResetConfirmationClose} isCentered>
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Start from scratch?
                </AlertDialogHeader>

                <AlertDialogBody>
                  This will wipe everything in your viewport and start again from the beginning; are you sure you want to continue?
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={startFromScratchCancelRef} size="sm" onClick={onResetConfirmationClose} variant='outline'>
                    Cancel
                  </Button>
                  <Button colorScheme="red" size="sm" onClick={handleConfirmStartFromScratch} ml={3} variant='outline'>
                    Confirm
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          <Tooltip gutter={11} placement='auto' label='Pages: Toggle pages panel.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            {(isPagesVisible && !isPreviewMode) ? (
              <IconButton
                id='show-hide-pages'
                onClick={togglePages}
                size="sm"
                colorScheme='purple'
                aria-label="Pages"
                icon={<MdOutlineLineWeight />}
              />
            ) : (
              <IconButton
                id='show-hide-pages'
                onClick={togglePages}
                size="sm"
                variant="ghost"
                color="current"
                aria-label="Pages"
                icon={<MdOutlineLineWeight />}
              />
            )}
          </Tooltip>

          {/* <Tooltip gutter={11} placement='auto' label='Outliner: Toggle outliner panel.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            {(isOutlinerVisible) ? (
              <IconButton
                id='show-hide-outliner'
                onClick={toggleOutliner}
                size="sm"
                colorScheme='purple'
                aria-label="Outliner"
                icon={<MdAccountTree />}
                isDisabled={areIconsDisabled || user?.plan_id === 'FREE_TRIAL'}
              />
            ) : (
              <IconButton
                id='show-hide-outliner'
                onClick={toggleOutliner}
                size="sm"
                variant="ghost"
                color="current"
                aria-label="Outliner"
                icon={<MdAccountTree />}
                isDisabled={areIconsDisabled || user?.plan_id === 'FREE_TRIAL'}
              />
            )}
          </Tooltip> */}

          {fromTemplate && selectedProjectMode && !unsupportedColors.includes(selectedProjectMode.toLowerCase()) && (
            <Tooltip gutter={11} placement='auto' label='Colors: Toggle colors panel.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
              {(isColorsVisible) ? (
                <IconButton
                  id='show-hide-colors'
                  onClick={toggleColors}
                  size="sm"
                  colorScheme='purple'
                  aria-label="Colors"
                  icon={<MdColorLens />}
                  isDisabled={areIconsDisabled || user?.plan_id === 'FREE_TRIAL'}
                />
              ) : (
                <IconButton
                  id='show-hide-Colors'
                  onClick={toggleColors}
                  size="sm"
                  variant="ghost"
                  color="current"
                  aria-label="Colors"
                  icon={<MdColorLens />}
                  isDisabled={areIconsDisabled || user?.plan_id === 'FREE_TRIAL'}
                />
              )}
            </Tooltip>
          )}

          {/* <Tooltip gutter={11} placement='auto' label='Outliner: Toggle outliner panel.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            {(isCommonElementsVisible) ? (
              <IconButton
                id='show-hide-common-elements'
                onClick={toggleCommonElements}
                size="sm"
                colorScheme='purple'
                aria-label="Common Elements"
                icon={<BsReverseLayoutSidebarInsetReverse />}
                isDisabled={areIconsDisabled || user?.plan_id === 'FREE_TRIAL'}
              />
            ) : (
              <IconButton
                id='show-hide-common-elements'
                onClick={toggleCommonElements}
                size="sm"
                variant="ghost"
                color="current"
                aria-label="Common Elements"
                icon={<BsReverseLayoutSidebarInsetReverse />}
                isDisabled={areIconsDisabled || user?.plan_id === 'FREE_TRIAL'}
              />
            )}
          </Tooltip> */}

          <Tooltip gutter={11} placement='auto' label='Chat: Toggle chat panel.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            {(isChatVisible && !isPreviewMode) ? (
              <IconButton
                id='show-hide-chat'
                onClick={toggleChat}
                size="sm"
                colorScheme='purple'
                aria-label="Chat"
                icon={<TbMessageCode />}
              />
            ) : (
              <IconButton
                id='show-hide-chat'
                onClick={toggleChat}
                size="sm"
                variant="ghost"
                color="current"
                aria-label="Chat"
                icon={<TbMessageCode />}
              />
            )}
          </Tooltip>

          <Tooltip gutter={11} placement='auto' label='History: Toggle undo panel.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            {(isUndoVisible && !isPreviewMode) ? (
              <IconButton
                id='show-hide-undo'
                onClick={toggleUndo}
                size="sm"
                colorScheme='purple'
                aria-label="Undo"
                icon={<MdUndo />}
              />
            ) : (
              <IconButton
                id='show-hide-undo'
                onClick={toggleUndo}
                size="sm"
                variant="ghost"
                color="current"
                aria-label="Undo"
                icon={<MdUndo />}
              />
            )}
          </Tooltip>

          <Tooltip gutter={11} placement='auto' label='Links: Toggle linking and navigation panel.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            {(isLinksVisible && !isPreviewMode) ? (
              <IconButton
                id='show-hide-links'
                onClick={toggleLinks}
                size="sm"
                colorScheme='purple'
                aria-label="Links"
                icon={<IoLinkSharp />}
                isDisabled={areIconsDisabled || user?.plan_id === 'FREE_TRIAL'}
              />
            ) : (
              <IconButton
                id='show-hide-links'
                onClick={toggleLinks}
                size="sm"
                variant="ghost"
                color="current"
                aria-label="Links"
                icon={<IoLinkSharp />}
                isDisabled={areIconsDisabled || user?.plan_id === 'FREE_TRIAL'}
              />
            )}
          </Tooltip>

          <Tooltip gutter={11} placement='auto' label='Forms: Toggle forms panel.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            {(isFormsVisible) ? (
              <IconButton
                id='show-hide-forms'
                onClick={toggleForms}
                size="sm"
                colorScheme='purple'
                aria-label="Forms"
                icon={<MdFormatListBulletedAdd />}
                isDisabled={areIconsDisabled || user?.plan_id === 'FREE_TRIAL'}
              />
            ) : (
              <IconButton
                id='show-hide-forms'
                onClick={toggleForms}
                size="sm"
                variant="ghost"
                color="current"
                aria-label="Forms"
                icon={<MdFormatListBulletedAdd />}
                isDisabled={areIconsDisabled || user?.plan_id === 'FREE_TRIAL'}
              />
            )}
          </Tooltip>

          <Tooltip gutter={11} placement='auto' label='Images: Toggle images panel.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            {(isImagesVisible && !isPreviewMode) ? (
              <IconButton
                id='show-hide-images'
                onClick={toggleImages}
                size="sm"
                colorScheme='purple'
                aria-label="Images"
                icon={<FaRegImage />}
                isDisabled={areIconsDisabled || user?.plan_id === 'FREE_TRIAL'}
              />
            ) : (
              <IconButton
                id='show-hide-links'
                onClick={toggleImages}
                size="sm"
                variant="ghost"
                color="current"
                aria-label="Images"
                icon={<FaRegImage />}
                isDisabled={areIconsDisabled || user?.plan_id === 'FREE_TRIAL'}
              />
            )}
          </Tooltip>

          {isAvailableInPlanDeveloperMode.includes(user?.plan_id as string) && (
            <Tooltip gutter={11} placement='auto' label='Code: Toggle developer mode panel.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
              {(isDevModeVisible && !isPreviewMode) ? (
                <IconButton
                  id='show-hide-devmode'
                  onClick={toggleDevMode}
                  size="sm"
                  colorScheme='purple'
                  aria-label="Developer Mode"
                  icon={<MdOutlineCode />}
                  isDisabled={!isWideScreen}
                />
              ) : (
                <IconButton
                  id='show-hide-devmode'
                  onClick={toggleDevMode}
                  size="sm"
                  variant="ghost"
                  color="current"
                  aria-label="Developer Mode"
                  icon={<MdOutlineCode />}
                  isDisabled={!isWideScreen}
                />
              )}
            </Tooltip>
          )}

          {!fromTemplate && (
            <Tooltip gutter={11} placement='auto' label='Enhance: Automatically boost the styling of a section.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
              <IconButton

                sx={{
                  animation: isGlowingEnhance ? `${glowKeyframes(purple)} 1.5s infinite alternate` : 'none',
                }}

                onClick={handleEnhanceCSS}
                // color="current"
                size="sm"
                // variant="solid"
                variant={!isEnhanceCoolingDown ? "ghost" : "outline"}
                // variant="ghost"
                color="current"
                aria-label="Enhance"
                icon={<IoSparkles />}
                isDisabled={isEnhanceCoolingDown || areIconsDisabled}
              // isDisabled={!localContextId}
              />
            </Tooltip>
          )}


          <Tooltip gutter={11} placement='auto' label='Translate: Languages & localization' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              sx={{
                animation: isGlowingTranslate ? `${glowKeyframes(purple)} 1.5s infinite alternate` : 'none',
              }}
              onClick={onTranslateOpen}
              size="sm"
              variant="ghost"
              color="current"
              aria-label="Translate"
              icon={<MdGTranslate />}
            />
          </Tooltip>

          <Modal isOpen={isTranslateOpen} onClose={onTranslateClose} size={{ base: "full", md: "md" }} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Translate</ModalHeader>
              <ModalCloseButton />
              <ModalBody>

                <Flex py={4} px={2} flexDir='column' gap={4}>

                  <FormLabel>Translate to</FormLabel>
                  <Select mt={1} size="sm" onChange={handleTranslationTargetLanguageOnChange} value={translationTargetLanguage}>
                    {
                      languages && languages.map((language: any, index: number) => (
                        <option key={index} value={language.name}>{language.name}</option>
                      ))
                    }
                  </Select>

                  <FormLabel>Scope</FormLabel>
                  <Flex gap={2}>
                    <Button isDisabled={!translationTargetLanguage} w='full' onClick={() => handleTranslatePage(translationTargetLanguage)} variant='outline' colorScheme='purple'>Current page</Button>
                    <Button isDisabled={!translationTargetLanguage} w='full' onClick={() => handleTranslateProject(translationTargetLanguage)} variant='outline' colorScheme='purple'>Full project</Button>
                  </Flex>

                </Flex>



              </ModalBody>


            </ModalContent>
          </Modal>

          <Tooltip gutter={11} placement='auto' label='Publish: Share the project online.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              onClick={handleDeploy}
              size="sm"
              variant="ghost"
              color="current"
              aria-label="Deploy"
              // icon={<MdOutlinePublishedWithChanges />}
              icon={<BsRocketTakeoff />}
              // icon={<MdRocketLaunch />}
              isDisabled={project?.is_published || isDeployingSpinner}
            />
          </Tooltip>
          <Tooltip gutter={11} placement='auto' label='Update: Overwrite current website.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              onClick={onUpdateOpen}
              size="sm"
              variant="ghost"
              color="current"
              aria-label="Update"
              icon={<MdCloudUpload />}
              isDisabled={!project?.is_published}
            />
          </Tooltip>

          <Modal isOpen={isUpdateOpen} onClose={onUpdateClose} size={{ base: "full", md: "md" }} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Update Website</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text mb={4}>
                  Are you sure you want to overwrite your current published website? This action cannot be undone.
                </Text>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="gray" mr={3} onClick={onUpdateClose} variant='outline'>
                  Cancel
                </Button>
                <Button colorScheme="purple" onClick={() => {
                  handleUpdate();
                  onUpdateClose();
                }} variant='outline'>
                  Confirm Update
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal isOpen={isProjectDeployedOpen} onClose={onProjectDeployedClose} size={{ base: "full", md: "md" }} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Published!</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text mb={3} fontSize="md">
                  Your project is now published! It will be live and accessible within 2 to 5 minutes. You can visit it through:
                </Text>

                {/* <Link color={useColorModeValue('purple.500', 'purple.200')} href={(deployedProject as any)?.url} isExternal>
                  {(deployedProject as any)?.url} <Icon as={MdOutlineLink} />
                </Link> */}

                <Link color={useColorModeValue('purple.500', 'purple.200')} href={(deployedProject as any)?.url} isExternal>
                  {(deployedProject as any)?.url} <ExternalLinkIcon mx='2px' />
                </Link>

                <Button mt={4} colorScheme='purple' size='xs' rightIcon={<MdContentCopy />} onClick={() => navigator.clipboard.writeText((deployedProject as any)?.url)}>Copy to clipboard</Button>


                {/* <IconButton
                  onClick={() => navigator.clipboard.writeText((deployedProject as any)?.url)}
                  size="xs"
                  variant="ghost"
                  colorScheme="purple"
                  aria-label="Copy to clipboard"
                  icon={<MdContentCopy />}
                /> */}

              </ModalBody>

              <ModalFooter>
                {/* <Button size="sm" colorScheme="purple" rightIcon={<MdChevronRight />} onClick={onProjectDeployedClose}>
                  Proceed
                </Button> */}
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal isOpen={isUpdateModalOpen} onClose={onUpdateModalClose} size={{ base: "full", md: "md" }} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Update Successful!</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text mb={3} fontSize="md">
                  Your project has been successfully updated. The changes will be live within 2 to 5 minutes. You can visit your updated site at:
                </Text>
                <Link color={useColorModeValue('purple.500', 'purple.200')} href={updatedSiteUrl} isExternal>
                  {updatedSiteUrl} <ExternalLinkIcon mx='2px' />
                </Link>
                <Button mt={4} colorScheme='purple' size='xs' rightIcon={<MdContentCopy />} onClick={() => navigator.clipboard.writeText(updatedSiteUrl)}>Copy to clipboard</Button>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="purple" onClick={onUpdateModalClose}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Tooltip gutter={11} placement='auto' label='Export: Save on device.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              onClick={handleExportClick}
              size="sm"
              variant="ghost"
              color="current"
              aria-label="export"
              icon={<FaFileExport />}
            />
          </Tooltip>

          <Modal size={{ base: "full", md: 'md' }} isOpen={isExportOpen} onClose={onExportClose} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Export</ModalHeader>
              <ModalCloseButton />
              <ModalBody>

                <Flex py={4} px={2} flexDir='column' gap={4}>

                  <FormLabel>Type</FormLabel>
                  <RadioGroup onChange={(e) => handleRadioChange(e, 'project-export-type')} value={projectExportType} size='sm' colorScheme='purple'>
                    <Stack direction='column'>
                      <Radio value='single'>Single HTML file</Radio>
                      <Radio value='separate'>Separate HTML, CSS, and JavaScript files</Radio>
                    </Stack>
                  </RadioGroup>

                  <FormLabel>Content</FormLabel>
                  <Flex gap={2}>
                    <Button isDisabled={!exportLocally.includes(user?.plan_id as string)} w='full' onClick={() => handleExportPage()} variant='outline' colorScheme='purple'>Current page</Button>
                    <Button isDisabled={!exportLocally.includes(user?.plan_id as string)} w='full' onClick={() => handleExportProject()} variant='outline' colorScheme='purple'>Full project</Button>
                  </Flex>

                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>

          {/* <Tooltip gutter={11} placement='auto' label='Export page: Save the current page on the device.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              onClick={() => exportCurrentPage()}
              size="sm"
              variant="ghost"
              color="current"
              aria-label="export-current-page"
              title="Export current page"
              icon={<MdSaveAlt />}
            />
          </Tooltip>

          <Tooltip gutter={11} placement='auto' label='Export project: Save the entire project on the device.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              onClick={() => handleExportProject()}
              size="sm"
              variant="ghost"
              color="current"
              aria-label="export-all-pages"
              title="Export all pages"
              icon={<MdOutlineFolderZip />}
            />
          </Tooltip> */}

          {/* <CompletionLoadingScreen /> */}

          <AlertDialog
            isOpen={isUpgradeOpen}
            leastDestructiveRef={cancelRef}
            onClose={onUpgradeClose}
            isCentered
            size="lg"
          >
            <AlertDialogOverlay>
              <AlertDialogContent
                boxShadow="lg"
                p={5}
                borderRadius="md"
                bg={useColorModeValue('white', 'gray.800')}
              >
                <AlertDialogHeader
                  fontSize="2xl"
                  fontWeight="bold"
                  display="flex"
                  alignItems="center"
                  color={useColorModeValue('gray.800', 'white')}
                >
                  <Tooltip
                    label="Upgrade to unlock all features"
                    aria-label="Upgrade tooltip"
                    bg={useColorModeValue('gray.700', 'gray.200')}
                    color={useColorModeValue('white', 'gray.800')}
                  >
                    <Icon as={InfoOutlineIcon} color="purple.500" mr={3} />
                  </Tooltip>
                  Upgrade
                </AlertDialogHeader>

                <AlertDialogBody
                  fontSize="lg"
                  mt={4}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  color={useColorModeValue('gray.700', 'gray.200')}
                >
                  <MotionBox
                    animate={{ rotate: [0, 360] }}
                    transition={{ duration: 2, ease: "easeInOut", repeat: Infinity }}
                    mb={6}
                  >
                    <Icon
                      as={InfoOutlineIcon}
                      w={16}
                      h={16}
                      color={useColorModeValue('purple.500', 'purple.400')}
                    />
                  </MotionBox>
                  <Text textAlign="center">
                    <Text
                      as="span"
                      fontWeight="bold"
                      color={useColorModeValue('gray.800', 'white')}
                    >
                      This action is not available in your current plan.
                    </Text>{" "}
                    Upgrade now to unlock it and get the most out of SIFO's full suite of features.
                  </Text>
                </AlertDialogBody>

                <AlertDialogFooter justifyContent="center" mt={6}>
                  <Button
                    size="md"
                    variant="outline"
                    ref={cancelRef}
                    onClick={onUpgradeClose}
                    mr={3}
                    color={useColorModeValue('gray.800', 'white')}
                    borderColor={useColorModeValue('gray.200', 'gray.600')}
                    _hover={{
                      bg: useColorModeValue('gray.100', 'gray.700')
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="md"
                    colorScheme="purple"
                    onClick={() => navigate('/upgrade')}
                    variant='outline'
                    _hover={{
                      boxShadow: useColorModeValue(
                        '0 0 10px rgba(128, 90, 213, 0.5)',
                        '0 0 10px rgba(128, 90, 213, 0.8)'
                      )
                    }}
                  >
                    Unlock Now
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </Flex>
      </Flex>
    </AuthenticatedLayout>
  );
};
import { atom, DefaultValue, selector } from 'recoil';
import { ApiClient } from '../lib/clients/api-client';
import { Project } from '../types';
import { currentUserState } from './current-user';

import { projectBootstrapTemplatesAsLowerCaseStrings } from '../lib/project-modes/bootstrap-templates';

const defaultProjectsSelector = selector<Project[]>({
  key: 'project-list-selector',
  get: async ({ get }) => {
    try {
      const currentUser = get(currentUserState);
      if (currentUser) {
        return ApiClient.getProjects();
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  },
});

export const projectsState = atom<Project[]>({
  key: 'projects',
  default: defaultProjectsSelector,
});

export const selectedProjectState = atom<string | null>({
  key: 'select-project',
  default: localStorage.getItem('selected_project_id'),
});

export const selectedProjectSelector = selector<Project | null>({
  key: 'selected-project-selector',
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) return;

    if (newValue) {
      localStorage.setItem('selected_project_id', newValue.id);
    } else {
      localStorage.removeItem('selected_project_id');
    }

    set(selectedProjectState, newValue ? newValue.id : null);
  },
  get: ({ get }) => {
    const projects = get(projectsState);
    const selectedId = get(selectedProjectState);

    if (!selectedId) {
      return null;
    }
    return projects.find((project) => project.id === selectedId) || null;
  },
});

export const IsPreviewMode = atom({
  key: 'is-preview-mode',
  default: false
})

export const SelectedProjectMode = atom<any>({
  key: 'selected-project-mode',
  default: null
})

export const ProjectCommonElements = atom<any>({
  key: 'project-common-elements',
  default: []
})

export const ProjectColorMap = atom<any>({
  key: 'project-color-map',
  default: null
})

export const ProjectColorPalette = selector<string[]>({
  key: 'project-color-palette',
  get: ({ get }) => {
    const projectColorMap = get(ProjectColorMap);

    if (!projectColorMap) return;

    return projectColorMap
      .split('\n')
      .filter((line: any) => line.trim() !== '')
      .map((line: any) => {
        const leftColor = line.split(':')[0].trim();
        return leftColor;
      });
  }
});

export const ProjectCommonElementsParsed = selector({
  key: 'project-common-elements-parsed',
  get: ({ get }) => {
    const projectCommonElements: any = get(ProjectCommonElements);

    if (projectCommonElements && projectCommonElements.length > 0) {
      const parser = new DOMParser();
      return projectCommonElements.map((element: any) => parser.parseFromString(element, 'text/html').body.firstElementChild)
    }

    return null;
  }
});


// export const ProjectCommonElementsIDs = selector({
//   key: 'project-common-elements-ids',
//   get: ({ get }) => {
//     const projectCommonElementsParsed: any = get(ProjectCommonElementsParsed);

//     if (projectCommonElementsParsed && projectCommonElementsParsed.length > 0) {
//       return projectCommonElementsParsed.map((element_parsed: any) => element_parsed.id || null)
//     }

//     return null;
//   }
// });

export const ProjectCommonElementsIDs = selector({
  key: 'project-common-elements-ids',
  get: ({ get }) => {
    const projectCommonElementsParsed: any = get(ProjectCommonElementsParsed);

    if (projectCommonElementsParsed && projectCommonElementsParsed.length > 0) {
      return projectCommonElementsParsed.map((element_parsed: any) => 
        element_parsed && element_parsed.id ? element_parsed.id : null
      );
    }

    return null;
  }
});

export const IsProjectFromTemplate = selector({
  key: 'is-project-from-template',
  get: ({ get }) => {
    const selectedProjectMode = get(SelectedProjectMode);

    // nullcheck fix for selectedprojmode.toLowerCase() crash 
    if (!selectedProjectMode) {
      return false; 
    }

    return projectBootstrapTemplatesAsLowerCaseStrings.includes(
      selectedProjectMode.toLowerCase()
    );
  },
});



// export const SelectedTemplateId = atom({
//   key: 'selected-template-id',
//   default: null
// })

export const DefaultProjectSourceFile = atom({
  key: 'default-project-source-file',
  default: ''
})
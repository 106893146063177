// deno-lint-ignore-file no-explicit-any

export function isAddEventListener(node: any) {
    return (
        node.expression &&
        node.expression.callee &&
        node.expression.callee.property &&
        node.expression.callee.property.name === 'addEventListener'
    );
}

export function isFunctionCall(node: any) {
    return (
        node.expression &&
        node.expression.callee &&
        node.expression.callee.name && 
        node.expression.type === 'CallExpression'
    );
}
export function isObjectMethod(code: string): boolean {
    // Check if code starts with a method name followed by function or arrow syntax
    return /^\s*[a-zA-Z_$][a-zA-Z0-9_$]*\s*:/.test(code);
  }
  
  export function wrapAsObjectLiteral(code: string): string {
    return `({${code}})`;
  }

  export function isObjectMethodUpdate(existingCode: string, incomingCode: string): boolean {
    // Check if existing code contains an object definition
    const hasObject = existingCode.includes('const') || existingCode.includes('let') || existingCode.includes('var');
    // Check if incoming code is a method definition
    const isMethod = /^\s*[a-zA-Z_$][a-zA-Z0-9_$]*\s*:\s*function/.test(incomingCode);
    return hasObject && isMethod;
  }
  
  export function getMethodName(code: string): string {
    const match = code.match(/^\s*([a-zA-Z_$][a-zA-Z0-9_$]*)\s*:/);
    return match ? match[1] : '';
  }
export const projectTemplates = [
  {
    name: 'Classic Landing Page',
    thumbnail: 'BsGrid1X2Fill',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Personal Site',
    thumbnail: 'BsCupHotFill',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Local Business',
    thumbnail: 'BsHouseFill',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Business (B2B)',
    thumbnail: 'BsAwardFill',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Blogs',
    thumbnail: 'SiBloglovin',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Portfolio',
    thumbnail: 'BsAspectRatioFill',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'News & Magazine',
    thumbnail: 'BsBellFill',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Forums & Community Boards',
    thumbnail: 'BsBox2HeartFill',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Social Network',
    thumbnail: 'BsChatDotsFill',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Web Portals',
    thumbnail: 'BsCircleFill',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Ecommerce',
    thumbnail: 'BsBagCheckFill',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Online Learning Platform',
    thumbnail: 'BsCompassFill',
    available_in: ['ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Entertainment & Streaming',
    thumbnail: 'BsDiscFill',
    available_in: ['ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Subscription Site',
    thumbnail: 'BsCreditCard2BackFill',
    available_in: ['ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Booking & Reservation',
    thumbnail: 'MdLocalHotel',
    available_in: ['ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Restaurant & Delivery',
    thumbnail: 'IoFastFood',
    available_in: ['ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Event & Ticketing Platforms',
    thumbnail: 'BsMegaphoneFill',
    available_in: ['ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Nonprofit & Charities',
    thumbnail: 'BsHospitalFill',
    available_in: ['ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Wiki & Knowledge Bases',
    thumbnail: 'BsLightbulbFill',
    available_in: ['ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Health & Fitness',
    thumbnail: 'BsHeartPulseFill',
    available_in: ['ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Dating Site',
    thumbnail: 'BsArrowThroughHeartFill',
    available_in: ['ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Affiliate Marketing',
    thumbnail: 'BsStars',
    available_in: ['ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  }
];

export const projectTemplatesAsLowerCaseStrings = projectTemplates.map(template => template.name.toLowerCase());
export const projectLibraries = [
  {
    name: 'None',
    tooltip: 'Simple, and barebones web technologies.',
    thumbnail: null,
    showcase: null,
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Tailwind',
    tooltip: 'Tailwind: A popular UI library.',
    thumbnail: ['modes/thumbnails/tailwind-css-light.svg', 'modes/thumbnails/tailwind-css-dark.svg'],
    showcase: 'modes/showcase/tailwind.png',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Bulma',
    tooltip: 'Bulma: A popular UI library that features a vibrant components.',
    thumbnail: ['modes/thumbnails/bulma-light.svg', 'modes/thumbnails/bulma-dark.svg'],
    showcase: 'modes/showcase/bulma.png',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Materialize',
    tooltip: 'Materialize: Inspired by Google\'s material-design philosophy.',
    thumbnail: ['modes/thumbnails/materialize-light.svg', 'modes/thumbnails/materialize-dark.svg'],
    showcase: 'modes/showcase/materialize.png',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Bootstrap',
    tooltip: 'Bootstrap: You already used Bootstrap without knowing it, it\'s everywhere.',
    thumbnail: ['modes/thumbnails/bootstrap-logo.svg', 'modes/thumbnails/bootstrap-logo.svg'],
    showcase: 'modes/showcase/bootstrap.png',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'DaisyUI',
    tooltip: 'DaisyUI: A tailwind based library.',
    thumbnail: ['modes/thumbnails/daisyui.png', 'modes/thumbnails/daisyui.png'],
    showcase: 'modes/showcase/daisyui.png',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'UIKit',
    tooltip: 'UIKit: A Lightweight, modular UI framework.',
    thumbnail: ['modes/thumbnails/uikit.png', 'modes/thumbnails/uikit.png'],
    showcase: 'modes/showcase/uikit.png',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'W3',
    tooltip: 'W3: A fast, minimalistic library.',
    thumbnail: ['modes/thumbnails/w3.png', 'modes/thumbnails/w3.png'],
    showcase: 'modes/showcase/w3.png',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Semantic',
    tooltip: 'Semantic: Another beautiful, and popular UI library.',
    thumbnail: ['modes/thumbnails/semantic.png', 'modes/thumbnails/semantic.png'],
    showcase: 'modes/showcase/semantic.png',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Pico',
    tooltip: 'Pico: A minimalist and lightweight starter kit that prioritizes semantic syntax, making every element responsive and elegant by default.',
    thumbnail: ['modes/thumbnails/pico-dark.png', 'modes/thumbnails/pico-light.png'],
    showcase: 'modes/showcase/pico.png',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-3"
  },
  {
    name: 'Cirrus',
    tooltip: 'Cirrus: A fully responsive, sinplistic, and beautiful framework.',
    thumbnail: ['modes/thumbnails/cirrus.png', 'modes/thumbnails/cirrus.png'],
    showcase: 'modes/showcase/cirrus.png',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL', ],
    default_model: "model-2"
  },
  {
    name: 'Spectre',
    tooltip: 'Spectre: A lightweight, responsive, and modern styling framework.',
    thumbnail: ['modes/thumbnails/spectre.png', 'modes/thumbnails/spectre.png'],
    showcase: 'modes/showcase/spectre.png',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL', ],
    default_model: "model-2"
  },
  {
    name: 'Milligram',
    tooltip: 'Milligram: A minimalism focused styling framework.',
    thumbnail: ['modes/thumbnails/milligram.png', 'modes/thumbnails/milligram.png'],
    showcase: 'modes/showcase/milligram.png',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-2"
  },
  {
    name: 'NES',
    tooltip: 'NES: Retro, 8-bit-like style framework.',
    thumbnail: ['modes/thumbnails/nes.png', 'modes/thumbnails/nes.png'],
    showcase: 'modes/showcase/nes.png',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-2"
  },
  {
    name: 'Paper',
    tooltip: 'Paper: The fun, less formal framework.',
    thumbnail: ['modes/thumbnails/paper.png', 'modes/thumbnails/paper.png'],
    showcase: 'modes/showcase/paper.png',
    available_in: ['ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-2"
  },
  {
    name: 'Tachyons',
    tooltip: 'Tachyons: Fast loading, totally responsive interfaces.',
    thumbnail: ['modes/thumbnails/tachyons-dark.png', 'modes/thumbnails/tachyons-light.png'],
    showcase: 'modes/showcase/tachyons.png',
    available_in: ['ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-2"
  },
  {
    name: 'Halfmoon',
    tooltip: 'Halfmoon: A highly customizalbe, bootstrap replacement.',
    thumbnail: ['modes/thumbnails/halfmoon.png', 'modes/thumbnails/halfmoon.png'],
    showcase: 'modes/showcase/halfmoon.png',
    available_in: ['ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-2"
  },
  {
    name: 'Mini',
    tooltip: 'Mini: Very minimal, and style-agnostic framework.',
    thumbnail: ['modes/thumbnails/mini.png', 'modes/thumbnails/mini.png'],
    showcase: 'modes/showcase/mini.png',
    available_in: ['ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-2"
  },
  {
    name: 'Chota',
    tooltip: 'Chota: A micro framework.',
    thumbnail: ['modes/thumbnails/chota.png', 'modes/thumbnails/chota.png'],
    showcase: 'modes/showcase/chota.png',
    available_in: ['ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-2"
  },
  {
    name: 'Siimple',
    tooltip: 'Siimple: Minimal and themeable.',
    thumbnail: ['modes/thumbnails/siimple-dark.png', 'modes/thumbnails/siimple-light.png'],
    showcase: 'modes/showcase/siimple.png',
    available_in: ['ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-2"
  },
  {
    name: 'Primer',
    tooltip: 'Primer: A design system, by Github.',
    thumbnail: ['modes/thumbnails/primer.png', 'modes/thumbnails/primer.png'],
    showcase: 'modes/showcase/primer.png',
    available_in: ['ELITE_MONTHLY', 'ELITE_ANNUAL'],
    default_model: "model-2"
  },
];
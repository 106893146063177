import { atom, selector } from 'recoil';
import { ApiClient } from '../lib/clients/api-client';
import { currentUserState } from './current-user';

export const DisplayVisualEditor = atom({
    key: 'display-visual-editor',
    default: false,
});

export const DisplaySectionLabels = atom({
    key: 'display-section-labels',
    default: false,
});

const defaultUserPreferences: any = {
    display_tips_modal_on_projects_list: true,
    display_tooltips: true,
    ai_model: 'model-4',
    image_model: 'image-1',
    ai_actions: false,
    create_pages_automatically_on_linking: false,
    auto_images: false,
    // Legacy
    context_size: 4,
}

const DefaultUserPreferences = selector({
    key: 'default-user-preferences',
    get: async ({ get }) => {
        try {
            const currentUser = get(currentUserState);
            if (currentUser) {
                let prefs;

                prefs = await ApiClient.fetchUserPreferences(currentUser.id);

                if (prefs === undefined) {
                    await ApiClient.initUserPreferences(currentUser.id, {
                        display_tips_modal_on_projects_list: true,
                        display_tooltips: true,
                        ai_model: 'model-4',
                        image_model: 'image-1',
                        ai_actions: false,
                        create_pages_automatically_on_linking: false,
                        auto_images: false,
                    })
                }

                return prefs || defaultUserPreferences;
            } else {
                return defaultUserPreferences;
            }
        } catch (err) {
            return defaultUserPreferences;
        }
    },
});

export const UserPreferences = atom({
    key: 'user-preferences',
    default: DefaultUserPreferences,
});

// import { atom } from 'recoil';

// export const DisplayVisualEditor = atom({
//     key: 'display-visual-editor',
//     default: true,
// });

// export const UserPreferences = atom({
//     key: 'user-preferences',
//     default: {
//         display_tips_modal_on_projects_list: true,
//     },
// });
import React, { useRef, useEffect, useState } from 'react';
import { Box, Button, Flex, IconButton, Tooltip, useColorModeValue, useBreakpointValue } from '@chakra-ui/react';
import { useProjectActions, usePageActions, useOpenaiActions, useSIFOActions } from '../../hooks';
import { IsProjectFromTemplate, selectedProjectSelector, WillRepairJavascript, Autofixer, AutofixerContext, OutlinerDeleteElementId, OutlinerTree, IsGeneratingSpinner, PageForms, PageImages, PageLinks, WipeIframeContent, IsUpdated, DisplaySectionLabels, DisplayVisualEditor, UserPreferences, HTMLCode, CSSCode, JavaScriptCode, selectedPageSelector, ViewportSourceDocument, ContentUndoHistory, ConversationHistoryUndoHistory, UndoHistoryStepIndex, DidIUndo, LocalContext, LocalContextCSS, ViewportTree, PageLinksHaveBeenUpdated, PageImagesHaveBeenUpdated, SelectedOutlinerNode, SelectedProjectMode, PageFormsHaveBeenUpdated, currentUserState, GlobalContextOuterHTML, ProjectCommonElements, ProjectCommonElementsParsed, ProjectCommonElementsIDs, pagesState, ApplyThemeColor } from '../../state';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { cssjs } from 'jotform-css.js';
import { insertStyles, getIDsRecursively, getClassesRecursively, getTagsRecursively, extractCSSRulesBySelectors, extractCSSRulesBySelectorsNoKeyfranes, deleteCSSRulesBySelectors, writeCSSFromRules, fixCssSelectors, fixCssSelectorsInJs } from './css-tools';
import { compareTailwindClasses } from './compare-tw-classes';
import { hasBackgroundImage, constructPageLinksArray, constructPageFormsArray, constructPageImagesArray } from './links-tools';
import { contentEditableElements } from './content-editable-elements';
import { CssToTailwindTranslator } from "css-to-tailwind-translator";
import { useToast } from '@chakra-ui/react';
import { debounce } from 'lodash';
import { domParser } from './dom-parser';

// import { extractPageColors, generateColorMapping, replacePageColors } from './color-palette/color-utils';
// import { ColorOverrideSystem, initializeColorOverrideSystem, applyColorOverridesToDocument } from './color-palette/color-persist';
// import { ColorOverride } from './color-palette/color-override';

// import { extractColorPalette, replaceColors, overwriteColors } from './color-palette/stylesheets-utils';
// import { generateColorMapping } from './color-palette/color-utils';
// import { insertJavascript } from './js-tools';
import LogicEngine from './logic-engine/engine';
import DiffEngine from './diff-engine/differ';

// Working
// import { extractColorPalette, overwriteColors } from './color-palette/stylesheets-utils';
// import { createThemeColorMapping } from './color-palette/mapping';

import { FaDesktop } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { SlMagnifierAdd } from "react-icons/sl";
import { SlMagnifierRemove } from "react-icons/sl";
import { SlMagnifier } from "react-icons/sl";
import { GiConfirmed } from "react-icons/gi";
import { IoText } from "react-icons/io5";
import { IoMdMove } from "react-icons/io";
import { GiResize } from "react-icons/gi";
import { MdGTranslate } from "react-icons/md";

import { projectTemplatesAsLowerCaseStrings } from '../../lib/project-modes/templates';

// import { processTailwindClass, insertStyles, getIDsRecursively, extractCSSRulesBySelectors, extractCSSRulesBySelectorsNoKeyfranes, deleteCSSRulesBySelectors, writeCSSFromRules } from './css-tools';
// import dragula from 'dragula';


// DisplayVisualEditor,

export const Renderer: React.FC<any> = ({ objects, tryToFixErrorWithAi, handleClickInProcessorWhenMobile, isEnhanceCoolingDown }) => {



  const setAutofixer = useSetRecoilState<any>(Autofixer);
  const setAutofixerContext = useSetRecoilState<any>(AutofixerContext);


  const toast = useToast();

  const setOutlinerTree = useSetRecoilState<any>(OutlinerTree);

  const [hasBeenManuallyEdited, setHasBeenManuallyEdited] = useState(false);

  const setIsGeneratingSpinner = useSetRecoilState(IsGeneratingSpinner);

  const [applyThemeColor, setApplyThemeColor] = useRecoilState(ApplyThemeColor);

  const [wipeIframeContent, setWipeIframeContent] = useRecoilState<any>(WipeIframeContent);
  const [outlinerDeleteElementId, setOutlinerDeleteElementId] = useRecoilState<any>(OutlinerDeleteElementId);

  const [pages, setPages] = useRecoilState(pagesState);

  const [projectCommonElements, setProjectCommonElements] = useRecoilState(ProjectCommonElements);
  const projectCommonElementsParsed = useRecoilValue(ProjectCommonElementsParsed);
  const projectCommonElementsIDs = useRecoilValue(ProjectCommonElementsIDs);

  const userPreferences = useRecoilValue(UserPreferences);
  const selectedProjectMode = useRecoilValue(SelectedProjectMode);
  const [mobileView, setMobileView] = useState(false);

  const iframeReference = useRef<HTMLIFrameElement | null>(null);
  const editorColor = useColorModeValue('#805AD5', '#D6BCFA');
  const editorDeleteButtonColor = useColorModeValue('#e53e3e', '#feb2b2');

  const { updateProjectPages, updateProjectCommonElements } = useProjectActions();
  const { updatePageContent } = usePageActions();

  const debouncedUpdatePage = debounce((viewport, pageId) => {
    // Get viewport content string
    const viewportContent = viewport.documentElement.outerHTML;

    // Create undo step
    setContentUndoHistory((prev) => {
      let modifiedPrev = prev;
      if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

      return [
        ...modifiedPrev,
        {
          timestamp: new Date().toLocaleString(),
          label: 'Edit manually',
          content: viewportContent
        }
      ]
    });

    // Increment undo step pointer/index
    setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

    // Update developer mode content
    const htmlContent = viewport.documentElement.querySelector('div#body')?.innerHTML;
    const cssContent = viewport.documentElement.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
    const javascriptContent = viewport.documentElement.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;

    if (htmlContent) setHTMLCode(htmlContent);
    if (cssContent) setCSSCode(cssContent);
    if (javascriptContent) setJavaScriptCode(javascriptContent);

    // Update page content in the database
    updatePageContent(pageId, viewportContent);
  }, 3500);

  const { buildLocalizationMap, buildContextTree, buildOutlinerTree } = useSIFOActions();
  // const { getTranslation } = useOpenaiActions();

  const user = useRecoilValue(currentUserState);
  const selected_project = useRecoilValue(selectedProjectSelector);
  const selected_page = useRecoilValue(selectedPageSelector);
  // const viewportSourceDocument = useRecoilValue(ViewportSourceDocument);
  const [viewportSourceDocument, setViewportSourceDocument] = useRecoilState<any>(ViewportSourceDocument);

  const [willRepairJavascript, setWillRepairJavascript] = useRecoilState(WillRepairJavascript);

  const [isUpdated, setIsUpdated] = useRecoilState(IsUpdated);
  const [pageLinksHaveBeenUpdated, setPageLinksHaveBeenUpdated] = useRecoilState(PageLinksHaveBeenUpdated);
  const [pageFormsHaveBeenUpdated, setPageFormsHaveBeenUpdated] = useRecoilState(PageFormsHaveBeenUpdated);
  const [pageImagesHaveBeenUpdated, setPageImagesHaveBeenUpdated] = useRecoilState(PageImagesHaveBeenUpdated);
  const [pageLinks, setPageLinks] = useRecoilState(PageLinks);
  const [pageForms, setPageForms] = useRecoilState(PageForms);
  const [pageImages, setPageImages] = useRecoilState(PageImages);

  const [didIUndo, setDidIUndo] = useRecoilState(DidIUndo);
  const [contentUndoHistory, setContentUndoHistory] = useRecoilState(ContentUndoHistory);
  // const setContentUndoHistory = useSetRecoilState(ContentUndoHistory);
  const setConversationHistoryUndoHistory = useSetRecoilState(ConversationHistoryUndoHistory);
  const [undoHistoryStepIndex, setUndoHistoryStepIndex] = useRecoilState(UndoHistoryStepIndex);

  const resetLocalContext = useResetRecoilState(LocalContext);
  const setLocalContext = useSetRecoilState(LocalContext);
  const setLocalContextCSS = useSetRecoilState(LocalContextCSS);

  const { updateLinks, updateForms, updateImages } = usePageActions();

  const isWideScreen = useBreakpointValue({ base: false, md: true });

  const isInitialMount = useRef(true);

  const fromTemplate = useRecoilValue(IsProjectFromTemplate);

  const [htmlCode, setHTMLCode] = useRecoilState(HTMLCode);
  const [cssCode, setCSSCode] = useRecoilState(CSSCode);
  const [javascriptCode, setJavaScriptCode] = useRecoilState(JavaScriptCode);

  const setViewportTree = useSetRecoilState(ViewportTree);
  const setGlobalContextOuterHTML = useSetRecoilState(GlobalContextOuterHTML)

  const [displayVisualEditor, setDisplayVisualEditor] = useRecoilState(DisplayVisualEditor);
  const [displaySectionLabels, setDisplaySectionLabels] = useRecoilState(DisplaySectionLabels);

  const [isDirectEditEnabled, setIsDirectEditEnabled] = useState(false);
  const [isDirectMoveEnabled, setIsDirectMoveEnabled] = useState(false);
  const [isDirectResizeEnabled, setIsDirectResizeEnabled] = useState(false);

  const insertElement = (element: HTMLElement) => {

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const parentElementId = element.getAttribute("data-parent");
    if (!parentElementId) return;

    const parentElement = viewport.getElementById(parentElementId);
    if (!parentElement) return;

    const existingElement = viewport.querySelector(`#${element.id}`);
    const previousElementID = element.getAttribute("data-is-after");
    const nextElementID = element.getAttribute("data-is-before");

    if (nextElementID) {
      const nextElement = viewport.querySelector(`#${nextElementID}`);
      if (nextElement) {
        try {
          parentElement.insertBefore(element, nextElement);
        } catch {
          parentElement.append(element);
        }
      } else {
        // Append
        parentElement.append(element);
      }

      if (existingElement) existingElement.remove();
    } else if (previousElementID) {
      const previousElement = viewport.querySelector(`#${previousElementID}`);
      if (previousElement) {
        try {
          previousElement.insertAdjacentElement('afterend', element);
        } catch {
          parentElement.append(element);
        }
      } else {
        // Append
        parentElement.append(element);
      }

      if (existingElement) existingElement.remove();
    } else {
      if (existingElement) existingElement.replaceWith(element);
      else parentElement.append(element);
    }

  }

  const createEditorElement = (type: string, styles: any, textContent?: string): Element => {

    const element = document.createElement(type);
    if (textContent) element.textContent = textContent;
    element.classList.add('sifo-manual-editor');

    element.style.userSelect = 'none';
    element.setAttribute('contenteditable', 'false');

    Object.entries(styles).forEach((e: any) => {
      element.style[e[0]] = e[1]
    })

    return element;

  }

  const clearEditor = () => {

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const elements = Array.from(viewport.querySelectorAll('.sifo-manual-editor'));
    elements.forEach((element: Element) => {
      if (element.parentNode) element.parentNode.removeChild(element);
    });

  }

  const isPropertyWritable = (obj: any, property: any) => {
    const descriptor = Object.getOwnPropertyDescriptor(obj, property);
    return descriptor ? !!descriptor.writable : true; // If descriptor is undefined, assume writable
  }

  const undrawSectionLabels = () => {
    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const existingLabels = viewport.querySelectorAll('.sifo-section-label');
    existingLabels.forEach((label: any) => {
      label.remove();
    })
  }

  const drawSectionLabels = () => {

    if (!DisplaySectionLabels) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const sections = viewport.querySelectorAll('div#body > *');
    if (sections.length < 1) return;

    // Check if it exists already, and delete it.

    const existingLabels = viewport.querySelectorAll('.sifo-section-label');
    existingLabels.forEach((label: any) => {
      label.remove();
    })

    sections.forEach((section: any) => {
      const sectionId = section.getAttribute("id");

      // const rect = section.getBoundingClientRect();
      const label = document.createElement('div');
      label.classList.add('sifo-section-label');
      label.textContent = sectionId;

      label.style.position = 'absolute';
      // label.style.left = `${rect.left + 10}px`;
      // label.style.top = `${rect.top + 10}px`;
      label.style.left = `${section.offsetLeft + 10}px`;
      label.style.top = `${section.offsetTop + 10}px`;

      label.style.color = '#fff';
      label.style.backgroundColor = '#805ad5';
      label.style.padding = '0.2rem 0.5rem';
      label.style.borderRadius = '0.3rem';
      label.style.opacity = '0.3';
      label.style.fontSize = '0.8rem';

      viewport.body.appendChild(label);
    })

  }

  const drawVisualEditor = () => {
    if (!displayVisualEditor && isWideScreen) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const elements = viewport.querySelectorAll('*:not(html):not(body):not(div#body)');

    const handleElementClick = (e: any) => {
      const localContextElement: any = viewport.querySelector(`#${e.id}`);

      const extras = localContextElement.querySelectorAll('.sifo-manual-editor');
      extras.forEach((extra: any) => {
        extra.remove();
      });

      // More cleanup here..
      const elementsWithPositioningAttributes = localContextElement.querySelectorAll('[data-parent]');
      elementsWithPositioningAttributes.forEach((elementWithPositioningAttributes: any) => {
        elementWithPositioningAttributes.removeAttribute('data-parent');
        elementWithPositioningAttributes.removeAttribute('data-is-before');
        elementWithPositioningAttributes.removeAttribute('data-is-after');
      });

      localContextElement.removeAttribute('data-parent');
      localContextElement.removeAttribute('data-is-before');
      localContextElement.removeAttribute('data-is-after');

      e.style.position = null;
      e.style.cursor = null;

      if (e.getAttribute('style') === "")
        e.removeAttribute('style')

      setLocalContext(localContextElement);

      const viewportCSS = viewport.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
      console.log('Viewport CSS: ', viewportCSS);
      if (!viewportCSS) return;


      // Here is the CSS local context bread and butter...
      // (Okay, might not waste too much time on it.)
      // When a selector does not use the element ID, for example: the selector is a class, .service-item, and the selected element has an id
      // of #div-808284, does clicking on it trigger the proper behaviour? The answer is No.
      // how have we not noticed this before? I think it's because the AI was using IDs for styling, but as our examples grow better and more comple,
      // it is now apparent that it is in fact missing something.

      const parser = new cssjs();

      let selectors = getIDsRecursively(e);
      console.log('Selectors (getIDsRecursively(e)): ', selectors);

      let classes = getClassesRecursively(e);
      console.log('Selectors (getClassesRecursively(e)): ', classes);

      let tags = getTagsRecursively(e);
      console.log('Selectors (getTagsRecursively(e)): ', classes);

      selectors = [...selectors, ...classes, ...tags];

      console.log("selectors: ", selectors);

      // console.log('IDs and Classes: ', selectors);

      // I grab an element,
      // I get all the IDs of all its children, including itself.
      // Then I extract the rules via selectors (ids, no classes so far)
      // Then I write the wanted CSS.

      // What I need is to broaden the selectors reach. The selectors array need to include the proper selectors being used in the actual CSS.

      const parsed = parser.parseCSS(viewportCSS);

      // const elementCSS = extractCSSRulesBySelectors(parsed, selectors)
      const elementCSS = extractCSSRulesBySelectorsNoKeyfranes(parsed, selectors)
      console.log("Elements CSS: ", elementCSS);
      if (!elementCSS) return;

      const elementCSSString = writeCSSFromRules(elementCSS);
      console.log("Elements CSS String: ", elementCSSString);

      if (!elementCSSString) return;

      setLocalContextCSS(elementCSSString);

    }

    elements.forEach((e: any) => {

      e.style.userSelect = 'none';
      e.style.webkitUserSelect = 'none';
      e.style.msUserSelect = 'none';
      e.style.mozUserSelect = 'none';

      e.oncontextmenu = (event: Event) => {
        event.preventDefault();
      }

      let timer: any;

      if (!isWideScreen) {
        if (Object.isExtensible(e)) {
          if (isPropertyWritable(e, 'ontouchstart')) {
            e.ontouchstart = (event: Event) => {
              event.stopPropagation();
              // event.preventDefault();

              timer = setTimeout(() => {
                handleElementClick(e);
              }, 800)
            }

          }

          if (isPropertyWritable(e, 'ontouchend')) {
            e.ontouchend = (event: Event) => {
              clearTimeout(timer);
            }
          }
        }
      }

      // e.ontouchmove = (event: Event) => {
      //   clearTimeout(timer);
      // }

      if (!isWideScreen) return;

      e.onclick = (event: Event) => {
        event.stopPropagation();
        event.preventDefault();

        handleElementClick(e);
      }

      const highlight = createEditorElement('div', {
        position: 'absolute', top: '0', left: '0',
        height: '100%', width: '100%',
        backgroundColor: editorColor, boxShadow: `0 15px 35px ${editorColor}`, opacity: '0.2',
        zIndex: '10', pointerEvents: 'none', animation: 'none'
      });

      const actionsContainer = createEditorElement('div', {
        display: 'flex', gap: '5px',
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
        fontFamily: 'sans-serif', fontWeight: 'normal', whiteSpace: 'nowrap',
        zIndex: '11', animation: 'none'
      });

      const idTag = createEditorElement('button', {
        borderRadius: '0.4em',
        color: editorColor === '#805AD5' ? '#fff' : '#000',
        backgroundColor: editorColor,
        border: 'none',
        animation: 'none', fontSize: '13px', height: '1.5rem'
      }, `#${e.id}`);

      const deleteButton = createEditorElement('button', {
        borderRadius: '0.4em',
        color: editorColor === '#805AD5' ? '#fff' : '#000',
        backgroundColor: editorDeleteButtonColor, cursor: 'pointer',
        border: 'none', animation: 'none', fontSize: '13px', height: '1.5rem'
      }, '✖ Delete');

      deleteButton.addEventListener('click', () => {
        e.remove();

        // Forms
        const pageFormTags: any = viewport.querySelectorAll('form');

        if (pageFormTags.length > 0 && selected_page && user) {
          const pageForms: any = constructPageFormsArray(pageFormTags, selected_page.id, user.email);
          setPageForms(pageForms);
          updateForms(selected_page.id, pageForms);
        }

        const pageAmchorTags: any = viewport.querySelectorAll('a');
        if (pageAmchorTags.length > 0) {
          const pageLinks: any = constructPageLinksArray(pageAmchorTags);
          setPageLinks(pageLinks);
          if (selected_page) updateLinks(selected_page.id, pageLinks);
        }

        // const pageImgTags: any = viewport.querySelectorAll('img');

        const allElements: any = viewport.querySelectorAll('*');

        const imageElements: any = Array.from(allElements).filter((element: any) => {
          if (element.tagName === 'IMG' || hasBackgroundImage(element)) {
            return true;
          }
        })

        if (imageElements.length > 0) {
          const pageImages: any = constructPageImagesArray(imageElements);
          setPageImages(pageImages);
          if (selected_page) updateImages(selected_page.id, pageImages);
        }

        if (selected_page) {
          const viewportContent = viewport.documentElement.outerHTML;

          setContentUndoHistory((prev) => {
            let modifiedPrev = prev;
            if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

            return [
              ...modifiedPrev,
              {
                timestamp: new Date().toLocaleString(),
                label: 'Delete element',
                content: viewportContent
              }
            ]
          });

          updatePageContent(selected_page.id, viewportContent);

          setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

          const htmlContent = viewport.documentElement.querySelector('div#body')?.innerHTML;
          const cssContent = viewport.documentElement.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
          const javascriptContent = viewport.documentElement.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;

          const css_parser = new cssjs();

          const selectors = getIDsRecursively(e);
          const parsed = css_parser.parseCSS(cssContent as string);
          const filtered = deleteCSSRulesBySelectors(parsed, selectors);
          const newCSS = writeCSSFromRules(filtered);

          if (htmlContent)
            setHTMLCode(htmlContent);

          if (cssContent)
            setCSSCode(newCSS);

          if (javascriptContent)
            setJavaScriptCode(javascriptContent);
        }
      })

      e.onmouseover = (event: Event) => {
        event.stopPropagation();
        e.style.position = 'relative';

        clearEditor();

        actionsContainer.appendChild(idTag);
        actionsContainer.appendChild(deleteButton);

        e.appendChild(highlight);
        if (e.hasChildNodes())
          e.appendChild(actionsContainer);
      }

      e.onmouseleave = (event: Event) => {
        e.style.position = null; // Is this enough, or should I add removeAttribute style?

        // Check if style attribute is empty, delete it.
        if (e.getAttribute('style') === '') {
          e.removeAttribute('style');
        }

        highlight.remove();
        actionsContainer.remove();
      }

    })
  }

  const clearVisualEditor = () => {
    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const elements = viewport.querySelectorAll('*:not(html):not(body):not(div#body)');

    elements.forEach((e: any) => {

      e.onclick = null;
      e.onmouseover = null;
      e.onmouseleave = null;

      // e.addEventListener('mouseover', (event: Event) => {
      //   event.stopPropagation();
      //   e.style.position = 'relative';

      //   clearEditor();

      //   actionsContainer.appendChild(idTag);
      //   actionsContainer.appendChild(deleteButton);

      //   e.appendChild(highlight);
      //   if (e.hasChildNodes())
      //     e.appendChild(actionsContainer);
      // })

      // e.addEventListener('mouseleave', (event: Event) => {
      //   e.style.position = null; // Is this enough, or should I add removeAttribute style?
      //   highlight.remove();
      //   actionsContainer.remove();
      // })


    })
  }


  const selectedOutlinerNode = useRecoilValue<any>(SelectedOutlinerNode);

  useEffect(() => {
    if (!selectedOutlinerNode) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const localContextElement: any = viewport.querySelector(`${selectedOutlinerNode.nodeTag}#${selectedOutlinerNode.nodeId}`);
    if (localContextElement) {
      setLocalContext(localContextElement);

      const viewportCSS = viewport.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
      if (!viewportCSS) return;

      const parser = new cssjs();

      const selectors = getIDsRecursively(localContextElement);
      const parsed = parser.parseCSS(viewportCSS);

      // const elementCSS = extractCSSRulesBySelectors(parsed, selectors)
      const elementCSS = extractCSSRulesBySelectorsNoKeyfranes(parsed, selectors)
      if (!elementCSS) return;

      const elementCSSString = writeCSSFromRules(elementCSS);
      if (!elementCSSString) return;

      setLocalContextCSS(elementCSSString);
    }

  }, [selectedOutlinerNode])

  // const reloadJavaScript = (viewport: any, scriptContent: string) => {
  //   const scriptElement = viewport.querySelector('script[data-dev-tag="inject-into"]');
  //   if (scriptElement) scriptElement.remove();

  //   // const errorDetectionScript = viewport.querySelector('script[data-id="error-detection-script"]');
  //   // if(errorDetectionScript) errorDetectionScript.remove();

  //   iframeReference.current!.srcdoc = viewport.documentElement.outerHTML;

  //   const newScriptElement = viewport.createElement('script');
  //   newScriptElement.setAttribute('data-dev-tag', 'inject-into');
  //   newScriptElement.innerHTML = scriptContent;

  //   // const newErrorDetectionScript = viewport.createElement('script');
  //   // newErrorDetectionScript.setAttribute('data-dev-tag', 'sifo-dev');
  //   // newErrorDetectionScript.setAttribute('data-id', 'error-detection-script');
  //   // newErrorDetectionScript.innerHTML = `
  //   // let errorDetected = false;

  //   // const errorLog = (event) => {
  //   //     if (errorDetected) return;
  //   //     errorDetected = true;

  //   //     event.preventDefault();
  //   //     event.stopPropagation();

  //   //     const errorMessage = event.message;

  //   //     window.parent.postMessage({
  //   //         type: 'js-exec-error',
  //   //         errmsg: errorMessage
  //   //     }, '*');
  //   // }

  //   // window.addEventListener("error", errorLog);
  //   // `;


  //   // viewport.body.appendChild(newErrorDetectionScript);
  //   viewport.body.appendChild(newScriptElement);
  // }

  const reloadJavaScript = (viewport: any, scriptContent: string) => {
    const scriptElement = viewport.querySelector('script[data-dev-tag="inject-into"]');
    scriptElement.remove();

    const bodyDiv = viewport.querySelector('div#body');
    const clonedBodyDiv = bodyDiv.cloneNode(true);
    bodyDiv.remove();

    viewport.body.prepend(clonedBodyDiv);

    // const code = scriptElement.innerHTML;

    const newScriptElement = viewport.createElement('script');
    newScriptElement.setAttribute('data-dev-tag', 'inject-into');
    newScriptElement.innerHTML = scriptContent;

    viewport.body.appendChild(newScriptElement);

    // console.log(
    //   scriptElement,
    //   newScriptElement,
    //   viewport.body
    // );

  }

  // function findParentSection(element: any): any {
  //   if (element.parentElement && element.parentElement.id === 'body') {
  //     return element;
  //   } else if (element.parentElement) {
  //     return findParentSection(element.parentElement);
  //   } else {
  //     return null;
  //   }
  // }

  function joinSet(set: any) {
    const arrayFromSet = [...set];
    const concatenatedString = arrayFromSet.join('\n');
    return concatenatedString;
  }

  function createDependencyScriptTag(cdnLink: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = cdnLink;
    script.classList.add('sifo-lib-cdn')

    return script;
  }

  function createStylesheetLinkTag(cssLink: string) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = cssLink;
    link.classList.add('sifo-lib-css')

    return link;
  }

  function getIframeScrollPosition() {
    const iframeDocument = iframeReference.current!.contentWindow!.document;
    const scrollLeft = iframeDocument.documentElement.scrollLeft || iframeDocument.body.scrollLeft;
    const scrollTop = iframeDocument.documentElement.scrollTop || iframeDocument.body.scrollTop;

    return { scrollLeft, scrollTop };
  }

  function setIframeScrollPosition(x: any, y: any) {
    const iframeWindow = iframeReference.current!.contentWindow;
    if (iframeWindow) iframeWindow.scrollTo(x, y);
  }

  function refreshViewport() {
    const iframe = iframeReference.current;
    const viewport = iframe?.contentDocument || iframe?.contentWindow?.document;
    if (!viewport) return;

    const scrollPos = getIframeScrollPosition();
    iframe.srcdoc = viewport.documentElement.outerHTML;

    iframe.onload = () => {
      setIframeScrollPosition(scrollPos.scrollLeft, scrollPos.scrollTop);
    };
  }

  useEffect(() => {
    if (!isInitialMount.current) {
      const iframe = iframeReference.current;
      if (!iframe) return;

      const viewport = iframe.contentDocument || iframe.contentWindow?.document;
      if (!viewport) return;

      const scrollPos = getIframeScrollPosition();

      const parser = new DOMParser();
      const workingDoc = parser.parseFromString(selected_page!.content, 'text/html');

      if (workingDoc && objects) {
        if (objects.html) {
          objects.html.forEach((HTMLElement: any) => {
            const parentElementId = HTMLElement.getAttribute("data-parent");
            if (!parentElementId) return;

            const workingDocParent = workingDoc.getElementById(parentElementId);
            const viewportParent = viewport.getElementById(parentElementId);

            if (!workingDocParent || !viewportParent) return;

            const previousElementID = HTMLElement.getAttribute("data-is-after");
            const nextElementID = HTMLElement.getAttribute("data-is-before");

            const processElement = (docParent: any, docQuery: any) => {
              const existingElement = docQuery(`#${HTMLElement.id}`);
              const nextElement = nextElementID ? docQuery(`#${nextElementID}`) : null;
              const previousElement = previousElementID ? docQuery(`#${previousElementID}`) : null;
            
              // Delete element if the data-delete attribute is set to true
              const isDeleteOperation = HTMLElement.getAttribute("data-delete") === "true";
              if (isDeleteOperation) {
                const toBeDeletedElementId = HTMLElement.id;
                if (toBeDeletedElementId) {
                  const toBeDeletedElement = docQuery(`#${toBeDeletedElementId}`);
                  // Add check to ensure element exists before attempting to remove it
                  if (!toBeDeletedElement) {
                    return; // Return early if element doesn't exist
                  }
                  toBeDeletedElement.remove();
                }
                return;
              }
            
              // Remove existing element if it exists and we're going to reposition it
              if (existingElement && (nextElementID || previousElementID)) {
                existingElement.remove();
              }
            
              // Handle positioning with next element
              if (nextElement) {
                try {
                  docParent.insertBefore(HTMLElement.cloneNode(true), nextElement);
                  return;
                } catch {
                  docParent.append(HTMLElement.cloneNode(true));
                  return;
                }
              }
            
              // Handle positioning with previous element
              if (previousElement) {
                try {
                  previousElement.insertAdjacentElement('afterend', HTMLElement.cloneNode(true));
                  return;
                } catch {
                  docParent.append(HTMLElement.cloneNode(true));
                  return;
                }
              }
            
              // Update existing element in place if no positioning is specified
              if (existingElement) {
                if (HTMLElement.children.length === 0) {
                  // Update attributes only
                  Array.from(HTMLElement.attributes).forEach((attr: any) => {
                    existingElement.setAttribute(attr.name, attr.value);
                  });
                } else {
                  // Replace entire element
                  existingElement.replaceWith(HTMLElement.cloneNode(true));
                }
                return;
              }
            
              // If element doesn't exist and no positioning, append to parent
              docParent.append(HTMLElement.cloneNode(true));
            };

            processElement(workingDocParent, (selector: any) => workingDoc.querySelector(selector));
            processElement(viewportParent, (selector: any) => viewport.querySelector(selector));
          });
        }
        // Refactored
        // if (objects.html) {
        //   objects.html.forEach((HTMLElement: any) => {
        //     const parentElementId = HTMLElement.getAttribute("data-parent");
        //     if (!parentElementId) return;

        //     const workingDocParent = workingDoc.getElementById(parentElementId);
        //     const viewportParent = viewport.getElementById(parentElementId);

        //     if (!workingDocParent || !viewportParent) return;

        //     const previousElementID = HTMLElement.getAttribute("data-is-after");
        //     const nextElementID = HTMLElement.getAttribute("data-is-before");

        //     const processElement = (docParent: any, docQuery: any) => {
        //       const existingElement = docQuery(`#${HTMLElement.id}`);
        //       const nextElement = nextElementID ? docQuery(`#${nextElementID}`) : null;
        //       const previousElement = previousElementID ? docQuery(`#${previousElementID}`) : null;

        //       if (nextElement) {
        //         docParent.insertBefore(HTMLElement.cloneNode(true), nextElement);
        //       } else if (previousElement) {
        //         previousElement.insertAdjacentElement("afterend", HTMLElement.cloneNode(true));
        //       } else if (existingElement) {
        //         existingElement.replaceWith(HTMLElement.cloneNode(true));
        //       } else {
        //         docParent.append(HTMLElement.cloneNode(true));
        //       }

        //       if (existingElement) existingElement.remove();
        //     };

        //     processElement(workingDocParent, (selector: any) => workingDoc.querySelector(selector));
        //     processElement(viewportParent, (selector: any) => viewport.querySelector(selector));
        //   });
        // }


        // if (objects.html) {
        //   objects.html.forEach((HTMLElement: HTMLElement): void => {

        //     const parentElementId = HTMLElement.getAttribute("data-parent");
        //     if (!parentElementId) return;

        //     // Get parent elements from both documents
        //     const workingDocParent = workingDoc.getElementById(parentElementId);
        //     const viewportParent = viewport.getElementById(parentElementId);

        //     if (!workingDocParent || !viewportParent) return;

        //     const previousElementID = HTMLElement.getAttribute("data-is-after");
        //     const nextElementID = HTMLElement.getAttribute("data-is-before");

        //     // Handle workingDoc
        //     const existingInWorking = workingDoc.querySelector(`#${HTMLElement.id}`);
        //     if (nextElementID) {
        //       const nextElement = workingDoc.querySelector(`#${nextElementID}`);
        //       if (nextElement) {
        //         try {
        //           workingDocParent.insertBefore(HTMLElement.cloneNode(true), nextElement);
        //         } catch {
        //           workingDocParent.append(HTMLElement.cloneNode(true));
        //         }
        //       } else {
        //         workingDocParent.append(HTMLElement.cloneNode(true));
        //       }
        //       if (existingInWorking) existingInWorking.remove();
        //     } else if (previousElementID) {
        //       const previousElement = workingDoc.querySelector(`#${previousElementID}`);
        //       if (previousElement) {
        //         try {
        //           previousElement.insertAdjacentElement('afterend', HTMLElement.cloneNode(true) as Element);
        //         } catch {
        //           workingDocParent.append(HTMLElement.cloneNode(true));
        //         }
        //       } else {
        //         workingDocParent.append(HTMLElement.cloneNode(true));
        //       }
        //       if (existingInWorking) existingInWorking.remove();
        //     } else {
        //       if (existingInWorking) existingInWorking.replaceWith(HTMLElement.cloneNode(true));
        //       else workingDocParent.append(HTMLElement.cloneNode(true));
        //     }

        //     // Handle viewport
        //     const existingInViewport = viewport.querySelector(`#${HTMLElement.id}`);
        //     if (nextElementID) {
        //       const nextElement = viewport.querySelector(`#${nextElementID}`);
        //       if (nextElement) {
        //         try {
        //           viewportParent.insertBefore(HTMLElement.cloneNode(true), nextElement);
        //         } catch {
        //           viewportParent.append(HTMLElement.cloneNode(true));
        //         }
        //       } else {
        //         viewportParent.append(HTMLElement.cloneNode(true));
        //       }
        //       if (existingInViewport) existingInViewport.remove();
        //     } else if (previousElementID) {
        //       const previousElement = viewport.querySelector(`#${previousElementID}`);
        //       if (previousElement) {
        //         try {
        //           previousElement.insertAdjacentElement('afterend', HTMLElement.cloneNode(true) as Element);
        //         } catch {
        //           viewportParent.append(HTMLElement.cloneNode(true));
        //         }
        //       } else {
        //         viewportParent.append(HTMLElement.cloneNode(true));
        //       }
        //       if (existingInViewport) existingInViewport.remove();
        //     } else {
        //       if (existingInViewport) existingInViewport.replaceWith(HTMLElement.cloneNode(true));
        //       else viewportParent.append(HTMLElement.cloneNode(true));
        //     }

        //   });
        // }

        // Handle CSS updates - Apply to both workingDoc and viewport

        // Handle stylesheets
        if (objects.stylesheets && objects.stylesheets.length > 0) {
          objects.stylesheets.forEach((stylesheet: any) => {
            const stylesheetLink = createStylesheetLinkTag(stylesheet);

            // For viewport
            const viewportHead = viewport.head;
            if (viewportHead) {
              const viewportInjectStyle = viewport.querySelector('style[data-dev-tag="inject-into"]');
              if (viewportInjectStyle) {
                const stylesheetHref = stylesheetLink.getAttribute("href");
                let existingViewportStylesheet = viewport.querySelector(`link[href="${stylesheetHref}"]`);

                if (!existingViewportStylesheet) {
                  viewportHead.insertBefore(stylesheetLink.cloneNode(true), viewportInjectStyle);
                }
              }
            }

            // For workingDoc
            const workingDocHead = workingDoc.head;
            if (workingDocHead) {
              const workingDocInjectStyle = workingDoc.querySelector('style[data-dev-tag="inject-into"]');
              if (workingDocInjectStyle) {
                const stylesheetHref = stylesheetLink.getAttribute("href");
                let existingWorkingDocStylesheet = workingDoc.querySelector(`link[href="${stylesheetHref}"]`);

                if (!existingWorkingDocStylesheet) {
                  workingDocHead.insertBefore(stylesheetLink.cloneNode(true), workingDocInjectStyle);
                }
              }
            }
          });
        }

        if (objects.css) {
          const styleElement = workingDoc.querySelector('style[data-dev-tag="inject-into"]');
          const viewportStyleElement = viewport.querySelector('style[data-dev-tag="inject-into"]');

          objects.css.forEach((CSSBlock: string) => {
            CSSBlock = fixCssSelectors(CSSBlock);
            if (styleElement) styleElement.innerHTML = insertStyles(styleElement.innerHTML, CSSBlock);
            if (viewportStyleElement) viewportStyleElement.innerHTML = insertStyles(viewportStyleElement.innerHTML, CSSBlock);
          });
        }

        // Handle dependencies
        if (objects.dependencies && objects.dependencies.length > 0) {
          objects.dependencies.forEach((dependency: any) => {
            const dependencyScript = createDependencyScriptTag(dependency);
            dependencyScript.removeAttribute('integrity');

            // For viewport
            const viewportBody = viewport.body;
            if (viewportBody) {
              const viewportInjectScript = viewport.querySelector('script[data-dev-tag="inject-into"]');
              if (viewportInjectScript) {
                const scriptSrc = dependencyScript.getAttribute("src");
                let existingViewportScript = viewport.querySelector(`script[src="${scriptSrc}"]`);

                if (!existingViewportScript) {
                  viewportBody.insertBefore(dependencyScript.cloneNode(true), viewportInjectScript);
                }
              }
            }

            // For workingDoc
            const workingDocBody = workingDoc.body;
            if (workingDocBody) {
              const workingDocInjectScript = workingDoc.querySelector('script[data-dev-tag="inject-into"]');
              if (workingDocInjectScript) {
                const scriptSrc = dependencyScript.getAttribute("src");
                let existingWorkingDocScript = workingDoc.querySelector(`script[src="${scriptSrc}"]`);

                if (!existingWorkingDocScript) {
                  workingDocBody.insertBefore(dependencyScript.cloneNode(true), workingDocInjectScript);
                }
              }
            }
          });
        }
        // Handle JavaScript updates - Use iframe reload approach
        if (objects.javascript && objects.javascript.length > 0) {

          const scriptElement = workingDoc.querySelector('script[data-dev-tag="inject-into"]');
          if (scriptElement) {
            let scriptContent: string = scriptElement.innerHTML;

            objects.javascript.forEach((JSCode: string) => {
              JSCode = fixCssSelectorsInJs(JSCode);
              if (willRepairJavascript) {
                scriptContent = JSCode;
              } else {
                scriptContent = LogicEngine(scriptContent, JSCode);
              }
            });

            scriptElement.innerHTML = scriptContent;

            // Update iframe with full content only for JS changes
            iframe.srcdoc = workingDoc.documentElement.outerHTML;
            iframe.onload = () => {
              setIframeScrollPosition(scrollPos.scrollLeft, scrollPos.scrollTop);
            };
          }
          setWillRepairJavascript(false);
        }

        // Rest of your code remains the same...
        // (Update undo history, handle forms, links, images, etc.)

        // Store the final content
        const newContent = workingDoc.documentElement.outerHTML;

        if (selected_page) {
          setContentUndoHistory((prev) => {
            let modifiedPrev = prev;
            if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

            return [
              ...modifiedPrev,
              {
                timestamp: new Date().toLocaleString(),
                label: 'Generate elements',
                content: newContent
              }
            ];
          });

          setConversationHistoryUndoHistory((prev) => {
            let modifiedPrev = prev;
            if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);
            return modifiedPrev;
          })

          setDidIUndo(false);
          updatePageContent(selected_page.id, newContent);

        }


        setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

        // Update code editors
        const htmlContent = workingDoc.querySelector('div#body')?.innerHTML;
        const cssContent = workingDoc.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
        const javascriptContent = workingDoc.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;

        if (htmlContent) setHTMLCode(htmlContent);
        if (cssContent) setCSSCode(cssContent);
        if (javascriptContent) setJavaScriptCode(javascriptContent);

        // Optimize context building
        const bodyElement: any = workingDoc.querySelector("div#body");
        if (bodyElement) {
          const bodyClone: any = bodyElement.cloneNode(true);
          bodyClone.querySelectorAll('[contenteditable]')
            .forEach((element: any) => element.removeAttribute('contenteditable'));
          setGlobalContextOuterHTML(bodyClone.innerHTML);

          workingDoc.querySelectorAll('*:not(html):not(body):not(div#body)')
            .forEach((element: any) => {
              if (contentEditableElements.includes(element.tagName.toLowerCase())) {
                element.setAttribute('contenteditable', 'true');
              }
            });
        }

        // Handle forms, links, and images
        const pageFormTags: any = workingDoc.querySelectorAll('form');
        if (pageFormTags.length > 0 && selected_page && user) {
          const pageForms: any = constructPageFormsArray(pageFormTags, selected_page.id, user.email);
          setPageForms(pageForms);
          if (selected_page) updateForms(selected_page.id, pageForms);
        }

        const pageAnchorTags: any = workingDoc.querySelectorAll('a');
        if (pageAnchorTags.length > 0) {
          const pageLinks: any = constructPageLinksArray(pageAnchorTags);
          setPageLinks(pageLinks);
          if (selected_page) updateLinks(selected_page.id, pageLinks);
        }

        const allElements: any = workingDoc.querySelectorAll('*');
        const imageElements: any = Array.from(allElements).filter((element: any) => {
          return element.tagName === 'IMG' || hasBackgroundImage(element);
        });

        if (imageElements.length > 0) {
          const pageImages: any = constructPageImagesArray(imageElements);
          setPageImages(pageImages);
          if (selected_page) updateImages(selected_page.id, pageImages);
        }

        syncCommonHtmlElements(workingDoc);
      }
    } else {
      isInitialMount.current = false;
    }
  }, [objects]);

  // Apply new theme color
  useEffect(() => {
    if (applyThemeColor) {
      const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
      if (!viewport) return;

      const styleElement = viewport.querySelector('style[data-dev-tag="inject-into"]');
      if (styleElement) styleElement.innerHTML = insertStyles(styleElement.innerHTML, applyThemeColor);

      // Do it for all pages and save..

      setApplyThemeColor(null);
    }

  }, [applyThemeColor])

  // useEffect(() => {

  //   // No IDs

  //   const iframe = iframeReference.current;
  //   const viewport = iframe?.contentDocument || iframe?.contentWindow?.document;
  //   if (!viewport) return;

  //   iframe.onload = () => {
  //     // Generate IDs for elements that do not have IDs.
  //     const elementsWithoutId = viewport.querySelectorAll('div#body *:not([id])');

  //     elementsWithoutId.forEach((element: any) => {
  //       const tag = element.tagName.toLowerCase() || 'unknown';
  //       const randomId = Math.floor(Math.random() * 1000000);
  //       element.setAttribute("id", `${tag}-${randomId}`);
  //     })

  //   }

  // }, [selected_page])

  /*
  Rebuild context on iframe load
  Generate parent IDs and data attributes for elements that don't have them.
  */

  const syncCommonHtmlElements = (viewport: any) => {
    const domParser = new DOMParser();

    let updatedPages: any = [];
    let updatedProjectCommonElements: any = [];

    // Overwrite pages with modified pages.
    projectCommonElementsParsed && projectCommonElementsParsed.forEach((commonElement: any) => {
      const inViewportCommonElement = viewport.querySelector("#" + commonElement.id);

      if (inViewportCommonElement) {
        const inViewportCommonElementOuterHTML = inViewportCommonElement.outerHTML;
        const commonElementOuterHTML = commonElement.outerHTML;

        if (inViewportCommonElementOuterHTML !== commonElementOuterHTML) {
          updatedPages = updatedPages.length > 0 ? updatedPages : [...pages];

          updatedPages = updatedPages.map((page: any) => {
            const parsedPageContent = domParser.parseFromString(page.content, "text/html");
            const commonElementInPage = parsedPageContent.querySelector("#" + commonElement.id);

            // const clonedElement = inViewportCommonElement.cloneNode(true);

            // if (!commonElementInPage && !fromTemplate) {
            //   parsedPageContent.body.appendChild(clonedElement);
            // }

            if (commonElementInPage) {
              const clonedElement = inViewportCommonElement.cloneNode(true);
              commonElementInPage.replaceWith(clonedElement);

              return {
                ...page,
                content: parsedPageContent.documentElement.outerHTML,
              };
            }

            return page;
          });

        }
      }
    });

    // Overwrite CommonElements with new, updated one.
    projectCommonElementsIDs && projectCommonElementsIDs.forEach((commonElementId: any) => {
      const inViewportCommonElement = viewport.querySelector("#" + commonElementId);

      if (inViewportCommonElement) {
        updatedProjectCommonElements.push(
          inViewportCommonElement.outerHTML
        );
      }
    })

    // Call setPages once with the finalized updated pages.
    if (updatedPages.length > 0) setPages(updatedPages);

    // Call setProjectCommonElements once with finalized updated common elements.
    if (updatedProjectCommonElements.length > 0) setProjectCommonElements(updatedProjectCommonElements);

    // Update pages in database.
    if (updatedPages.length > 0) updateProjectPages(updatedPages);

    // Update project common elements in database.
    if (updatedProjectCommonElements.length > 0) updateProjectCommonElements(selected_project!.id, updatedProjectCommonElements)
  }

  // Re-sync when common elements change
  // useEffect(() => {
  //   if (projectCommonElements && projectCommonElements.length > 0) {

  //     const iframe = iframeReference.current;
  //     if (!iframe) return;

  //     const viewport = iframe?.contentDocument || iframe?.contentWindow?.document;
  //     if (!viewport) return;

  //     syncCommonHtmlElements(viewport);
  //   }

  // }, [projectCommonElements])

  useEffect(() => {
    if (!selected_page) return;

    const iframe = iframeReference.current;
    if (!iframe) return;
    const viewport = iframe?.contentDocument || iframe?.contentWindow?.document;
    if (!viewport) return;
    const parser = new DOMParser();
    const originalDoc = parser.parseFromString(selected_page.content, 'text/html');
    iframe.onload = () => {
      console.log("page were in = ", selected_page)


      // For devemopment and testing purposes
      // const { originalToNormalized, normalizedColors } = extractColorPalette(viewport);
      // const colorMapping = createThemeColorMapping("#00FF00", normalizedColors);
      // overwriteColors(colorMapping, viewport, originalToNormalized);

      // Temporary so I can save and inspect..
      // const viewportContent = viewport.documentElement.outerHTML;
      // updatePageContent(selected_page!.id, viewportContent);

      const elementsWithoutId = originalDoc.querySelectorAll('div#body *:not([id])');

      elementsWithoutId.forEach((element: any) => {
        const tag = element.tagName.toLowerCase() || 'unknown';
        const randomId = Math.floor(Math.random() * 1000000);
        element.setAttribute("id", `${tag}-${randomId}`);
      })

      /*
      New method of doing things, context is plain html,
      and we remove contenteditable from context because it is not needed,
      as it is injected afterwards.
      */

      const bodyElement = originalDoc.querySelector("div#body");

      if (bodyElement) {
        bodyElement.querySelectorAll('[contenteditable]').forEach((element: any) => element.removeAttribute('contenteditable'));
        setGlobalContextOuterHTML(bodyElement.innerHTML);
      }


      /* 
      Enable direct edit.
      Add contenteditable attribute to all elements.
      */



      /* 
      Enable direct resize.
      Disable it, then enable it, to refresh.
      */

      // iframeReference.current?.contentWindow?.postMessage('direct-resize-disabled', '*');
      // iframeReference.current?.contentWindow?.postMessage('direct-resize-enabled', '*');

      // iframeReference.current?.contentWindow?.postMessage('direct-resize-refresh', '*');
      // refreshViewport();
    }
    const htmlContent = originalDoc.querySelector('div#body')?.innerHTML;
    const cssContent = originalDoc.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
    const javascriptContent = originalDoc.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;

    if (!didIUndo) {
      if (htmlContent) setHTMLCode(htmlContent);
      if (cssContent) setCSSCode(cssContent);
      if (javascriptContent) setJavaScriptCode(javascriptContent);
    }
    const elements = viewport.querySelectorAll('*:not(html):not(body):not(div#body)');

    elements.forEach((e: any) => {
      if (contentEditableElements.includes(
        e.tagName.toLowerCase()
      )) {
        e.setAttribute('contenteditable', 'true');
      }
    })

  }, [selected_page])

  // Generate panels data for selected page

  // useEffect(() => {
  //   if (pageLinks.length === 0 && pageImages.length === 0 && pageForms.length === 0) {
  //     const iframe = iframeReference.current;
  //     const viewport = iframe?.contentDocument || iframe?.contentWindow?.document;
  //     if (!viewport) return;

  //     iframe.onload = () => {

  //       console.log("selected_page: About to generate and fetch now: ###### ", selected_page);

  //       const pageAmchorTags: any = viewport.querySelectorAll('a');
  //       if (pageAmchorTags.length > 0) {
  //         const pageLinks: any = constructPageLinksArray(pageAmchorTags);
  //         if (selected_page) updateLinks(selected_page.id, pageLinks);
  //         setPageLinks(pageLinks);
  //       }

  //       const allElements: any = viewport.querySelectorAll('*');
  //       const imageElements: any = Array.from(allElements).filter((element: any) => {
  //         if (element.tagName === 'IMG' || hasBackgroundImage(element)) {
  //           return true;
  //         }
  //       })

  //       if (imageElements.length > 0) {
  //         const pageImages: any = constructPageImagesArray(imageElements);
  //         if (selected_page) updateImages(selected_page.id, pageImages);
  //         setPageImages(pageImages);
  //       }



  //       const pageFormTags: any = viewport.querySelectorAll('form');
  //       // console.log('pageFormTags:', pageFormTags);

  //       if (pageFormTags.length > 0 && selected_page && user) {
  //         const pageForms: any = constructPageFormsArray(pageFormTags, selected_page.id, user.email);
  //         // console.log('constructPageFormsArray(pageForms):', pageForms);
  //         // console.log('Setting page forms...');
  //         if (selected_page) updateForms(selected_page.id, pageForms);
  //         setPageForms(pageForms);
  //         // console.log('Updating forms in database...');
  //       }

  //     };
  //   }

  // }, [pageLinks, pageImages, pageForms, selected_page])

  // useEffect(() => {
  //   const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;

  //   if (viewport && contentUndoHistory) {
  //     const htmlContent = viewport.querySelector('div#body')?.innerHTML;
  //     const cssContent = viewport.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
  //     const javascriptContent = viewport.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;

  //     if (htmlContent)
  //       setHTMLCode(htmlContent);

  //     if (cssContent)
  //       setCSSCode(cssContent);

  //     if (javascriptContent)
  //       setJavaScriptCode(javascriptContent);
  //   }

  // }, [contentUndoHistory])

  const confirmManualEdits = () => {
    if (!selected_page) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const elements = viewport.querySelectorAll('[contenteditable]');
    elements.forEach((e: any) => {
      e.removeAttribute('contenteditable');
    })

    const viewportContent = viewport.documentElement.outerHTML;

    setContentUndoHistory((prev) => {
      let modifiedPrev = prev;
      if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

      return [
        ...modifiedPrev,
        {
          timestamp: new Date().toLocaleString(),
          label: 'Edit manually',
          content: viewportContent
        }
      ]
    });

    setDidIUndo(false);
    updatePageContent(selected_page.id, viewportContent);
    setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

    const htmlContent = viewport.documentElement.querySelector('div#body')?.innerHTML;
    const cssContent = viewport.documentElement.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
    const javascriptContent = viewport.documentElement.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;

    if (htmlContent)
      setHTMLCode(htmlContent);

    if (cssContent)
      setCSSCode(cssContent);

    if (javascriptContent)
      setJavaScriptCode(javascriptContent);

    setHasBeenManuallyEdited(false);
    // setIsDirectEditEnabled(false);

    // iframeReference.current?.contentWindow?.postMessage('move-mode-disabled', '*');
    // setIsDirectMoveEnabled(false);

    // iframeReference.current?.contentWindow?.postMessage('direct-resize-disabled', '*');
    // setIsDirectResizeEnabled(false);

    disableDirectEdit();
    disableDirectMove();
    disableDirectResize();
  }

  const enableDirectEdit = () => {
    if (!selected_page) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    if (displayVisualEditor) setDisplayVisualEditor(false);

    // When enabled, button turns solid, like sidebar, or outline maybe, since smaller icons, and different

    if (isDirectMoveEnabled) {
      disableDirectMove();
    }

    if (isDirectResizeEnabled) {
      disableDirectResize();
    }

    setIsDirectEditEnabled(true);

    const elements = viewport.querySelectorAll('*:not(html):not(body):not(div#body)');
    elements.forEach((e: any) => {
      if (contentEditableElements.includes(
        e.tagName.toLowerCase()
      )) {
        e.setAttribute('contenteditable', 'true');
      }
    })

    // Put controls back in center in highlighter
    // Revert back to clicking on element to select context
  }

  const disableDirectEdit = () => {
    if (!selected_page) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    setIsDirectEditEnabled(false);

    const elements = viewport.querySelectorAll('[contenteditable]');
    elements.forEach((e: any) => {
      e.removeAttribute('contenteditable');
    })
  }

  const enableDirectMove = () => {
    if (!selected_page) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    if (displayVisualEditor) setDisplayVisualEditor(false);

    if (isDirectEditEnabled) {
      disableDirectEdit();
    }

    if (isDirectResizeEnabled) {
      disableDirectResize();
    }

    iframeReference.current?.contentWindow?.postMessage('move-mode-enabled', '*');
    setIsDirectMoveEnabled(true);
  }

  const disableDirectMove = () => {
    if (!selected_page) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    iframeReference.current?.contentWindow?.postMessage('move-mode-disabled', '*');
    setIsDirectMoveEnabled(false);
  }

  const enableDirectResize = () => {
    if (!selected_page) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    if (displayVisualEditor) setDisplayVisualEditor(false);

    if (isDirectEditEnabled) {
      disableDirectEdit();
    }

    if (isDirectMoveEnabled) {
      disableDirectMove();
    }

    iframeReference.current?.contentWindow?.postMessage('direct-resize-enabled', '*');
    setIsDirectResizeEnabled(true);
  }

  const disableDirectResize = () => {
    if (!selected_page) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    iframeReference.current?.contentWindow?.postMessage('direct-resize-disabled', '*');
    setIsDirectResizeEnabled(false);
  }

  // window.onmessage = (e) => {
  //   if (e.data === 'manually-edited') setHasBeenManuallyEdited(true);
  // }

  window.onmessage = (e) => {
    // console.log(e.data);

    if (e.data === 'close-panels-on-mobile') handleClickInProcessorWhenMobile();

    /*
    Old way of handling direct edit,
    by way of enabling a special mode, and changing the state
    to allow for the activation of the apply changes button.
    */

    // if (e.data === 'manually-edited') setHasBeenManuallyEdited(true);

    /*
    We update pageContent regularly, after manual edits,
    and we debounce it to not trigger too many calls to the server,
    keeping things in sync.
    */

    // if (e.data === 'manually-edited') {
    //   if (!selected_page) return;

    //   const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    //   if (!viewport) return;

    //   // Know which element got modified.
    //   // Edit it in cleanViewport
    //   // Pass clean viewport

    //   // Not very good, runs too many times
    //   // syncCommonHtmlElements(viewport);

    //   // const cleanViewport = domParser.parseFromString(selected_page.content, "text/html");


    //   // debouncedUpdatePage(cleanViewport, selected_page.id);
    //   debouncedUpdatePage(viewport, selected_page.id);
    // }

    if (e.data.title === 'manually-edited') {
      if (!selected_page) return;

      const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
      if (!viewport) return;

      console.log("e.data.target: ", e.data.target);

      if (e.data.target) {
        const modifiedElementInViewport = viewport.querySelector("#" + e.data.target);

        if (!modifiedElementInViewport) return;

        const isAllowedElement = contentEditableElements.includes(modifiedElementInViewport.tagName.toLowerCase());

        if (!isAllowedElement ||
          modifiedElementInViewport.tagName.toLowerCase() === 'input' ||
          modifiedElementInViewport.tagName.toLowerCase() === 'textarea' ||
          modifiedElementInViewport.tagName.toLowerCase() === 'select' ||
          modifiedElementInViewport.closest('form')) {
          return;
        }

        const cleanViewport = domParser.parseFromString(selected_page.content, "text/html");
        const modifiedElementInClean = cleanViewport.querySelector("#" + e.data.target);

        if (modifiedElementInClean) {
          modifiedElementInClean.innerHTML = modifiedElementInViewport.innerHTML;
        }

        debouncedUpdatePage(cleanViewport, selected_page.id);
      }
    }



    if (e.data.type === 'js-exec-error') {

      const errorMessage = e.data.errmsg;

      // Don't run this when throttled,
      // but throttled how?
      // on a per project basis, or a cooldown?

      // tryToFixErrorWithAi(errorMessage);

      // toast({
      //   title: 'Code execution error',
      //   description: 'Looks like there is an error in the code, please undo and try again.',
      //   variant: 'solid',
      //   status: 'error',
      //   position: 'bottom-left',
      //   duration: 6000,
      // });

      // const errorMessage = e.data.errmsg;

      // toast({
      //   position: 'bottom-left',
      //   duration: 6000,
      //   render: () => (
      //     <Box>
      //       <Button colorScheme="red" onClick={() => tryToFixErrorWithAi(errorMessage)} size="sm">Try to fix.</Button>
      //     </Box>
      //   ),
      // })

    }
  }

  useEffect(() => {
    // console.log(viewportSourceDocument); //

    // console.log("viewportSourceDocument: ", viewportSourceDocument);

    if (viewportSourceDocument) {
      iframeReference.current!.srcdoc = viewportSourceDocument;
    }

    // For extra caution?
    clearVisualEditor();
    undrawSectionLabels();

    // if (!displaySectionLabels) {
    //   undrawSectionLabels();
    // }

    // console.log(viewportSourceDocument);
    // console.log(iframeReference.current!.srcdoc);

    // if (iframeReference.current)
    // iframeReference.current.srcdoc = viewportSourceDocument;
  }, [viewportSourceDocument, selected_page?.id])

  useEffect(() => {
    if (undoHistoryStepIndex) {
      setViewportSourceDocument(null);
    }
  }, [undoHistoryStepIndex])

  // useEffect(() => {
  //   if (didIUndo) {
  //     undrawSectionLabels();
  //   } 
  // }, [didIUndo])

  // useEffect(() => {
  //   if (!isUpdated) return;

  //   const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
  //   if (!viewport) return;


  //   const bodyElement = viewport.querySelector('div#body');
  //   if (bodyElement) bodyElement.innerHTML = htmlCode;

  //   const styleElement = viewport.querySelector('style[data-dev-tag="inject-into"]');
  //   if (styleElement) styleElement.innerHTML = cssCode;

  //   const scriptElement = viewport.querySelector('script[data-dev-tag="inject-into"]');
  //   if (scriptElement) scriptElement.innerHTML = javascriptCode;

  // }, [htmlCode, cssCode, javascriptCode]);

  // useEffect(() => {
  //   if (!isUpdated) return;

  //   const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
  //   if (!viewport) return;

  //   const bodyElement = viewport.querySelector('div#body');
  //   if (bodyElement) bodyElement.innerHTML = htmlCode;
  // }, [htmlCode]);

  // useEffect(() => {
  //   if (!isUpdated) return;

  //   const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
  //   if (!viewport) return;

  //   const styleElement = viewport.querySelector('style[data-dev-tag="inject-into"]');
  //   if (styleElement) styleElement.innerHTML = cssCode;
  // }, [cssCode]);

  // useEffect(() => {
  //   if (!isUpdated) return;

  //   // I probably need to reset the flag for consecutive ones not working,
  //   const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
  //   if (!viewport) return;

  //   const scriptElement = viewport.querySelector('script[data-dev-tag="inject-into"]');
  //   if (scriptElement) scriptElement.innerHTML = javascriptCode;

  //   refreshViewport();
  //   // iframeReference.current!.srcdoc = viewport.documentElement.outerHTML;

  //   // console.log(viewport.documentElement.outerHTML);
  //   // reloadJavaScript(viewport, javascriptCode);
  // }, [javascriptCode]);

  // useEffect(() => {
  //   if (isUpdated) {
  //     if (!selected_page) return;
  //     const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
  //     if (!viewport) return;

  //     // Forms
  //     const pageFormTags: any = viewport.querySelectorAll('form');

  //     if (pageFormTags.length > 0 && selected_page && user) {
  //       const pageForms: any = constructPageFormsArray(pageFormTags, selected_page.id, user.email);
  //       setPageForms(pageForms);
  //       if (selected_page) updateForms(selected_page.id, pageForms);
  //     }

  //     const pageAmchorTags: any = viewport.querySelectorAll('a');
  //     if (pageAmchorTags.length > 0) {
  //       const pageLinks: any = constructPageLinksArray(pageAmchorTags);
  //       setPageLinks(pageLinks);
  //       if (selected_page) updateLinks(selected_page.id, pageLinks);
  //     }

  //     const allElements: any = viewport.querySelectorAll('*');

  //     const imageElements: any = Array.from(allElements).filter((element: any) => {
  //       if (element.tagName === 'IMG' || hasBackgroundImage(element)) {
  //         return true;
  //       }
  //     })

  //     if (imageElements.length > 0) {
  //       const pageImages: any = constructPageImagesArray(imageElements);
  //       console.log('pageImages: ', pageImages);
  //       setPageImages(pageImages);
  //       if (selected_page) updateImages(selected_page.id, pageImages);
  //     }

  //     // const elems = viewport.querySelectorAll('div#body *');
  //     // if (elems.length > 0) {
  //     //   elems.forEach((elem: any) => {
  //     //     const elemId = elem.getAttribute('id');

  //     //     if (!elemId) {
  //     //       const tag = elem.tagName.toLowerCase() || 'unknown';
  //     //       const randomId = Math.floor(Math.random() * 1000000);
  //     //       elem.setAttribute('id', `${tag}-${randomId}`)
  //     //     }
  //     //   })
  //     // };

  //     const viewportContent = viewport.documentElement.outerHTML;

  //     setContentUndoHistory((prev) => {
  //       let modifiedPrev = prev;
  //       if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

  //       return [
  //         ...modifiedPrev,
  //         {
  //           timestamp: new Date().toLocaleString(),
  //           label: 'Developer mode edit',
  //           content: viewportContent
  //         }
  //       ]
  //     });

  //     setConversationHistoryUndoHistory((prev) => {
  //       let modifiedPrev = prev;
  //       if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);
  //       return modifiedPrev;
  //     })

  //     setDidIUndo(false);
  //     updatePageContent(selected_page.id, viewportContent);
  //     setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

  //     /*
  //     Legacy way of doing things, using the viewportTree for context,
  //     and a specific format of completions.
  //     */

  //     // const viewportTree = buildContextTree(viewport.documentElement);
  //     // setViewportTree(viewportTree);

  //     /*
  //     New method of doing things, context is plain html,
  //     and we remove contenteditable from context because it is not needed,
  //     as it is injected afterwards.
  //     */

  //     const bodyElement = viewport.querySelector("div#body");

  //     if (bodyElement) {
  //       bodyElement.querySelectorAll('[contenteditable]').forEach(element => element.removeAttribute('contenteditable'));
  //       setGlobalContextOuterHTML(bodyElement.innerHTML);
  //     }

  //     // setGlobalContextOuterHTML(viewport.querySelector("div#body")!.innerHTML);
  //     // setGlobalContextOuterHTML(viewport.querySelector("div#body")!.outerHTML);

  //     syncCommonHtmlElements(viewport);

  //   }


  //   setIsUpdated(false);
  // }, [isUpdated])

  useEffect(() => {
    if (!isUpdated || !selected_page) return;

    // Parse the original document from DB
    const parser = new DOMParser();
    const originalDoc = parser.parseFromString(selected_page.content, 'text/html');

    // Update HTML
    const bodyElement = originalDoc.querySelector('div#body');
    if (bodyElement) bodyElement.innerHTML = htmlCode;

    // Update CSS
    const styleElement = originalDoc.querySelector('style[data-dev-tag="inject-into"]');
    if (styleElement) styleElement.innerHTML = cssCode;

    // Update JavaScript
    const scriptElement = originalDoc.querySelector('script[data-dev-tag="inject-into"]');
    if (scriptElement) scriptElement.innerHTML = javascriptCode;

    // Process Forms
    const pageFormTags = originalDoc.querySelectorAll('form');
    if (pageFormTags.length > 0 && user) {
      const pageForms: any = constructPageFormsArray(pageFormTags, selected_page.id, user.email);
      setPageForms(pageForms);
      updateForms(selected_page.id, pageForms);
    }

    // Process Links
    const pageAnchorTags = originalDoc.querySelectorAll('a');
    if (pageAnchorTags.length > 0) {
      const pageLinks: any = constructPageLinksArray(pageAnchorTags);
      setPageLinks(pageLinks);
      updateLinks(selected_page.id, pageLinks);
    }

    // Process Images
    const allElements = originalDoc.querySelectorAll('*');
    const imageElements = Array.from(allElements).filter((element: any) => {
      return element.tagName === 'IMG' || hasBackgroundImage(element);
    });

    if (imageElements.length > 0) {
      const pageImages = constructPageImagesArray(imageElements);
      setPageImages(pageImages);
      updateImages(selected_page.id, pageImages);
    }

    // Update undo history
    const newContent = originalDoc.documentElement.outerHTML;

    setContentUndoHistory((prev) => {
      let modifiedPrev = didIUndo
        ? prev.filter((step, index) => index <= undoHistoryStepIndex)
        : prev;

      return [
        ...modifiedPrev,
        {
          timestamp: new Date().toLocaleString(),
          label: 'Developer mode edit',
          content: newContent
        }
      ];
    });

    setConversationHistoryUndoHistory((prev) => {
      return didIUndo
        ? prev.filter((step, index) => index <= undoHistoryStepIndex)
        : prev;
    });

    // Update context
    const contextBody = originalDoc.querySelector("div#body");
    if (contextBody) {
      contextBody.querySelectorAll('[contenteditable]')
        .forEach((element: any) => element.removeAttribute('contenteditable'));
      setGlobalContextOuterHTML(contextBody.innerHTML);
    }

    // Sync common elements
    syncCommonHtmlElements(originalDoc);

    // Update iframe content
    if (iframeReference.current) {
      iframeReference.current.srcdoc = newContent;
    }

    // Update page content in DB
    updatePageContent(selected_page.id, newContent);

    // Reset flags and update indices
    setDidIUndo(false);
    setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);
    setIsUpdated(false);

  }, [isUpdated, htmlCode, cssCode, javascriptCode]);

  useEffect(() => {
    if (!pageLinksHaveBeenUpdated || !selected_page) return;

    // Get both viewports
    const liveViewport = iframeReference.current?.contentDocument ||
      iframeReference.current?.contentWindow?.document;

    // Create clean viewport from original content
    const parser = new DOMParser();
    const cleanViewport = parser.parseFromString(selected_page.content, 'text/html');

    if (!liveViewport || !cleanViewport) return;

    // Update both viewports
    pageLinks.forEach((link: any) => {
      // Update live viewport for immediate user feedback
      const liveAnchorTag = liveViewport.querySelector(`#${link.id}`);
      const cleanAnchorTag = cleanViewport.querySelector(`#${link.id}`);

      if (liveAnchorTag) {
        liveAnchorTag.innerHTML = link.inner;
        liveAnchorTag.setAttribute('href', link.href);
      }

      if (cleanAnchorTag) {
        cleanAnchorTag.innerHTML = link.inner;
        cleanAnchorTag.setAttribute('href', link.href);
      }
    });

    // Get content from clean viewport for saving
    const cleanContent = cleanViewport.documentElement.outerHTML;

    // Update undo history
    setContentUndoHistory((prev) => {
      let modifiedPrev = prev;
      if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

      return [
        ...modifiedPrev,
        {
          timestamp: new Date().toLocaleString(),
          label: 'Edit links',
          content: cleanContent
        }
      ];
    });

    setConversationHistoryUndoHistory((prev) => {
      let modifiedPrev = prev;
      if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);
      return modifiedPrev;
    });

    setDidIUndo(false);
    updatePageContent(selected_page.id, cleanContent);
    setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

    // Update code editors using clean viewport
    const htmlContent = cleanViewport.documentElement.querySelector('div#body')?.innerHTML;
    const cssContent = cleanViewport.documentElement.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
    const javascriptContent = cleanViewport.documentElement.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;

    if (htmlContent) setHTMLCode(htmlContent);
    if (cssContent) setCSSCode(cssContent);
    if (javascriptContent) setJavaScriptCode(javascriptContent);

    setPageLinksHaveBeenUpdated(false);
  }, [pageLinksHaveBeenUpdated]);

  useEffect(() => {
    if (!pageFormsHaveBeenUpdated || !pageForms.length || !selected_page) return;

    // Get both viewports
    const liveViewport = iframeReference.current?.contentDocument ||
      iframeReference.current?.contentWindow?.document;

    // Create clean viewport from original content
    const parser = new DOMParser();
    const cleanViewport = parser.parseFromString(selected_page.content, 'text/html');

    if (!liveViewport || !cleanViewport) return;

    // Update forms in database first
    updateForms(selected_page.id, pageForms);

    // Update both viewports
    pageForms.forEach((form: any) => {
      // Update live viewport for immediate user feedback
      const liveFormTag = liveViewport.querySelector(`#${form.dom_id}`);
      const cleanFormTag = cleanViewport.querySelector(`#${form.dom_id}`);

      // Apply changes to both viewports
      [liveFormTag, cleanFormTag].forEach(formTag => {
        if (formTag) {
          formTag.setAttribute('action', form.action);
          formTag.setAttribute('method', 'post');
          formTag.removeAttribute('onsubmit');
          // formTag.setAttribute('target', 'submission_target_frame');
          // formTag.setAttribute('target', '_blank');
        }
      });
    });

    // Get content from clean viewport for saving
    const cleanContent = cleanViewport.documentElement.outerHTML;

    // Update undo history
    setContentUndoHistory((prev) => {
      let modifiedPrev = prev;
      if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

      return [
        ...modifiedPrev,
        {
          timestamp: new Date().toLocaleString(),
          label: 'Edit forms',
          content: cleanContent
        }
      ];
    });

    setConversationHistoryUndoHistory((prev) => {
      let modifiedPrev = prev;
      if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);
      return modifiedPrev;
    });

    setDidIUndo(false);
    updatePageContent(selected_page.id, cleanContent);
    setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

    // Update code editors using clean viewport
    const htmlContent = cleanViewport.documentElement.querySelector('div#body')?.innerHTML;
    const cssContent = cleanViewport.documentElement.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
    const javascriptContent = cleanViewport.documentElement.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;

    if (htmlContent) setHTMLCode(htmlContent);
    if (cssContent) setCSSCode(cssContent);
    if (javascriptContent) setJavaScriptCode(javascriptContent);

    setPageFormsHaveBeenUpdated(false);
  }, [pageFormsHaveBeenUpdated, pageForms]);

  function getPlaceholderDimensions(url: string) {
    if (url.includes("placehold")) {
      const regex = /placehold.co\/(\d+)x(\d+)/;
      const match = url.match(regex);

      if (match) {
        return {
          width: parseInt(match[1], 10),
          height: parseInt(match[2], 10)
        };
      }
    }

    return null;
  }

  function detectPlaceholderImages() {
    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const styleElement = viewport.querySelector('style[data-dev-tag="inject-into"]');



    const images = viewport.querySelectorAll('img');

    images.forEach((img: any) => {
      const imageSrc = img.getAttribute("src");

      if (!imageSrc) return;
      if (imageSrc === "#") return;

      const placeholderImageDimensions = getPlaceholderDimensions(imageSrc);

      if (placeholderImageDimensions) {
        const fixedCssBlock = fixCssSelectors(`#${img.getAttribute("id")} { width: 100%; height: auto; max-height: ${placeholderImageDimensions.height}px; max-width: ${placeholderImageDimensions.width}px; object-fit: cover; }`);
        if (styleElement) styleElement.innerHTML = insertStyles(styleElement.innerHTML, fixedCssBlock);

        // img.style.maxHeight = placeholderImageDimensions.height + "px";

      }

    })
  }

  // function detectUnlimitedImages() {
  //   const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
  //   if (!viewport) return;

  //   let sectionsOuterHtml = new Set();
  //   let willTriggerAutofix = false;

  //   const images = viewport.querySelectorAll('img');

  //   images.forEach((img: any) => {
  //     const imageSrc = img.getAttribute("src");

  //     if (!imageSrc) return;
  //     if (imageSrc === "#") return;
  //     if (imageSrc.includes("placehold")) return;

  //     const heightStyle = window.getComputedStyle(img).height;
  //     if (heightStyle === 'auto' || !img.style.height) {


  //       // The current image is one of those images, gotta find its parent, and construct a set, and pass it as context. 
  //       const imageParentSection = findParentSection(img);
  //       if (imageParentSection) {
  //         sectionsOuterHtml.add(imageParentSection.outerHTML);
  //         // Push the outerHTML to the set.
  //       }

  //       willTriggerAutofix = true;
  //       // AAAAA,  it depends on the number of images, I see.
  //     }
  //   });

  //   if (willTriggerAutofix) {
  //     toast({
  //       title: "Layout breaking images detected.",
  //       description: 'We\'ll go ahead and fix them for you.',
  //       variant: 'solid',
  //       status: 'info',
  //       position: 'bottom-left',
  //       duration: 4000,
  //     });
  //   }

  //   return [willTriggerAutofix, sectionsOuterHtml];
  // }

  useEffect(() => {
    if (!pageImagesHaveBeenUpdated || !selected_page) return;

    const liveViewport = iframeReference.current?.contentDocument ||
      iframeReference.current?.contentWindow?.document;

    const parser = new DOMParser();
    const cleanViewport = parser.parseFromString(selected_page.content, 'text/html');

    if (!liveViewport || !cleanViewport) return;

    pageImages.forEach((image: any) => {
      if (!image.id) {
        console.warn('Image without id:', image);
        return;
      }

      if (image.type === 'url') {
        const liveImgTag = liveViewport.querySelector(`#${image.id}`);
        const cleanImgTag = cleanViewport.querySelector(`#${image.id}`);

        if (liveImgTag) liveImgTag.setAttribute('src', image.src);
        if (cleanImgTag) cleanImgTag.setAttribute('src', image.src);
      } else if (image.type === 'background') {
        const liveElement: any = liveViewport.querySelector(`#${image.id}`);
        const cleanElement: any = cleanViewport.querySelector(`#${image.id}`);

        if (liveElement) liveElement.style.backgroundImage = `url('${image.src}')`;
        if (cleanElement) cleanElement.style.backgroundImage = `url('${image.src}')`;
      }
    });

    const cleanContent = cleanViewport.documentElement.outerHTML;

    setContentUndoHistory((prev) => {
      let modifiedPrev = prev;
      if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

      return [
        ...modifiedPrev,
        {
          timestamp: new Date().toLocaleString(),
          label: 'Edit images',
          content: cleanContent
        }
      ];
    });

    setConversationHistoryUndoHistory((prev) => {
      let modifiedPrev = prev;
      if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);
      return modifiedPrev;
    });

    setDidIUndo(false);
    updatePageContent(selected_page.id, cleanContent);
    setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

    // Update code editors using clean viewport
    const htmlContent = cleanViewport.documentElement.querySelector('div#body')?.innerHTML;
    const cssContent = cleanViewport.documentElement.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
    const javascriptContent = cleanViewport.documentElement.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;

    if (htmlContent) setHTMLCode(htmlContent);
    if (cssContent) setCSSCode(cssContent);
    if (javascriptContent) setJavaScriptCode(javascriptContent);

    setPageImagesHaveBeenUpdated(false);
  }, [pageImagesHaveBeenUpdated]);

  useEffect(() => {
    if (!isWideScreen) clearVisualEditor();
    if (!displayVisualEditor) clearVisualEditor();
  }, [displayVisualEditor, isWideScreen])

  // useEffect(() => {
  //   if (isWideScreen) {
  //     setDisplayVisualEditor(true);
  //   } else {
  //     setDisplayVisualEditor(false);
  //   }
  // }, [])

  //drawVisualEditor();
  // drawSectionLabels();

  useEffect(() => {
    if (selected_page) {
      if (displaySectionLabels) {
        drawSectionLabels();
      } else {
        undrawSectionLabels();
      }
    }
  }, [displaySectionLabels]);

  // useEffect(() => {
  // defineEventListeners();
  // }, [])
  // useEffect(() => {
  //   if (displaySectionLabels === false) undrawSectionLabels();
  //   // if (displaySectionLabels === true) drawSectionLabels();
  // }, [displaySectionLabels])

  // useEffect(() => {
  //   if (!htmlCode) return;
  //   drawSectionLabels();
  // }, [htmlCode, cssCode])

  const [viewportZoomLevel, setViewportZoomLevel] = useState(1)

  // const handleTranslatePage = async (language: string) => {
  //   const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
  //   if (!viewport) return;

  //   const localizationMap = buildLocalizationMap(viewport);
  //   const translation = await getTranslation(JSON.stringify(localizationMap), language);

  //   try {
  //     const parsedTranslation: any = JSON.parse(translation as string);
  //     console.log(parsedTranslation);

  //     // I could loop through the viewport right now, and insert in place.
  //   } catch {
  //     throw new Error('Failed to parse.');
  //   }


  //   // Create another page and so on. Also, in languages, get mappings to shorthand versions of language? fr and de for instance.

  //   // This gets sent, 
  //   // Gets translated,
  //   // Gets back, parsed,
  //   // Populated, saved, and all that.

  //   // For starters, then think of how to better integrate it I guess.
  //   // Then think of how to do it all at once.
  // }

  useEffect(() => {
    if (wipeIframeContent) {

      const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
      if (!viewport) return;

      const body: any = viewport.querySelector('div#body');
      if (body) {
        body.innerHTML = "";
      }

      const style: any = viewport.querySelector('style[data-dev-tag="inject-into"]');
      if (style) {
        style.innerHTML = "";
      }

      const script: any = viewport.querySelector('script[data-dev-tag="inject-into"]');
      if (script) {
        script.innerHTML = "";
      }

      // Remove cdn script tags
      const cdnLibraryScripts = viewport.querySelectorAll('.sifo-lib-cdn');
      cdnLibraryScripts.forEach((cdn: any) => {
        cdn.remove();
      })

      // Remove cdn css stylesheet link tags
      const cssLibraryStylesheets = viewport.querySelectorAll('.sifo-lib-css');
      cssLibraryStylesheets.forEach((css: any) => {
        css.remove();
      })

      resetLocalContext();
      setViewportTree('');
      setGlobalContextOuterHTML('');
      setOutlinerTree([]);

      setPageForms([]);
      setPageLinks([]);
      setPageImages([]);

      const bodyElement = viewport.querySelector('div#body');
      const styleElement = viewport.querySelector('style[data-dev-tag="inject-into"]');
      const scriptElement = viewport.querySelector('script[data-dev-tag="inject-into"]');

      if (bodyElement) setHTMLCode(bodyElement.innerHTML);
      if (styleElement) setCSSCode(String(styleElement.textContent));
      if (scriptElement) setJavaScriptCode(scriptElement.innerHTML);

      // setHTMLCode("");
      // setCSSCode("");
      // setJavaScriptCode("");

      if (selected_page) {
        const viewportContent = viewport.documentElement.outerHTML;

        setContentUndoHistory((prev) => {
          let modifiedPrev = prev;
          if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

          return [
            ...modifiedPrev,
            {
              timestamp: new Date().toLocaleString(),
              label: 'Start from scratch',
              content: viewportContent
            }
          ]
        });

        setDidIUndo(false);
        updatePageContent(selected_page.id, viewportContent);
        setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

      }

      undrawSectionLabels();

      setWipeIframeContent(false);
    }


  }, [wipeIframeContent])

  useEffect(() => {
    if (outlinerDeleteElementId) {
      const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
      if (!viewport) return;

      const elementToDelete = viewport.querySelector(`#${outlinerDeleteElementId}`);
      if (elementToDelete) {
        elementToDelete.remove();

        const outlinerTree = buildOutlinerTree(viewport);
        setOutlinerTree(outlinerTree);

        const pageFormTags: any = viewport.querySelectorAll('form');

        if (pageFormTags.length > 0 && selected_page && user) {
          const pageForms: any = constructPageFormsArray(pageFormTags, selected_page.id, user.email);
          setPageForms(pageForms);
          updateForms(selected_page.id, pageForms);
        }

        const pageAmchorTags: any = viewport.querySelectorAll('a');
        if (pageAmchorTags.length > 0) {
          const pageLinks: any = constructPageLinksArray(pageAmchorTags);
          setPageLinks(pageLinks);
          if (selected_page) updateLinks(selected_page.id, pageLinks);
        }

        const allElements: any = viewport.querySelectorAll('*');

        const imageElements: any = Array.from(allElements).filter((element: any) => {
          if (element.tagName === 'IMG' || hasBackgroundImage(element)) {
            return true;
          }
        })

        if (imageElements.length > 0) {
          const pageImages: any = constructPageImagesArray(imageElements);
          setPageImages(pageImages);
          if (selected_page) updateImages(selected_page.id, pageImages);
        }

        if (selected_page) {
          const viewportContent = viewport.documentElement.outerHTML;

          setContentUndoHistory((prev) => {
            let modifiedPrev = prev;
            if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

            return [
              ...modifiedPrev,
              {
                timestamp: new Date().toLocaleString(),
                label: 'Delete element',
                content: viewportContent
              }
            ]
          });

          setDidIUndo(false);
          updatePageContent(selected_page.id, viewportContent);
          setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

        }

        setOutlinerDeleteElementId(null);
      }
    }

  }, [outlinerDeleteElementId])

  // useEffect(() => {
  //   drawSectionLabels();
  // }, [mobileView])

  function defineEventListeners() {
    // if (!displaySectionLabels) return;

    const viewportWindow: any = iframeReference.current?.contentWindow;
    if (!viewportWindow) return;

    if (displaySectionLabels) {
      viewportWindow.onresize = drawSectionLabels;
    } else {
      viewportWindow.onresize = null;
    }
    // viewport.onscroll = drawSectionLabels();
  }

  // useEffect(() => {
  // }, [])

  return (
    <Flex flex={1} gap={2}>
      <Flex justifyContent='center' flex={1} h='full' css={{ "::-webkit-scrollbar": { display: "none" }, overflow: 'hidden' }}>
        <iframe
          id='sifo-viewport-iframe'
          style={{ height: '100%', width: mobileView ? '24rem' : '100%', borderRadius: '3px', backgroundColor: 'white', transition: 'all 0.2s ease-in-out', transform: `scale(${viewportZoomLevel})` }}
          title="Viewport"
          allowFullScreen
          ref={iframeReference}
        />
      </Flex>
      {isWideScreen && (
        <Flex flexDir='column' gap={2}>

          {
            /* 
            
          <Tooltip gutter={11} placement='left' label='Apply: Confirm and save manual changes.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              size="xs"
              variant={hasBeenManuallyEdited ? 'solid' : 'subtle'}
              colorScheme='purple'
              aria-label='Apply changes'
              icon={<GiConfirmed />}
              onClick={confirmManualEdits}
              isDisabled={!hasBeenManuallyEdited}
            />
          </Tooltip>
                      {isDirectEditEnabled ? (
                        <Tooltip gutter={11} placement='left' label='Direct Edit: Directly edit text on the page.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                          <IconButton
                            size="xs"
                            variant='outline'
                            colorScheme='purple'
                            aria-label='Disable Direct Edit'
                            icon={<IoText />}
                            onClick={disableDirectEdit}
                          />
                        </Tooltip>
                      ) : (
            
                        <Tooltip gutter={11} placement='left' label='Direct Edit: Directly edit any text on the page.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                          <IconButton
                            size="xs"
                            variant='subtle'
                            colorScheme='purple'
                            aria-label='Enable Direct Dditing'
                            icon={<IoText />}
                            onClick={enableDirectEdit}
                          />
                        </Tooltip>
                      )}
            */
          }

          {
            /*
            {isDirectResizeEnabled ? (
              <Tooltip gutter={11} placement='left' label='Direct Resize: Directly resize elements on the page.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                <IconButton
                  size="xs"
                  variant='outline'
                  colorScheme='purple'
                  aria-label='Disable Direct Resize'
                  icon={<GiResize />}
                  onClick={disableDirectResize}
                />
              </Tooltip>
            ) : (
  
              <Tooltip gutter={11} placement='left' label='Direct Resize: Directly resize elements on the page.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                <IconButton
                  size="xs"
                  variant='subtle'
                  colorScheme='purple'
                  aria-label='Enable Direct Resize'
                  icon={<GiResize />}
                  onClick={enableDirectResize}
                />
              </Tooltip>
            )}
  
  
            {isDirectMoveEnabled ? (
              <Tooltip gutter={11} placement='left' label='Direct Move: Directly move elements on the page.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                <IconButton
                  size="xs"
                  variant='outline'
                  colorScheme='purple'
                  aria-label='Disable Move & Resize Mode'
                  icon={<IoMdMove />}
                  onClick={disableDirectMove}
                />
              </Tooltip>
            ) : (
  
              <Tooltip gutter={11} placement='left' label='Direct Move: Directly move elements on the page.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                <IconButton
                  size="xs"
                  variant='subtle'
                  colorScheme='purple'
                  aria-label='Enable Move & Resize Mode'
                  icon={<IoMdMove />}
                  onClick={enableDirectMove}
                />
              </Tooltip>
            )}
            
            */
          }

          <Tooltip gutter={11} placement='left' label='Desktop: Switch to wide screen view.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              size="xs"
              variant='ghost'
              aria-label='Desktop View'
              icon={<FaDesktop />}
              onClick={() => setMobileView(false)}
            />
          </Tooltip>

          <Tooltip gutter={11} placement='left' label='Mobile: Switch to responsive view.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              size="xs"
              variant='ghost'
              aria-label='Mobile View'
              icon={<FaMobileAlt />}
              onClick={() => setMobileView(true)}
            />
          </Tooltip>

          <Tooltip gutter={11} placement='left' label='Zoom in: Make the viewport bigger.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              size="xs"
              variant='ghost'
              aria-label='Zoom in'
              icon={<SlMagnifierAdd />}
              onClick={() => setViewportZoomLevel(viewportZoomLevel + 0.2)}
            />
          </Tooltip>

          <Tooltip gutter={11} placement='left' label='Zoom out: Make the viewport smaller.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              size="xs"
              variant='ghost'
              aria-label='Zoom out'
              icon={<SlMagnifierRemove />}
              onClick={() => setViewportZoomLevel(viewportZoomLevel - 0.2)}
            />
          </Tooltip>

          <Tooltip gutter={11} placement='left' label='Reset zoom: Go back to default zoom level.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              size="xs"
              variant='ghost'
              aria-label='Reset zoom'
              icon={<SlMagnifier />}
              onClick={() => setViewportZoomLevel(1)}
              isDisabled={viewportZoomLevel === 1}
            />
          </Tooltip>

          {/* <Tooltip gutter={11} placement='left' label='Translate: Translate page.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              size="xs"
              variant='ghost'
              aria-label='Translate'
              icon={<MdGTranslate  />}
              onClick={() => handleTranslatePage('French')}
            />
          </Tooltip> */}
        </Flex>
      )}
    </Flex>
  );
};
